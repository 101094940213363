div#table-info-stats_paginate {
    margin-top: 20px;
}

div#table-info-stats_length {
    margin: 10px 0;
    & select {
        cursor: pointer;
    }
}

input.js-search-start.form-control {
    margin: 0 10px;
}

input.js-search-end.form-control {
    margin: 0 10px;
}

.js-option-searchuser {
    display: none;
}

.js-show-option-searchuser {
    color: $cprimary-7;
    margin: 15px 0; 
}

#table-info-stats th {
    overflow : auto;
    cursor: pointer;
    padding-left: 20px;
}

#table-info-stats tbody tr {
    height: 40px;
    border: 1px solid;
}

#table-info-stats tbody tr td {
    border: 1px solid;
    padding: 10px;
}

#table-info-stats_filter input[type="search"] {
    color: $cprimary-12;
}