.resp-box {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
}

.resp-box-img {
    margin-right: 2px;
    width: 15%;
    display: flex;
}

.resp-box-name {
    width: 40%;
    margin-right: 15px;
    word-break: break-word;
}

.resp-box-actions {
    width: 40%;
    text-align: right;
    font-weight: bold;
}

.resp-box-img .bullet {
    margin-left: 5px;
    margin-right: 0;
}

.resp-box-img div {
    width: 50%;
}

