.escompte-content {
    display: none;
    justify-content: space-between;
    &.open {
        display: flex;
    }
}

.e-facture-return {
    background: none;
    border: 0;
    width: 275px;
    text-align: center;
}

.user-notonsoan {
    display: none;
}

.show-sendlater {
    display: none;
}

.js-open-mention {
    cursor: pointer;
}

.efacture-list {
    display: flex;
    flex-wrap: wrap;
    .doc .doc-content 
    {
        height: 232px;
        .doc-view {
            width: 125px;
            margin: 10px auto;
            height: 100px;
        }
    }
}

.show-add-efacture {
    display: none;
    max-width: 500px;
    .custom-file {
        position: absolute;
        top: 0px;
        left: 0;
    }

    .custom-file input {
        font-size: 16px;
        font-weight: normal;
        width: 30%;
    }

    .custom-file input + label {
        background: transparent;
        border: 0;
        display: inline-block;
        margin: 0;
        // color: $cwhite;
        min-height: 80px;
        line-height: 65px;
        padding-left: 0;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }

    .custom-file input + label:after {
        border: 1px solid $cprimary-7;
        border-radius: 100px;
        background: #fff;
        height: 35px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $cprimary-7;
        width: 200px;
        content: "Choisir un fichier";
        font-size: 16px;
        cursor: pointer;
    }
}

.mentions-elm {
    display: none;
}

.show-add-efacture label {
    font-weight: bold;
}

.popin .popin-create-efacture {
    width: 700px;
    max-width: 700px !important;
}