.search {
    h3 {
        font-weight: bold;
        margin-top: 60px;
    }
}

.eastereggs {
    position: absolute;
    background: rgba($cprimary-12, 0.6);
    top: 0;
    z-index: 999999;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.submitSearchMobile {
    display: none;
}