
// Large devices (desktops, less than 1680px)
@media (max-width: 1680px) {
    
    html {
        font-size: 14px;
    }
    .fs36 {
        font-size: 28px;
    }
    
    .fs24 {
        font-size: 22px;
    }

    .fs12 {
        font-size: 10px;
    }
    /*Form*/
    input, .popin-wrap.popin-add-bank select, .popin-wrap.popin-payment-noiban select, .st-selectStyleCountry {
        height: 35px;
    }
    .bg-black .popin select {
        height: 35px;
    }
    .kyc-row {
        .custom-file-label::after {
            position: absolute;
        }
    }
    .kyc-row .custom-file input + label {
        text-align: end;
    }
    /*Header*/
    header#header {
        height: 50px;
    }
    .header-flex {
        height: 50px;
    }
    .logo-img img {
        height: 40px;
    }
    .logo-img a {
        display: flex;
    }
    .view-full header#header {
        height: 50px;
    }
    .js-accountReach img {
        width: 40px;
    }

    /* Calendrier */
    .soancalendar-picto-text {
        font-size: 14px;
    }
    .soancalendar .cheader-date {
        font-size: 14px;
    }

    /*loading*/
    .loader {
        top: 50px;
    }

    /*all project*/
    .new-project-content h1 {
        font-size: 36px;
        margin-bottom: 0;
    }

    /*Dashboard*/
    .view-full .body-content .soancontainer > section h1, .view-full .body-content .soancontainer > section .title {
        font-size: 36px;
    }
    
    .dashboard .last-news {
        top: 10px;
    }
    
    .dashboard .last-news img {
        width: 15px;
        height: 15px;
        margin-left: 5px;
    }
    
    .bullet {
        height: 20px;
        width: 20px;
        line-height: 20px;
    }
    .step-date-choice {
        font-size: 14px;
    }
    .menu-project-name {
        font-size: 22px;
        margin-top: 20px;
    }
    .menu-round-picto {
        height: 75px;
        width: 75px;
    }
    
    .view-full .body-content .vertical-menu {
        width: 225px;
    }
    /*container*/
    .view-full .body-content .soancontainer {
        width: calc(100% - 225px);
        padding: 40px;
        padding-bottom: 100px;
    }
    /**/
    .dashboard .picto-prog {
        height: 75px;
    }

    .dashboard .step.active:not(.active-next) .step-content .picto-phase, .dashboard .step.active:not(.active-prev) .step-content .picto-phase {
        height: 100px;
    }

    .dashboard .step .step-content {
        height: 150px;
    }

    .dashboard .step.active:not(.active-next) .step-content, .dashboard .step.active:not(.active-prev) .step-content {
        height: 180px;
    }

    .dashboard .picto-admin {
        height: 60px;
    }

    .dashboard .picto-pre {
        height: 60px;
    }

    .dashboard .picto-deb {
        height: 75px;
    }

    /* Doc */
    .doc .doc-content {
        height: 195px;
    }
    .doc.help .doc-content {
        height: 210px;
    }
    .doc {
        width: 120px;
    }

    .efacture-list {
        display: flex;
        flex-wrap: wrap;
        .doc .doc-content 
        {
            height: 232px;
            .doc-view {
                width: 125px;
                margin: 10px auto;
                height: 100px;
            }
        }
    }
    
    .doc .doc-content .doc-view {
        height: 75px;
        width: 60px;
        margin: 10px auto;
        overflow: hidden;
    }
    .doc.help .doc-content .doc-view {
        width: auto;
        height: auto;
        margin: 0;
        overflow: hidden;
    }
    .doc .doc-content .doc-text .doc-text-title {
        font-size: 12px;
    }
    .doc .doc-content .doc-text .doc-text-auth {
        font-size: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    /*page doc*/
    .document-image {
        height: auto;
        width: 175px;
    }

    .e-facture-return {
        width: 175px;
    }
    
    // .document-info {
    //     width: 250px;
    // }
    .sstitle {
        font-size: 22px;
    }
    /* Search */
    input.search-input {
        height: 25px;
    }

    //used name popin info 
    .used-name-info {
        position: absolute;
        top: 2px;
    }

    .iconCompanyName {
        & a {
            margin-top: 0;
        }
        & img {
            margin-top: 4px;
        }
        & .st-blocNumber {
            top: -46px;
        }
        
    }
}

// Large devices (desktops, less than 1480px)
@media (max-width: 1480px) {
    .st-margin-right-0px-lg-90px {
        margin-right: 0px;
    }

    html {
        font-size: 13px;
    }
    .fs36 {
        font-size: 24px;
    }
    
    .fs24 {
        font-size: 18px;
    }
    /*Form*/
    input {
        height: 35px;
    }
    .bg-black .popin select {
        height: 35px;
    }
    /*Header*/
    header#header {
        height: 50px;
    }
    .header-flex {
        height: 50px;
    }
    .logo-img img {
        height: 30px;
    }
    
    .logo-img a {
        display: flex;
    }
    .view-full header#header {
        height: 50px;
    }
    .js-accountReach img {
        width: 30px;
        height: 30px;
    }

    /* Register */
    .register .register-container .title {
        font-size: 24px;
        margin-bottom: 15px;
    }
    
    .register-container .steps-info {
        margin: 15px 0;
    }
    
    .register-container .steps-info .fs24 {
        font-size: 16px;
    }

    /* Calendrier */
    .soancalendar-picto-text {
        font-size: 13px;
    }
    .soancalendar .cheader-date {
        font-size: 13px;
    }

    /*loading*/
    .loader {
        top: 40px;
    }

    /*all project*/
    .new-project-content h1 {
        font-size: 32px;
        margin-bottom: 0;
    }
    

    /*Dashboard*/
    .view-full .body-content .soancontainer > section h1, .view-full .body-content .soancontainer > section .title {
        font-size: 36px;
    }
    
    .dashboard .last-news {
        top: 10px;
    }
    
    .dashboard .last-news img {
        width: 15px;
        height: 15px;
        margin-left: 5px;
    }
    
    .bullet {
        height: 15px;
        width: 15px;
        line-height: 15px;
    }
    .step-date-choice {
        font-size: 13px;
    }
    .menu-project-name {
        font-size: 18px;
    }
    .menu-round-picto {
        height: 75px;
        width: 75px;
    }
    
    .view-full .body-content .vertical-menu {
        width: 225px;
    }
    /*container*/
    .view-full .body-content .soancontainer {
        width: calc(100% - 225px);
        padding: 40px;
        padding-bottom: 100px !important;
    }
    // .container.h-100 {
    //     height: auto !important;
    // }
    /**/
    .dashboard .picto-prog {
        height: 75px;
    }

    .dashboard .step.active:not(.active-next) .step-content .picto-phase, .dashboard .step.active:not(.active-prev) .step-content .picto-phase {
        height: 100px;
    }

    .dashboard .step .step-content {
        height: 150px;
    }

    .dashboard .step.active:not(.active-next) .step-content, .dashboard .step.active:not(.active-prev) .step-content {
        height: 180px;
    }

    .dashboard .picto-admin {
        height: 60px;
    }

    .dashboard .picto-pre {
        height: 60px;
    }

    .dashboard .picto-deb {
        height: 75px;
    }

    /* Doc */
    .doc .doc-content {
        height: 185px;
    }

    
    
    .doc {
        width: 120px;
    }

    .efacture-list {
        display: flex;
        flex-wrap: wrap;
        .doc .doc-content 
        {
            height: 232px;
            .doc-view {
                width: 125px;
                margin: 10px auto;
                height: 100px;
            }
        }
    }
    
    .doc .doc-content .doc-view {
        height: 75px;
        width: 60px;
        margin: 10px auto;
        overflow: hidden;
    }
    .doc .doc-content .doc-text .doc-text-title {
        font-size: 13px;
    }
    .doc .doc-content .doc-text .doc-text-auth {
        font-size: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .efacture-list {
        display: flex;
        flex-wrap: wrap;
        .doc .doc-content 
        {
            height: 232px;
            .doc-view {
                width: 125px;
                margin: 10px auto;
                height: 100px;
            }
        }
    }
    /*page doc*/
    .document-image {
        height: auto;
        width: 175px;
    }

    .e-facture-return {
        width: 175px;
    }
    
    // .document-info {
    //     width: 250px;
    // }
    .sstitle {
        font-size: 18px;
    }
    /* Search */
    input.search-input {
        height: 25px;
    }

    .view-full .body-content .st-ourOfferGlobalContainer {
        padding: 40px 20px 100px 20px !important;
    }

    .offer .st-ourOfferBtn {
        width: 280px;
    }

    .timeline-mail {
        width: 80%;
    }
    .popin-updateSupplier .st-selectedTag {
        top: 34px;
    }
}

@media (max-width: 1280px) {
    .payment-receive, .payment-send {
        width: 80%;
    }
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .payment-container {
        flex-direction: column;
        align-items: center;
    }
    
    .st-margin-left-60px-lg-90px {
        margin-left: 60px;
    }

    .st-home-dashboard-container {
        width: 100%;
        padding: 0px;
    }
    .bottom-menu {
        position: static;
    }
    span.closetxt {
        display: none;
    }

    .soantalk.talkclose {
        width: auto;
    }

    .soantalk.talkclose .soantalk-header {
        padding: 5px;
    }

    .soantalk.talkclose .soantalk-header-closemsg img {
        margin: 0 !important;
    }
    .soancalendar-picto-text {
        font-size: 12px;
    }
    .soancalendar .cheader-date {
        font-size: 10px;
    }
    .js-soancalendar-month {
        display: block;
    }
    .js-soancalendar-week {
        display: block;
    }
    .kyc-row .custom-file input + label:after {
        height: 41px;
        line-height: 28px;
    }
    .feedback {
        .btn {
            padding: 10px;
            width: 150px;
        }
    }

    .maxlittle-container {
        padding: 20px;
    }

    .archi-modifinfo-content-ourOffersOffer .d-flex {
        flex-direction: column;
    }

    .offer-container-validation .offer .offer-item, .maxlittle-container {
        margin: auto;
    }

    .myoffer-block div {
        align-items: flex-start;
    }

    .offer-payment-month .form-check, .offer-payment-year .form-check {
        margin-bottom: 20px;
    }

    #mytable .link-warning-before {
        padding-left: 25px;
    }
    .st-default-bank-text {
        font-size: 17px;
    }
    .btn-payment-demand {
        position: fixed;
        right: 10px;
        bottom: 50px;
    }

    .activity-type:after {
        left: calc(50% - 1.4px) !important;
    }

    .chart-container {
        .graph {
            width: 1140px;
        }
        .scale-value {
            font-size: 13px;
        }
    }
    .blocArrayDashboard {
        & .st-arrayDashboard {
            width: 100%;
            & .st-paymentName {
                width: 10%;
            }
            & .st-paymentPrice {
                .st-statePayment .st-3xLabelCustom {
                    margin-left: 10px;
                }
            }
        }
    }

    .dash-project-closed {
        width: 100%;
    }

    .bills-relaunch-bottom {
        .timeline-relaunch {
            margin-left: 20px;
            margin-top: 0;
            .timeline-mail {
                display: none;
                width: 100%;
                left: -35px;
                background-color: white;
                z-index: 10;
                top: 15px;
                .close-cross img {
                    margin-top: 20px;
                    margin-right: 20px;
                    cursor: pointer;
                }
                .mail-subject {
                    margin-top: 35px;
                }
            }
        } 
        .st-addNewMail, .st-msgInfoMail {
            margin-left: -15px;
        }    
    }
    .st-blocModels {
        display: none;
        position: relative;
        top: 65px;
        z-index: 11;
    }
    #modelSelect {
        margin: auto;
    }
    .st-emptyRelaunch {
        top: 0;
        margin-top: 30px;
        margin-bottom: 10px;
        width: 100%;
        margin-left: 0;
    }
    .usermanagement-container {
        flex-direction: column;;
    }
    .st-superAdmin {
        margin-left: 0;
    }
    .st-divAdmin, .st-divCollab, .st-divWatcher {
        margin-left: 40px;
        .st-superAdmin {
            margin-left: -40px;
        }
    }
    .payment-out .st-containerOffline {
        margin-top: 40px;
    }
    .supplierPaymentPage {
        width: auto;
        .st-blocSupplierContent {
            flex-direction: column;
        }
        .st-blocNumberBills, .st-blocImportFacture {
            margin: auto;
            width: 555px;
        }
        .st-blocFormFacture {
            margin: auto;
            margin-top: 50px;
        }
        .st-titleSection, .js-supplierInfos {
            padding-left: 12px;
        }
        .st-back-dashboard {
            padding-left: 8px;
        }
    }
    .st-ourOfferGlobalContainer {
        .max-container {
            margin-left: 50px;
        }
        .offer {
            margin-right: 50px;
        }
    }
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .st-margin-left-60px-lg-90px {
        margin-left: 0px;
    }
    
    .w-100-lg-50 {
        width: 100%;
    }

    .left-info {
        width: 50%;
    }

    .right-info {
        width: 50%;
        text-align: left;
    }

    .st-padding-x-0-sm-24px {
        padding: 0px;
    }

    .payment-out .container {
        display: block;
    }

    .payment-out .company-recap {
        width: 100%;   
    }

    .js-soancalendar-month {
        display: none;
    }
    .js-soancalendar-week {
        display: none;
    }
    section.payment-choice-container {
        display: block;
    }

    .payment-choice-block {
        width: 100%;
        margin: 0;
        margin-top: 20px;
        max-width: 100%;
    }

    .popin-wrap.popin-double-size .double-input {
        display: block;
    }
    
    .popin-wrap.popin-double-size {
        width: 100%;
    }
    
    .double-input .company-input-container {
        width: 100%;
    }
    .double-input-mobile {
        display: flex !important;
    }
    
    .double-input-mobile > div.company-input-container {
        width: 50%;
        &:last-child {
            margin-left: 0;
        }
        &:first-child {
            margin-right: 0;
        }
    }
    .popin-wrap {
        &.popin-double-size {
            .popin-step .first-step {
                width: 100%;
            }
            
            .popin-step .company-step {
                .company-step2, .company-step3 {
                    width: 100%;
                }
            }
        }
    }

    .new-project-actions {
        flex-direction: column;
    }

    .new-project-create {
        margin-bottom: 20px;
    }

    .moreOptions .archi-modifinfo-content .d-flex {
        flex-direction: column;
        text-align: center;
    }

    .moreOptions .archi-modifinfo-content a {
        width: 60%;
        margin-top: 20px;
    }

    .contact-btn, .st-deleteAccount, .st-btn-deleteAccount {
        width: 100% !important;
    }

    .payment-receive {
        width: 100%;
        margin-right: 0;
    }

    .popin-payment .popin-content div div:nth-child(2) {
        display: none !important;
    }

    .popin-payment .popin-content div {
        cursor: default;
    }
    .chart-container {
        .graph-block {
            bottom: 0;
            overflow-y: hidden;
            overflow-x: scroll;
            position: absolute;
            z-index: 8;
        }
        .graph {
            bottom: 50px;
            width: 936px;
        }
        .wrap {
            height: 190px;
        }
        .st-tabHeader {
            display: block;
        }
        .header-chart {
            margin: 15px;
            padding: 0;
        }
        #chartFilter {
            font-size: 14px;
            margin-right: 0;
            width: 160px;
        }
        .st-turnover-total {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            line-height: initial;
            margin-top: 0;
            position: absolute;
            text-align: center;
            top: 0;
            b {
                line-height: initial;
            }
        }
        .st-openpopin-period {
            text-align: left;
        }
        .st-openpopin-goal {
            text-align: right;
        }
        .st-openpopin-goal, .st-openpopin-period {
            margin: 20px 0 0;
            width: 100%;
        }
        .legend-block {
            bottom: -15px;
            left: 0;
            margin-bottom: 15px;
            padding: 0 10px 0 10px;
            position: absolute;
            right: 0;
        }
        .st-legend {
            justify-content: space-between;
            span {
                font-weight: bold;
                line-height: 10px;
            }
            span:hover {
                font-weight: normal;
            }
        }
        .st-legend .legend-in, .st-legend .legend-billed, .st-legend .legend-goals {
            margin-left: 20px;
        }
        .legend-out, .legend-in, .legend-billed {
            height: 10.5px;
            width: 14px;
        }
        .legend-goals {
            height: 10px;
            width: 10px;
        }
        .bar:hover {
            background: #54FCD1;
        }
        .bar:hover .tooltip-legend, .st-goal:hover .tooltip-legend {
            display: none;
        }
        .responsive-tooltip {
            box-shadow: 0px 6px 12px #00000029;
            display: block;
            font-size: 10px;
            height: 58px;
            margin: 0 auto;
            opacity: 0;
            padding: 5px 15px 8px;
            width: max-content;
        }
        .scale {
            background-color: #FFFFFF;
            bottom: 50px;
            padding-left: 16px;
            position: absolute;
            width: 60px;
            z-index: 9;
        }
        .scale-value {
            font-size: 10px;
        }
        .val-4 {
            bottom: 99%;
        }
        .month:after{
            bottom: -25px;
        }
    }

    .blocArrayDashboard {
        & .st-arrayDashboard {
            & .st-servicesSoan {
                width: 28%;
                margin-left: 0;
            }
            & .st-paymentPrice p {
                width: 100px;
            }
            & .st-paymentAction a {
                width: 25px;
                margin-left: -20px;
            }
            & .st-paymentPrice {
                & .st-amountPrice {
                    & .st-barredPrice {
                        width: 70px;
                    }
                }
            }
            & .js-search-dashboard {
                width: 150px;
            }
        }
    }

    .payment-out {
        & .st-emptyBlocOffline {
            display: none;
        }

        & .company-info {
            margin: auto;
            margin-bottom: 30px;
        }

        & .content-payed .content-item {
            padding-bottom: 50px;
            margin-bottom: 0;
        }

        & .st-containerOffline {
            margin-top: 0;
        }

        & .st-listOffline {
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
        }

        & .st-listContainer {
            flex-direction: row !important;
        }

        & .st-blocSubscriptionOffline {
            display: none;
        }
        & .st-blocSubscriptionOfflineMobile {
            display: block;
            height: auto;
            margin: 0 auto 30px;
            width: 650px;
        }
        & .st-payment-propulse {
            width: 100%;
        }
        & .st-containerOfflineMobile {
            display: block;
        }
        & .st-blocCompanyInfos {
            width: 650px;
            margin: auto;
            height: auto;
            padding: 40px;
            margin-bottom: 30px;
            margin-top: 30px;
            & .st-companyInfos {
                margin-bottom: 40px;
                flex-direction: row;
                justify-content: space-around;
                & img {
                    margin-left: initial;
                    margin-right: initial;
                    margin-top: initial;
                }
                & .company-name {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }

    .bills-relaunch-bottom {
        .timeline-relaunch {
            .timeline-mail {
                width: 108%;
                .mail-sendto-container {
                    flex-direction: column;
                    align-items: flex-start;
                    height: 100px;
                    padding: 20px;
                    & div:nth-child(2) {
                        margin-top: 10px;
                    }
                    .st-toSent {
                        width: 75px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
                .st-mailObject {
                    width: 130px;
                }
            }
        }
    }
    .js-close-cross {
        display: block;
    }

    .st-btn-deleteAccount {
        margin-top: 30px;
    }

    .st-ourOfferGlobalContainer {
        .offer-container {
            flex-direction: column;
            .offer:nth-child(1) {
                margin-bottom: 50px;
            }
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .st-kycChooseFileButton {
        height: 35px;
        width: 160px;
        font-size: 13px;
    }

    .st-block-payment-link {
        padding-top: 15px;
    }

    .st-padding-bottom-md-60px {
        margin-bottom: 0px;
    }

    .st-width-100-md-25 {
        width: 100%;
    }
    
    .st-width-100-md-50 {
        width: 100%;
    }

    .bills-relaunch-bottom {
        .st-addNewMail, .st-msgInfoMail {
            margin-left: 15px;
        }
        .timeline-relaunch {
            margin-left: 45px;
            .timeline-container:not(.timeline-sent):hover .timeline-picto {
                width: 50px;
            }
            .timeline-mail {
                z-index: 99;
                background: #fff;
                width: 100%;
                left: 0;
                overflow: auto;
                position: fixed;
                height: 100vh;
                padding-bottom: 55px;
                top: 110px;
                .close-cross {
                    display: block;
                    position: fixed;
                    top: 50px;
                    z-index: 100;
                    right: 0px;
                    background-color: white;
                    height: 65px;
                    padding-left: 5px;
                    width: 12%;
                    & img {
                        margin-top: 23px;
                        margin-right: 20px;
                    }
                }
                .mail-sendto-container {
                    height: 135px;
                    padding: 20px 30px;
                    & div:nth-child(1) {
                        flex-direction: column;
                    }
                }
                .mail-subject {
                    margin-bottom: 15px;
                }
                .trumbowyg-box, .mail-subject {
                    padding: 0 30px;
                }
                .st-blocAddingLogoMail, .js-showAddingModelDraft, .js-showAddingModel {
                    margin: 20px 30px;
                }
                .mail-actions {
                    margin: 30px;
                }
                .mail-actions {
                    padding-bottom: 100px;
                }
            }
        }
    } 

    .st-blocModels {
        position: fixed;
        top: 50px;
        z-index: 100;
        background-color: rgb(255, 255, 255);
        width: 88%;
        height: 65px;
        left: 0px;
        display: none;
        padding-top: 15px;
        #modelSelect {
            margin-left: initial;
            margin-top: initial;
            left: 30px;
        }
    }

    .kyc-row {
        .custom-file {
            justify-content: center !important;
        }
    }

    .st-min-width-180px {
        width: 180px;
    }

    .st-home-dashboard-container {
        h1 {
            font-size: 30px;
        }
    }

    //Popin onboarding menu
    .tour-popin-activation {
        width: 250px;
        transform: translate(-80%, 0);
        &:after {
            display: none;
        }
        &:before {
            display: none;
        }
    }
    .tourend {
        width: 250px;
        transform: translate(-80%, 0);
        &:after {
            display: none;
        }
        &:before {
            display: none;
        }
    }

    .show-mobile {
        display: block;
    }

    .view-full .body-content .vertical-menu a.js-close-menu img {
        display: block;
    }

    .vertical-menu {
        display: none !important;
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        background: #fff;
        z-index: 9999999;
        .vertical-menu-close {
            display: block !important;
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 18px;
            font-weight: bold;
        }
        &.mobileshow {
            display: block !important;
        }
    }
    .menu-links {
        display: flex;
        flex-direction: column;
        align-items: center
    }
    .menu-links > div {
        width: 50% !important;
    }
    .dashboard .last-news img {
        margin: 0;
    }
    .account-content .d-flex {
        display: block !important;
    }
    
    .account-content .d-flex .w-50 {
        width: 100% !important;
        margin: 0 !important;
    }

    .archi-modifinfo .account-avatar {
        margin: 0 !important;
        text-align: center;
    }

    .form-wallet .d-flex {
        flex-direction: column;
    }

    .reset-return-info {
        margin-top: 8px;
    }

    .submit-wallet-info {
        flex-direction: row !important;
    }

    .form-wallet > div > .form-group:first-child {
        width: 100% !important;
        margin: 0;
    }

    .form-wallet > div > .form-group:last-child {
        width: 100% !important;
        margin: 0;
    }
    
    .submit-abo {
        margin: 10px auto !important;
        text-align: center;
    }

    .account-content .infos:first-child {
        margin-bottom: 10px;
        padding-bottom: 30px;
        border-bottom: 1px solid;
    }

    .contact-btn {
        width: 100% !important;
        margin: 10px 0;
    }
    
    .delete-account-btn .w-50 {
        width: 100% !important;
    }
    
    .delete-account-btn .w-25 {
        width: 100% !important;
    }
    
    .resp-mt {
        margin-top: 15px;
    }

    .resp-company-autocomplete .w-25 {
        margin-top: 10px;
        width: 100% !important;
    }
    .resp-company-autocomplete .w-75 {
        width: 100% !important;
        margin: 0 !important;
    }

    .resp-company-notfound .w-25 {
        width: 100% !important;
    }
    
    .resp-company-notfound .w-75 {
        margin: 0 !important;
        width: 100% !important;
    }
    
    .account-content .disconnect {
        text-align: center;
    }

    .archi-modifinfo #change_infos_submit, .archi-modifinfo #mangopay_infos_submit {
        margin: 10px auto;
    }

    .submit-wallet-info {
        margin-top: 15px;
    }

    .autocomplete-fail .form-group {
        margin: 0 !important;
        width: 100% !important;
    }
    .st-bank-trash {
        opacity: 1;
    }
    .st-default-bank-text {
        font-size: 13px;
    }
    .bank-details {
        div {
            font-size: 18px !important;
        }
        span {
            font-size: 13px !important;
        }
    }
    #change_infos_thArchi_thBirthday select, #company_infos_thLRBirthday select, #user_registration_thBirthday select{
        width: auto;
        margin-left: 0;
        margin-right: 15px;
    }

    .offer-payment-month div, .offer-payment-year div
    {
        flex-direction: row !important;
    }

    .maxlittle-container
    {
        margin: auto;
    }

    .js-offerchoice-payment
    {
        margin-bottom: 30px;
    }

    .dashboard .last-news a {
        border-radius: 50%;
        box-shadow: none;
        background: transparent;
    }

    a.js-accountReach:last-child {
        display: none;
    }

    .folder-block {
        margin-right: 0;
        width: 170px;
    }
    
    .folder-content {
        justify-content: space-between;
    }
    
    .folder {
        background-size: contain;
        width: 100%;
        height: 170px;
    }
    
    .dashboard .last-news {
        top: 0;
    }
    
    .hide-mobile, .hide-mobile-inline {
        display: none;
    }
    .menu-mobile {
        display: inline-block;
        // text-align: center;
        padding-left: 15px;
        z-index: 9999;
    }

    input.search-input{
        display: none;
        position: initial;
        top: 55px;
        //border-top: 0;
        z-index: 999;
        border-radius: 4px;
        width: 80% !important;
        left: 5%;
        padding: 0 10px !important;
        background-color: rgba(33, 37, 41, 0.9) !important;
    }

    .search form img {
        position: static;
        transform: none;
        height: 30px;
        width: 40px;
        padding-left: 15px;
    }
    form.search-form {
        display: inline-block;
        width: auto;
        padding: 0;
    }
    .soancontainer {
        padding: 15px !important;
        height: auto;
        width: 100% !important;
    }
    
    .body-content {
        display: block !important;
    }
    
    .document-top {
        flex-direction: column;
        align-items: center;
    }
    
    .document-image {
        margin-right: 0;
        margin-bottom: 25px;
    }
    
    .document-bottom {
        flex-direction: column-reverse;
    }
    
    .document-bottom .document-help {
        width: 100%;
        margin-right: 0;
        padding: 0 10px;
    }
    
    .document-bottom .document-ticket {
        width: 100%;
    }
    
    .document-title.sstitle span {
        font-size: 16px;
    }
    
    .document-title.sstitle {
        align-items: center;
    }

    .no-content {
        flex-direction: column;
    }
    
    .no-text > div {
        font-size: 16px;
    }
    .soantalk {
        bottom: 10px;
        width: 94%;
        //left: 3%;
    }
    .shadowbox {
        width: 100%;
    }
    .soantalk .soantalk-content {
        width: 100%;
    }
    .new-project-actions {
        display: block;
    }
    
    // .new-project-status {
    //     margin-top: 15px;
    // }
    section.new-projects-form {
        width: 100%;
    }
    .msg-flash {
        width: 90%;
        overflow: hidden;
    }
    .view-full .body-content .soancontainer > section h1, .view-full .body-content .soancontainer > section .title {
        /* font-size: 18px; */
        text-align: left;
    }
    .new-project-content.activity-project-content h1 {
        text-align: center !important;
    }
    .view-full .body-content .vertical-menu .feedback {
        /* display: none; */
        position: relative !important;
        bottom: 0;
        // left: 50%;
        left: 15px;
        margin-bottom: 0;
        transform: none !important;
        // transform: translate(-50%) !important;
    }

    // .popin {
    //     width: 94%;
    //     min-width: auto;
    // }

    .soancalendar .planview .plan-block {
        width: 100%;
    }
    .page-stepfolder .folder-img {
        justify-content: flex-end;
        width: calc(100% - 20px);
    }
    
    .folder {
        // height: 165px;
        padding: 30px 20px 10px 10px;
    }
    
    .page-stepfolder .folder-img img {
        // width: 25%;
        width: 40%;
        margin: 5px;
    }

    /*Activities*/
    .activity-msg {
        width: 100%;
    }
    
    .user-msg .activity-msg::after {
        right: -12px;
        display: none;
    }
    
    .activity-message-archi .activity-msg {
        margin-left: auto;
        border-bottom: 3px solid orange;
    }
    
    .activity-message-archi .activity-hour {
        justify-content: flex-end;
        width: 50%;
        margin-left: auto;
    }

    .activity-hour {
        width: 100%;
    }
    
    .activity-message-archi {
        width: 100%;
    }
    
    .archi-msg .activity-msg::after {
        display: none;
    }
    
    .activity-message-user {
        width: 100%;
    }
    
    .activity-message-user .activity-msg {
        border-bottom: 3px solid $cprimary-7;
    }

    .soancalendar-naviguate .show-filter {
        width: auto;
        text-align: center;
    }
    /*Register*/
    .register {
        .register-container {
            width: 100%;
           // .step-content-1  {
                // * {
                //     display: block;
                //     width: 100%;
                //     margin-left: 0;
                //     margin-right: 0;
                // }
            //}
        }
    }
    .register-container .d-flex {
        display: block !important;
    }
    
    .register-container .d-flex .w-50 {
        width: 100% !important;
        margin: 0 !important;
    }
    
    .register-container .d-flex .form-group {
        width: 100% !important;
        margin: 10px 0 !important;
    }
    
    .register-container .d-flex.text-nowrap .d-flex {
        display: flex !important;
    }
    
    #freelance_registration_submit {
        width: 100%;
    }

    /*hide btn project */
    .btn-project {
        display: none;
    }

    // .dotmenu-folder {
    //     top: 30px;
    //     right: 20px;
    // }
    section.page-newdocument {
        .create-document form {
            margin-top: 40px;
            width: 100%;
        }
    }

    .btn-create-doc {
        display: block;
        margin-bottom: 15px;
        a:first-child {
            margin-bottom: 15px;
        }
    }

    /*Responsive 2.0*/
    .undefined-project {
        .search {
            display: none;
        }
        
        .logo-img {
            justify-content: flex-start;
            padding-left: 20px;
        }
        
        .js-tour-activation {
            height: auto;
            width: auto;
            line-height: normal;
        }
        
        ul.navbar-nav.ml-auto {
            height: 100%;
        }
        
        //.js-tour-activation img {
            // width: 100%;
            // height: 100%;
        //}
        .js-accountReach img {
            width: 30px;
            margin-left: 1rem !important;
            height: 30px;
            text-align: center;
            border-radius: 0;
        }
        button.btn.btn-primary.js-popin-submit.w-50 {
            width: 100% !important;
        }
    }
    .show-menu {
        .search {
            display: flex;
            align-items: center;
        }
    }
    // .new-project-create a {
    //     text-align: center;
    //     border-radius: 50%;
    //     text-indent: -99999px;
    //     width: 40px;
    //     position: relative;
    //     padding: 10px;
    //     height: 40px;
    // }
    
    // .new-project-create a:after {
    //     position: absolute;
    //     color: #fff;
    //     top: 4px;
    //     left: 12px;
    //     width: 15px;
    //     line-height: 30px;
    //     font-size: 28px;
    //     text-indent: initial;
    //     z-index: 99;
    //     content: "+";
    //     height: 15px;
    // }
    
    .new-project-create {
        text-align: right;
    }

    .new-project-content {
        position: relative;
    }

    .new-project-box {
        align-items: center;
    }
    
    .new-project-content h1 {
        text-align: left !important;
    }
    
    .new-project-create {
        position: fixed;
        bottom: 50px;
        right: 10px;
        z-index: 99999;
    }
    .vertical-menu.mobileshow {
        position: fixed;
        left:-100%;
        z-index: 999;
        top: 40px;
        animation: 0.6s leftright 0.2s ease-in forwards;
    }
    .bg-black .vertical-menu.mobileshow {
        z-index: 999;
        background: #363841;
    }
    @keyframes leftright {
        from {
            left:-100%;
        }
        to {
            left:0;
        }
    }

    .doc {
        margin: 10px;
    }

    .share-folder {
        margin-top: 15px;
        display: block;
    }
    
    .vertical-menu.mobileshow {
        padding: 0 !important;
    }
    
    .menu-links {
        margin: 0;
    }
    
    .menu-links > div {
        width: 100% !important;
        padding: 0 10px;
        font-size: 20px;
    }
    
    .js-drop {
        width: 100%;
    }

    .view-full .body-content .soancontainer > section h1 > span, .view-full .body-content .soancontainer > section .title span {
        display: block;
        line-height: 20px;
        text-align: left !important;
    }

    .steps-info {
        margin-top: 15px;
        display: block;
    }
    
    section.page-newmeeting.smallcontainer h1 span {
        text-align: left !important;
    }
    .meeting-content .no-picto img {
        width: 100%;
    }
    .page-newmeeting [id^="new_meeting_form_thDateChoices"] {
        display: block;
    }
    
    .page-newmeeting .meetingcontent {
        display: block;
    }
    
    .page-newmeeting .meetingcontent select {
        width: 30%;
        display: flex;
    }
    .inside-w50 .form-group {
        width: 100%;
    }
    .new-ubo form > .d-flex {
        display: block !important;
    }
    
    div#new_ubo_thBirthday {
        flex-flow: row;
    }
    
    .new-ubo form > .d-flex.w-25 {
        width: 50% !important;
    }
    
    #change_infos_thBirthday {
        flex-flow: row;
    }

    .password-check {
        border: 0;
        flex-wrap: wrap;
    }

    .btn-lbp {
        width: 80%;
    }

    .naked-header .logo-img {
        justify-content: center;
        padding-left: 0;
    }
    .slick-dots li.slick-active button:before {
        color: $cprimary-7 !important;
    }

    #new_document_thSignatureDueDatetime {
        flex-flow: row;
    }
    .cookielaw.active {
        width: calc(100% - 30px);
        height: auto;
        margin: 15px;
        right: auto;
    }
        
    .cookie-imgbox {
        width: 200px;
    }

    .cookielaw.active {flex-direction: row;}

    .cookie-textbox {
        text-align: left;
    }
    .cookie-text {
        text-align: left;
    }
    .cookielaw .okcookie {
        margin: 20px 0 20px 0;
    }

    .document-ticket span.cprimary.js-openpopin {
        text-align: center;
        border-radius: 50%;
        background: #439aff;
        text-indent: -99999px;
        width: 40px;
        display: block;
        position: relative;
        padding: 10px;
        height: 40px;
    }

    .document-ticket span.cprimary.js-openpopin:after {
        position: absolute;
        color: #fff;
        top: 4px;
        left: 12px;
        width: 15px;
        line-height: 30px;
        font-size: 28px;
        text-indent: initial;
        z-index: 99;
        content: "+";
        height: 15px;
    }
    .dashboard .step-date {
        color: darken($cprimary-7, 10%);
    }
    .dashboard .step.act .step-date {
        color: $cprimary-5;
    }
    .menuhide {
        left: auto;
        right: 0;
    }

    .add-folder a {
        display: block;
    }

    .add-folder a button {
        width: 100% !important;
        display: block;
    }

    .add-folder + div > a {
        text-align: center;
        border-radius: 50%;
        text-indent: -99999px;
        width: 40px;
        display: inline-block;
        position: relative;
        background: $cwhite !important;
        padding: 10px;
        height: 40px;
    }
    .btn-create-doc {
        align-items: flex-start !important;
    }

    .icon::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    
    .add-folder + div > a:after {
        position: absolute;
        color: #fff;
        top: 4px;
        left: 12px;
        width: 15px;
        font-family: "Font Awesome 5 Free";
        line-height: 30px;
        font-size: 28px;
        text-indent: initial;
        z-index: 99;
        content: url("../images/icon_download_main_green.svg");
        height: 15px;
    }
    .result  {
        max-width: 100%;
        max-height: 55vh;
    }
    //Change photo
    .result img {
        max-width: 100% !important;
        width: 100% !important;
        display: block;
    }

    .vertical-menu.mobileshow .text-center {
        text-align: left !important;
        padding-left: 15px;
    }

    .menu-links > div a {
        margin-left: 0;
    }
    .menu-links > div img {
        margin-right: 15px;
        height: 35px;
        width: 35px;
    }

    .menu-project-name {
        font-size: 24px;
    }
    .view-full .body-content .vertical-menu:after{
        display: none;
    }
    .bullet {
        vertical-align: top;
    }
    .menu-project-name .project-name {
        display: inline-flex ;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 15px;
    }
    .meeting .meeting-accept {
        width: 100%;
    }
    .new-projects-form h1 {
        font-size: 24px;
    }
    .form-group.grouped {
        display: inline-flex;
        padding: 10px;
        border: 1px solid grey;
        border-radius: 4px;
    }
    .change-menu-project-name input {
        width: 150px;
    }
    .create-project-actions {
        display: block !important;
    }
    
    .create-action-btn {
        margin-top: 20px;
    }
    .new-project-create {
        margin-top: 0!important;
    }
    .new-project-content h1 {
        margin: 20px 0;
    }
    .pointblue {
        display: none;
    }
    
    .pointyellow {
        display: none;
    }
    .register-img {
        display: none;
    }
    .st-anchor-header {
        display: block !important;
    }
    .st-dash-new-payment {
        margin: 30px auto 0;
    }
    .st-synthese-anchor {
        margin-top: 40px !important;
    }
    .dash-pay {
        flex-direction: column;
    }
    
    .dash-block-pay {
        // flex-direction: row;
        width: 100%;
        // display: flex;
        // justify-content: space-between;
    }
    
    .dash-topbar-img {
        display: none;
    }
    
    .dash-topbar-text div {
        flex-direction: column;
        align-items: flex-start !important;
    }
    
    .dash-wallet-info {
        flex-direction: column;
    }
    
    .dash-wallet {
        // width: 45%;
        text-align: center;
        padding: 20px;
    }

    .dash-wallet-info img {
        display: inline-block !important;
    }
    
    .dash-wallet {
        height: 220px;
    }

    .slick-prev {
        left: -15px;
    }
    
    .slick-next {
        right: -15px;
    }
    .dash-info {
        padding: 20px;
        height: 220px;
    }

    /*.dash-gauge {
        // width: 45%;
    }*/

    .dash-project {
        background: none;
        padding: 0;
    }
    
    .dash-level {
        width: 100%;
        margin-top: 20px;
    }
    
    .block-project {
        width: 162px;
        height: 162px;
        // height: 185px;
        margin-right: 0;
    }
    .dash-topbar-text a {
        margin: 10px 0 0 0 !important;
    }
    .dash-wallet div {
        margin-bottom: 15px;
    }
    .nolvl .dash-progressbar-info {
        right: -20%;
    }
    .dash-top-bar {
        height: auto;
    }
    .paymentaddmobile {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        padding: 0;
    }
    .inputsearchmobile {
        display: none;
        position: fixed;
        top: 50px;
        border-top: 0;
        //background: #000;
        height: 50px;
        z-index: 999;
        border-radius: 0 0 4px 4px;
        width: 100%;
        left: 0;
        color: #000;
        padding: 0 10px;
    }
    .inputsearchmobile .search-input {
        display: block;
        position: static !important;
        border-top: 0;
        background: #363841;
        height: 40px;
        z-index: 999;
        border-radius: 0 !important;
        width: 100% !important;
        left: 0 !important;
        color: #fff;
        padding: 0 10px !important;
    }
    .input-search-cross {
        margin-top: 10px;
        height: 40px;
        position: relative;
        width: 100%;
    }

    img.searchcross {
        position: absolute !important;
        right: 20px;
        z-index: 999;
        left: auto;
        width: 12px !important;
        padding: 0 !important;
        top: calc(50% - 6px);
        height: 12px !important;
    }
    
    .js-search-payment {
        width: 125px;
    }

    .payment-container {
        display: block !important;
        margin-top: 20px !important;
    }
    
    .payment-send {
        width: 100%;
        margin-left: 0;
    }
    
    // .filter-payment {
    //     flex-direction: column;
    // }

    .filter-container-payment {
        justify-content: flex-end !important;
    }

    .home-container {
        flex-direction: column;
    }
    
    .home-container aside {
        width: 100%;
        margin-right: 0;
    }
    
    .home-container section {
        width: 100%;
        margin-left: 0;
    }

    .members-list {
        position: relative;
        flex-direction: column;
        width: 100%;
    }
    
    .members-list-container {
        width: 100%;
    }
    
    .members-list > div {
        width: 100%;
    }
    
    .members-list-option {
        position: absolute;
        right: 0;
        top: 7px;
    }
    
    .members-list .rounded-picto {
        width: 30px !important;
        height: 30px !important;
    }
    
    .members-list .rounded-picto img {
        width: 30px !important;
        height: 30px !important;
    }
    
    .members-list-lvl {
        margin-left: 48px;
        margin-top: 10px;
    }
    
    .members-list .initial font {
        font-size: 10px;
    }

    .js-payment-selectcolumn {
        display: flex;
    }
    
    .js-payment-selectcolumn .notselected {
        opacity: 0.2;
    }
    
    .js-payment-selectcolumn .payment-header-list {
        display: block;
        width: 50%;
        text-align: center;
        cursor: pointer;
    }

    .folder {
        background: url("../images/folder-responsive.svg") no-repeat;
    }

    .doc-container {
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .new-payment-createbills [class^="step-content-"] {
        width: 100%;
    }
    .timeline-container {
        max-width: 100%;
    }
    .st-payment-giver-name, .st-payment-receiver-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80px;
    }
    
    .payment-info {
        width: 50%;
        max-width: none;
    }
    
    .payment-amount {
        width: 50%;
    }
    
    select.js-user-payment {
        width: 100%;
    }
    section.payment-choice-container {
        display: block;
    }

    .payment-choice-block {
        width: 100%;
        margin: 0;
        margin-top: 20px;
    }

    .timeline-container:not(.timeline-sent) {
        max-width: 100%;
    }

    .timeline-text {
        width: calc(100% - 20px);
    }
    .mail-sendto-container {
        display: block;
    }
    .archi-modifinfo-content .offers-content-text .d-flex {
        display: flex !important;
    }
    .contract-container {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .popin-wrap.popin-payment-bystep {
        width: 100%;
    }
    .dest-item, .project-item {
        padding: 5px 10px;
    }
    [class^="popin-step-content-"] {
        padding: 0;
    }
    
    .dest-item, .project-item {
        padding: 5px 10px;
    }

    [class^="popin-step-content-"] {
        padding: 0;
    }

    .popin-step-menu {
        width: 100%;
    }

    .popin-payment-bystep .form-group.w-50.mr-2 {
        width: 100% !important;
    }

    .popin-payment-bystep .form-group.w-25 {
        width: 100% !important;
    }

    .popin-payment-bystep .d-flex {
        display: block !important;
        &:nth-child(1) {
            display: flex !important;
        }
    }

    .popin-payment-bystep .form-group.w-50.ml-2 {
        margin-left: 0 !important;
        width: 100% !important;
    }
    
    .popin-payment-bystep .form-group.w-50.mr-2 {
        margin-right: 0 !important;
        width: 100% !important;
    }
    
    .popin-payment-bystep .w-50.mr-2 {
        width: 100% !important;
        margin-right: 0 !important;
    }
    
    .popin-payment-bystep .w-50.ml-2 {
        width: 100% !important;
        margin-left: 0 !important;
    }
    .popin-step-actions.d-flex {
        display: flex !important;
    }

    .myoffer-block {
        margin: auto;
        margin-top: 20px !important;
    }

    .myoffer-block div {
        align-items: center;
    }

    // .archi-modifinfo {
    //     text-align: center;
    // }

    .info-content.user-info, .archi-modifinfo-content-my-offer {
        text-align: center;
    }

    .archi-modifinfo .archi-modifinfo-title {
        text-align: center !important;
        margin-right: 0 !important;
    }

    .undefined-project .logo-img {
        justify-content: center;
        padding-left: 0;
    }

    //.undefined-project header .login {
    //    flex-basis: 30%;
    //    -webkit-flex-basis: 30%;
    //}

    .st-bloc-fonctionality-soan {
        display: none;
    }

    .round-avatar {
        border-radius: 50% !important;
    }

    .menu-mobile {
        display: block !important;
    }

    .container {
        aside {
            width: 100% !important;
            margin: 0 !important;
        }
        section {
            width: 100% !important;
            margin: 0 !important;
        }
    }

    .dash-project {
        margin-top: 50px;
    }

    .dash-project .filter {
        text-align: center;
    }

    .info-content.user-info div .d-flex .cgrey {
        //justify-content: space-between;
        text-align: left;
    }

    .info-content.user-info div .d-flex div:nth-child(2) {
        width: 50%;
        text-align: left;
    }

    .st-bloc-userInfos {
        width: 90%;
        margin: auto;
    }

    .rounded-picto {
        margin: auto !important;
    }

    .info-button {
        text-align: center;
        margin: auto;
        margin-top: 20px !important;
    }

    .st-companyInfos {
        width: 90%;
        margin: auto;
    }

    .st-companyInfos .companyinfos-block1 .d-flex, .st-companyInfos .companyinfos-block2 .d-flex {
        justify-content: space-between;
    }

    .companyinfos-infos .text-right {
        text-align: center !important;
        margin-top: 20px;
    }

    .archi-modifinfo .account-content h1, .form-wallet h3 {
        text-align: center !important;
        margin: 0 !important;
    }

    .form-inline .form-control {
        width: 100%;
    }

    .account-content .st-bloc-change-email {
        display: flex !important;
    }

    .dash-wallet div:nth-child(3) a, .dash-info div:nth-child(3) a, 
    .st-dash-info-company div:nth-child(4) a {
        display: block;
    }

    .accordeon-content .d-flex label {
        margin-left: 0 !important;
    }

    .popin-step-content-3 .payment-send-choice div:nth-child(1), 
    .popin-step-content-3 .payment-send-choice div:nth-child(2) {
        display: flex !important;
    }

    .submitSearchMobile {
        width: 20%;
        display: none;
    }

    .blocInputSearch {
        position: fixed;
        width: 90%;
        left: 5%;
        top: 60px;
    }

    .close-cross {
        margin-bottom: 10px;
    }

    .dotmenu-folder {
        display: block;
    }

    .msg-flash {
        width: 90% !important;
    }

    .alert.alert-success {
        justify-content: center;
    }

    .document-info .rounded-picto, .payment-sent-to .rounded-picto {
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    .picto-tangle .rounded-picto {
        margin-left: 0 !important;
        margin-right: -5px !important;
    }

    .popin-payment-bystep .st-escomptePopinPayment {
        display: flex !important;
    }

    .st-companyinfosInputChange .d-flex {
        flex-direction: column;
    }

    .blocArrayDashboard {
        & p {
            margin-top: 0;
        }
        & .st-tabHeader {
            padding: 16px;
            position: relative;
            & .st-searchDashboard {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: -50px;
                & img {
                    margin-left: 132px;
                }
                & .js-search-dashboard {
                    visibility: hidden;
                    margin-right: 50px;
                }
            }
        }
        & .st-arrayDashboard {
            & .st-rowDashboard {
                height: 190px !important;
                flex-direction: column;
                padding: 30px;
                border-radius: 0 !important;
                position: relative;
            }
            & .st-blocTopDashboard {
                margin-top: 5px;
                height: 64px;
                justify-content: space-between;
                & .st-divTopLeft, .st-divTopRight {
                    font-weight: bold;
                    font-size: 14px;
                }
                & .st-divTopLeft p:nth-child(1) {
                    margin-bottom: 0;
                }
                & .st-amountPrice {
                    font-size: 13px;
                    position: relative;
                    text-align: right;
                }
                & .st-escompteDashboard {
                    color: $cprimary-7;
                }
                & .st-labelEscompteDashboard {
                    text-align: right;
                    margin-right: 20px;
                }
                & .st-escompteLabelCustom {
                    margin-left: 8px;
                }
            }
            & .st-blocBottomDashboard {
                height: 89px;
                justify-content: space-between;
                & .st-labelEscompteDashboard img {
                    max-width: initial;
                    width: 68px;
                }
                & .st-divBottomRight {
                    width: auto;
                    & .actionButtonToPay, .actionButtonToSign {
                        width: 85px;
                        margin-right: 10px;
                        display: block;
                        margin-top: 0px;
                        height: 30px;
                    }
                    & .actionButtonToPay {
                        line-height: 30px;
                    }
                    & .actionButtonToSign {
                        line-height: 26px;
                    }
                }
                & .st-statePayment p {
                    margin-left: 10px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                & .st-statePayment .st-3xLabelCustom {
                    margin-left: -6px !important;
                }
                & .st-escompteDashboard p {
                    color: $cprimary-7;
                }
                & .st-divBottomLeft, .st-divBottomCenter, .st-divBottomRight {
                    margin-top: auto;
                    margin-bottom: 0;
                }
                & .st-divBottomLeft {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                & .st-divBottomCenter a {
                    color: $cprimary-7;
                    margin-left: 50px;
                }
            }
            & .st-divMarkAsPayed {
                position: absolute;
                text-align: -moz-right;
                text-align: -webkit-right;
                top: 5px;
                right: 0;
                & .st-points {
                    width: 4px;
                    height: 4px;
                    margin-top: 2px;
                    margin-bottom: 2px;
                }
            }
        }
        & .st-showMore {
            display: block;
            // border: 1px solid #707070;
            // color: #9C9EA0;
            // width: 90%;
            // border-radius: 100px;
            // margin-left: 16px;
            // margin-right: 16px;
            // padding-top: 8px;
            // padding-bottom: 8px;
            background-image: url("../images/icon_dropdown_green_smallDown.svg");
            background-repeat: no-repeat;
            background-position-x: 12px;
            background-position-y: center;
            // margin-top: 20px;
            width: 100px;
            text-align: center;
            // margin-right: 25px;
        }
    }
    .search-project {
        visibility: hidden;
    }
    .new-project-actions {
        position: absolute;
        top: -50px;
        right: 40px;
        margin-left: 0;
        left: auto;
        & img {
            margin-left: 132px;
        }
        & .search-project {
            width: 150px;
            margin-right: 16px;
        }
    }
    .st-titleSection {
        margin-left: 16px;
    }
    .container-block-project {
        margin-left: 10px;
    }
    .header-chart {
        padding-right: 16px;
    }
    .dash-project .open-menu-dots {
        display: block;
    }
    .st-escompteMsg {
        margin-left: 1rem;
    }

    .popin-needinfo .st-completeInfosRecovery {
        display: block !important;
        width: 200px !important;
    }

    .payment-out {
        & .container {
            max-width: initial;
        }
        & .st-blocCompanyInfos {
            width: 530px !important;
        }
        & .company-info {
            width: 530px;
        }
        & .st-3xOffline {
            height: 56px;
        }
        & .st-blocInputPayment {
            flex-direction: column !important;
            margin-right: initial;
            margin-left: initial;
            & div {
                justify-content: space-between;
                & p {
                    width: 150px;
                }
            }
        }
        & .st-blocSubscriptionOfflineMobile {
            width: 530px;
            & .st-containerSubscriptionOffline {
                flex-direction: column;
                align-items: center;
                & .st-listContainerSubscription {
                    width: 292px;
                }
            }
        }
    }
    .popin {
        .popin-payment-bystep {
            padding-left: 20px;
            .st-blocAddLogo {
                display: flex !important;
                & span {
                    margin-left: 10px;
                }
            }
            .js-blocMoreInfoEscompte, .js-contentMoreInfoEscompte, .js-blocMoreInfo3x, .js-contentMoreInfo3x {
                width: 80%;
            }
            .checkedEscompteLabel, .checked3xLabel {
                top: -16px;
            }
            .st-blocSelectEscompte {
                flex-direction: column;
            }
            .popin-step-header {
                overflow: auto;
                padding-bottom: 20px;
                flex-wrap: initial;
                display: block ruby;
                .step-header-1, .step-header-2, .step-header-3, .step-header-4 {
                    margin-right: 20px;
                    width: auto;
                }
            }
            .js-escompteSelectChoice, .js-escompteSelectDateChoice {
                z-index: 1;
                & div {
                    display: flex !important;
                }
            }
            .allMonthlyPayment div {
                display: flex !important;
            }
            .blocBillWithEscompte, .blocBillWithEscompteDate {
                width: 220px;
            }
            .st-selectEscompteNumber {
                margin-bottom: 30px;
            }
            .st-blocMonthlyPrice {
                flex-direction: column;
            }
            .popin-close {
                right: 18px;
                top: 25px;
            }
            .popin-title {
                font-size: 22px !important;
            }
        }
    }
    .st-legend {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        & .legend-goals {
            margin-bottom: 0;
        }
    }
    .st-legend div {
        flex-direction: column;
    }
    .st-legend span {
        margin-left: 0;
        margin-top: 5px;
    }
    .legend-block {
        top: 100px;
    }
    .chart-container .legend-out, .chart-container .legend-in, .chart-container .legend-billed {
        height: 12px !important;
        width: 27px !important;
        margin: auto !important;
    }
    .chart-container .scale {
        bottom: 0 !important;
        padding-left: 30px !important;
        & .scale-value {
            padding-bottom: 50px !important;
        }
    }
    .st-completeAccount {
        margin-right: 30px;
        margin-left: 30px;
        padding: 20px !important;
        & button {
            margin-top: 10px;
            min-width: auto;
            width: 168px;
            height: 35px;
            line-height: 10px;
        }
    }
    .st-dashboard-cards {
        margin-left: 30px !important;
    }

    .st-payedBefore {
        margin-bottom: 10px;
    }

    .st-home-dashboard-container {
        padding-bottom: 50px;
        & .container-block-project {
            margin-left: 26px;
            margin-right: 30px;
            & .block-project {
                width: 144px;
                height: 144px;
            }
        }
        .st-plusButton-submenu-container {
            bottom: 90px;
            right: 16px !important;
        }
        & .st-home-main-item {
            padding-top: 0;
            box-shadow: 0 3px 6px #00000029;
        }
        .st-blocDashNewPayment {
            display: none;
        }
        .st-home-main-item h1 {
            text-align: center;
            padding-top: 5px;
        }
        .st-anchor-header {
            position: fixed;
            width: 100%;
            z-index: 10000;
            bottom: 0;
            background: white;
            padding-top: 20px;
            padding-bottom: 20px;
            left: 0;
            padding-left: 30px;
            padding-right: 30px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: 0px -1px 6px #00000073;
            & h3 {
                font-weight: bold;
                font-size: 16px;
            }
        }
        .project-notif {
            height: 20px;
        }
        .new-project-box {
            margin-left: 30px;
        }
        .st-titleSection {
            margin-left: 30px;
        }
        .chart-container .header-chart {
            margin: 16px 30px;
        }
        .st-promotion-banner {
            margin-left: 30px;
            margin-right: 30px;
        }
        .blocArrayDashboard .st-tabHeader {
            padding: 16px 30px;
        }
        .blocArrayDashboard .st-arrayDashboard .st-divMarkAsPayed {
            top: 29px;
            right: 20px;
        }
        .st-amountPrice, .st-escompteDashboard {
            margin-right: 10px;
        }
    }

    .st-promotion-banner {
        margin: 10px 16px;
        width: auto;
        flex-direction: row;
        height: auto;
        padding: 20px;
        align-items: flex-start;
        .buttons-container {
            align-self: flex-end;
            margin-top: 5px;
        }
        & p {
            margin-top: auto;
        }
        &.st-promotion-banner-discount {
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .st-max-width-200px {
                max-width: 200px;
            }
            & div:nth-child(1) {
                & a {
                    margin-top: auto;
                    margin-bottom: auto;
                    & img {
                        max-width: none;
                    }
                }
            }
            & .js-deleteDiscountedNotification {
                max-width: none;
            }
            & div:nth-child(1) {
                & p:nth-child(2) {
                    margin-left: 17px !important;
                }
            }
        }
    }
    #paymentByStep-step5 {
        width: auto;
    }
    .st-folderDashboard {
        margin-left: 26px;
    }
    .supplierPaymentPage {
        .st-titleSection {
            margin-left: 0;
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .mail-sendto-container {
        & .select-echeance {
            width: auto;
        }
    }
    .st-width-login-container {
        width: 90%;
    }

    .st-min-height-auto-md-425px {
        height: auto;
    }

    .st-max-width-login-container {
        max-width: 90%;
    }

    .st-height-43px-md-63px {
        height: 43px;
    }

    .st-login-container {
        .login-title {
            font-size: 23px;
        }
        input {
            height: 32px;
        }
        .js-password-show {
            top: 5px;
        }
    }

    .w-100-md-auto {
        width: 100%;
    }

    .st-margin-bottom-18px-sm-32px {
        margin-bottom: 18px;
    }

    .st-margin-right-0-sm-50px {
        margin-right: 0px;
    }

    .document-image {
        .document-image-responsive {
            width: 146px;
            height: 207px;
        }
    }

    .billselm-title {
        width: 40%;
    }
    
    .step-after {
        width: 115px;
    }

    .step-circle {
        &.active {
            width: 30px;
            height: 30px;
        }
    }

    .step-text {
        text-transform: lowercase;
        font-size: 10px;
    }

    .company-block-actions {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .st-width-100-xs-364px {
        width: 100%;
    }

    .st-margin-right-xs-60px {
        margin-right: 0px;
    }

    .folder {
        background-size: contain;
        width: 100%;
        height: 170px;
    }

    .myoffer-block {
        width: 100%;
    } 

    .offer-payment-month div, .offer-payment-year div {
        flex-direction: column !important;
    }

    .kyc-row {
        .custom-file-label::after {
            position: static;
            margin: auto;
        }
    }

    .kyc-row .custom-file input + label {
        text-align: center;
        padding-top: 10px;
    }

    .banner-content {
        margin: auto;
        text-align: center;
    }

    .banner-btn {
        display: block;
        margin: auto;
        margin-top: 20px !important;
        text-align: center;
    }
    
    .banner-img {
        display: none;
    }
    .banner-wallet {
        .banner-img {
            display: none;
        }
    }

    .form-user-society .input-group {
        flex-direction: column;
    }

    .form-user-society .input-group input {
        width: 100%;
        border-top-right-radius: .25rem !important;
        border-bottom-right-radius: .25rem !important;
    }

    .form-user-society .input-group .input-group-append .btn {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        margin-top: 20px;
    }

    .info-content.user-info div .d-flex .cgrey {
        //justify-content: space-between;
        text-align: left;
    }

    .myoffer-block .d-flex {
        margin-bottom: 10px;
    }

    .myoffer-block div {
        flex-direction: column;
    }

    .submit-wallet-info {
        flex-direction: column !important;
        align-items: center;
    }

    .submit-wallet-info .form-group {
        width: 100% !important;
    }

    #change_infos_reset, #change_infos_submit {
        width: 70% !important;
        margin: auto;
    }

    .login ul li.li-help {
        padding: 0;
        width: 25px;
    }
    .app .st-blocNotificationBell.unread:before {
        right: 10px !important;
    }
    .st-notificationBell {
        right: -30px;
    }

    .st-blocSubscriptionOfflineMobile {
        margin-bottom: 150px;
    }

    .content-to-pay div .d-flex {
        flex-direction: column;
    }

    .content-to-pay div .d-flex div img {
        margin-left: 1px;
    }

    .st-titleBill {
        flex-direction: column;
    }

    .popin {
        width: 90%;
        min-width: auto;
        margin-top: 30px;
    }

    .popin-step-header {
        flex-wrap: wrap;
    }

    .popin-step-header div {
        width: 40%;
    }

    .popin-wrap.popin-payment-noiban .popin-actions {
        display: flex;
        flex-direction: column;
        align-items: end;
        & button:nth-child(1) {
            margin-top: 10px !important;
            margin-bottom: 20px !important;
        }
    }
    .st-home-dashboard-container h3.st-dossier-title {
        margin-top: 140px;
    }
    .chart-options {
        margin-top: 10px;
    }
    .chart-container {
        .graph-block {
            bottom: 0;
            overflow-y: hidden;
            overflow-x: scroll;
            position: absolute;
            z-index: 8;
        }
        .graph {
            bottom: 50px;
            width: 936px;
        }
        .wrap {
            height: 190px;
        }
        .st-tabHeader {
            display: block;
        }
        .header-chart {
            margin: 15px;
            padding: 0;
        }
        #chartFilter {
            font-size: 14px;
            margin-right: 0;
            width: 160px;
        }
        .st-turnover-total {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            line-height: initial;
            margin-top: 0;
            position: absolute;
            text-align: center;
            top: 0;
            b {
                line-height: initial;
            }
        }
        .st-openpopin-period {
            text-align: left;
        }
        .st-openpopin-goal {
            text-align: right;
        }
        .st-openpopin-goal, .st-openpopin-period {
            margin: 20px 0 0;
            width: 100%;
        }
        .legend-block {
            bottom: -15px;
            left: 0;
            margin-bottom: 15px;
            padding: 0 10px 0 10px;
            position: absolute;
            right: 0;
        }
        .st-legend {
            justify-content: space-between;
            span {
                font-weight: bold;
                line-height: 10px;
            }
            span:hover {
                font-weight: normal;
            }
        }
        .st-legend .legend-in, .st-legend .legend-billed, .st-legend .legend-goals {
            margin-left: 20px;
        }
        .legend-out, .legend-in, .legend-billed {
            height: 10.5px;
            width: 14px;
        }
        .legend-goals {
            height: 10px;
            width: 10px;
        }
        .bar:hover {
            background: #54FCD1;
        }
        .bar:hover .tooltip-legend, .st-goal:hover .tooltip-legend {
            display: none;
        }
        .responsive-tooltip {
            box-shadow: 0px 6px 12px #00000029;
            display: block;
            font-size: 10px;
            height: 58px;
            margin: 0 auto;
            opacity: 0;
            padding: 5px 15px 8px;
            width: max-content;
        }
        .scale {
            background-color: #FFFFFF;
            bottom: 50px;
            padding-left: 16px;
            position: absolute;
            width: 60px;
            z-index: 9;
        }
        .scale-value {
            font-size: 10px;
        }
        .val-4 {
            bottom: 99%;
        }
        .month:after{
            bottom: -25px;
        }
    }
    .popin-wrap.period {
        padding: 20px !important;
        & .popin-close {
            right: 20px !important;
            top: 20px !important;
        }
    }
    .st-escompteLabelMsg {
        margin-top: -7px;
    }

    .st-blocInputPayment div p {
        margin-left: 20px;
    }

    .popin-bankwire-infos, .popin-card-form, .popin-bankDebit, .popin-paying-3xFreeOfCharge, .popin-payingWithMandate {
        padding: 30px;
        .popin-close {
            top: 15px;
            right: 15px;
        }
        .popin-title {
            font-size: 22px;
        }
        .select-bank {
            padding-right: 50px;
        }
        .popin-actions {
            text-align: center;
            margin-top: 0;
        }
        .d-flex:not(.st-blocPopinError) {
            flex-direction: column !important;
            margin-bottom: 20px;
        }
        .st-BlocMensuality .d-flex {
            flex-direction: row !important;
        }
        .st-not-mb {
            margin-bottom: 0px !important;
        }
        .st-firstAlignInput {
            margin-right: 0;
        }
        .st-mensuality {
            font-size: 16px;
        }
        .st-redirectRegistration {
            width: auto;
        }
    }

    .payment-out {
        & .company-payed {
            margin-left: 0;
        }
        & .st-blocSubscriptionOfflineMobile {
            width: 100%;
            padding: 20px 14px 20px 14px;
            margin-bottom: 220px;
        }
        .st-blocFactureOffline {
            position: relative;
        }
        .st-atoutSoan {
            position: absolute;
            top: 75px;
            width: 100%;
        }
        .st-atoutSoanUsed {
            position: absolute;
            top: 60px;
            width: 100%;
        }
        & .st-blocCompanyInfos {
            width: 100% !important;
            padding: 20px;
            margin-top: 65px;
            & .st-companyInfos {
                flex-direction: column;
                margin-bottom: 10px;
                & img {
                    margin-left: auto;
                    margin-right: auto;
                }
                .company-name {
                    margin-top: 12px;
                }
            }
        }
        & .company-info {
            width: 100%;
            overflow: hidden;
            height: auto;
            padding: 10px 20px;
            min-height: auto;
        }
        & .company-table-header {
            justify-content: space-around;
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            background-color: white;
            z-index: 10;
        }
        & .content-to-pay, .content-payed {
            overflow: hidden;
            height: auto;
            margin-top: initial;
            margin-left: 0;
            & .content-item:first-child {
                margin-top: 20px;
            }
            & .content-item:last-child {
                margin-bottom: 20px;
                border: 0;
            }
            & .st-containerhasPayed {
                flex-direction: column;
            }
        }
        .st-paymentOfflineBloc {
            position: fixed;
            bottom: 0;
            background-color: white;
            width: 100%;
            padding: 22px;
            height: auto;
            left: 0;
            & .st-blocIconLock {
                display: none;
            }
            .st-block-payment-choices {
                height: 130px;
                min-height: auto;
            }
        }
        & .st-blocTotalOffline {
            margin-left: 0;
            width: auto;
            flex-direction: column;
            display: none !important;
        }

        & .st-divBtnPayed {
            margin-right: auto;
        }

        & .st-divBtnPayed {
            height: 45px;
        }

        & .st-blocBtnPayed {
            margin-top: auto;
            margin-bottom: auto;
        }
        & .st-iconOfflineEscompte {
            margin-top: -1px;
        }
        & .st-blocFactureOffline, .st-blocIconFacture {
            flex-direction: row !important;
            width: 100%;
        }
        & .st-blocIconFacture {
            justify-content: flex-end !important;
            margin-top: 20px;
        }

        & .st-blocPriceOffline, .st-infosPaymentOffline {
            width: 50% !important;
        }

        & .js-payment-data {
            flex-direction: column;
        }
        .st-iconDownload, .st-iconEye {
            display: flex;
            background-color: #F7F7F7;
            padding: 10px 10px;
            margin-top: auto;
            margin-right: 0;
            margin-bottom: auto;
            border-radius: 10px;
            height: 42px;
            & p {
                margin-bottom: 0;
            }
            & span {
                margin: auto;
            }
        }
        .st-iconDownload {
            margin-left: 5px;
        }
        .st-blockCheckbox {
            margin-top: 20px !important;
        }
        .st-infosPaymentOffline {
            & div:nth-child(3) {
                width: 200%;
            }
        }

        .st-emptyBillToPay {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .st-listContainer {
            flex-direction: column !important;
        }
        .st-btnSubscriptionOffline {
            margin-bottom: 20px;
        }
    }
    .st-escompteLabelMsg {
        margin-left: 0 !important;
        & span {
            position: relative;
            top: 5px;
        }
        & .st-escompteMsg {
            font-size: 0.8em;
        }
    }

    .subject-payment {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 120px;
    }

    .st-truncate-65px-sm-100px {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100px;
    }

    .swal-modal {
        width: 90% !important;
        height: auto !important;
    }

    .popin-sharedwith {
        .popin-close {
            top: 15px;
            right: 20px;
        }
        .js-allMembersContent {
            flex-wrap: initial;
            flex-direction: column;
            & div {
                width: 100%;
            }
        }
        .externalMemberEmailInput {
            width: 190px;
        }
        .js-addMemberButton {
            margin-left: 15px;
        }
        .js-addMemberMessage {
            width: 100%;
            margin-right: 0;
            margin-top: 15px;
        }
    }
    .st-offlinePayment {
        .js-changeLocale {
            position: fixed;
        }
    }
    .supplierPaymentPage {
        .st-footerMobile {
            display: flex;
        }
        .st-back-dashboard {
            margin-top: 20px;
        }
        .st-blocFormFacture {
            display: none;
        }
        .st-blocNumberBills {
            width: 100%;
            padding-left: 12px;
        }
        .js-numberBills {
            padding-right: 12px;
        }
        .js-browseImport {
            display: none !important;
        }
        .st-blocFormFacture {
            margin-top: 0;
            .form-group {
                margin-right: 0;
                input, select {
                    width: 100%;
                }
            }
            width: 100%;
            padding: 0 12px;
        }
        .st-blocImportFacture  {
            background-color: transparent;
            height: auto;
            padding-bottom: 50px;
            h3 {
                margin-top: 30px;
            }
        }
        .st-blocImportFacture {
            width: auto;
        }
        .js-blocImportBills {
            margin: auto;
            width: 90%;
        }
        .deleteDoc {
            margin-bottom: 50px;
        }
        .js-formContact div.d-flex, .invoice-infos div.d-flex {
            flex-direction: column;
        }
        .invoice-infos {
            .js-step1Supplier {
                display: none;
            }
        }
        .st-blocFormError1, .st-blocFormError2, .st-blocFormError3, .st-blocFormErrorImport, .st-infoApproval {
            flex-direction: row !important;
            height: auto !important;
            margin: auto;
            margin-bottom: 20px;
            padding: 10px 0;
        }
        .st-infoApproval {
            margin-right: 2px;
        }
        .js-selectDevise {
            margin-bottom: 50px;
        }
        .st-formPart1, .st-supplierTVA {
            margin-bottom: 0 !important;
        }
        .st-formPart1 {
            margin-top: 20px;
        }
        .st-formPart2 .form-group:nth-child(1) {
            margin-bottom: 15px !important;
        }
        .st-destEmail {
            width: 100%;
        }
        .st-formApproval {
            align-items: flex-end;
            flex-direction: column;
            .form-group {
                margin-right: 0 !important;
                width: 100%;
            }
        }
        .st-containerContact {
            height: auto !important;
            div.d-flex {
                flex-direction: column;
            }
        }
        .st-container1 {
            margin-top: 20px !important;
        }
        .st-blocSendApprovalRequest {
            width: 100%;
            .js-send-approval-request {
                margin-top: 28px !important;
                margin-left: 20px;
            }
        }
        .st-saveQuit1, .st-saveQuit2, .st-saveQuit3, .js-toPayNow, .js-toPayLater, .js-toAlreadyPay {
            display: none;
        }
        .st-blocPayment {
            div.d-flex {
                flex-direction: column;
                div {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    margin-top: 20px !important;
                    width: 100% !important;
                }
            }
            .st-blocRadio1 {
                margin-bottom: 300px;
            }
            .st-blocRadio2 {
                margin-bottom: 30px;
            }
            .st-blocRadio3 {
                margin-bottom: 100px;
            }
        }
        .st-drop-down, .st-drop-up {
            background-color: $csecondary-1;
            border-radius: 6px;
            width: 36px;
        }
        .invoice-approval {
            div.d-flex {
                justify-content: space-between;
            }
        }
        .st-blocPayNow, .st-blocPayLater {
            .mt30 {
                margin-top: 0 !important;
            }
        }
        .st-blocPayNow {
            margin-top: -490px;
        }
        .st-blocPayLater {
            margin-top: -520px;
        }
        .st-statusApproval {
            justify-content: flex-start !important;
        }
        .st-blocPayNow .st-blocSendEmail {
            margin-top: 1rem !important;
        }
    }
    .mobileColumn {
        flex-direction: column !important;
    }
    .popin-payment-bank {
        padding: 30px;
        .st-grey-title {
            font-size: 32px;
        }
        .title-lock {
            font-size: 22px;
        }
        .st-blocMenuPayment {
            flex-direction: column;
            button {
                margin-bottom: 10px;
            }
        }
        .popin-actions {
            .st-cancelBtn {
                margin-bottom: 15px;
            }
        }
        .js-autocompleteBank {
            width: auto;
            margin-right: 30px;
        }
    }
    .popin-add-bank, .popin-addBankingCard {
        .popin-title {
            font-size: 22px !important;
        }
        div.justify-content-between {
            flex-direction: column;
            div {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }
        .st-firstAlignInput {
            margin-right: 0;
        }
        .st-blocExpirationDate {
            margin-bottom: 20px;
        }
    }

    .st-mobileFlex {
        flex-direction: column;
    }

    .soancontainer {
        position: relative;
        z-index: 1;
    }
    .st-lbpBnt-text {
        transform: translateX(0);
    }
    .st-lbp-rounded {
        display: none;
    }
}

@media (max-width: 420px) {

    .st-min-width-sm-364px {
        min-width: auto;
    }

    .googleconnect, .linkdinconnect {
        height: 32px;
    }

    .container-block-project {
        justify-content: space-between;
        margin-right: 15px;
    }

    .block-project {
        position: initial !important;
        transform: initial !important;
    }

    .st-xs-width-80 {
        width: 80%;
    }

    .st-home-dashboard-container {
        padding-bottom: 50px;
        & .container-block-project {
            margin-left: 26px;
            margin-right: 26px;
            & .block-project {
                width: 144px;
                height: 144px;
            }
        }
        .st-plusButton-submenu-container {
            bottom: 90px;
            right: 16px !important;
        }
        & .st-home-main-item {
            padding-top: 0;
            box-shadow: 0 3px 6px #00000029;
        }
        .st-blocDashNewPayment {
            display: none;
        }
        .st-home-main-item h1 {
            text-align: center;
            padding-top: 5px;
        }
        .st-anchor-header {
            position: fixed;
            width: 100%;
            z-index: 10000;
            bottom: 0;
            background: white;
            padding-top: 20px;
            padding-bottom: 20px;
            left: 0;
            padding-left: 30px;
            padding-right: 30px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: 0px -1px 6px #00000073;
            & h3 {
                font-weight: bold;
                font-size: 16px;
            }
        }
        .new-project-box {
            margin-left: 30px;
        }
        .st-titleSection {
            margin-left: 30px;
        }
        .chart-container .header-chart {
            margin: 16px 30px;
        }
        .st-promotion-banner {
            margin-left: 30px;
            margin-right: 30px;
        }
        .blocArrayDashboard .st-tabHeader {
            padding: 16px 30px;
        }
        .blocArrayDashboard .st-arrayDashboard .st-divMarkAsPayed {
            top: 29px;
            right: 20px;
        }
        .st-amountPrice, .st-escompteDashboard {
            margin-right: 10px;
        }
        .st-promotion-banner {
            flex-direction: column;
        }
    }
    .popin {
        height: 100%;
        margin-top: 0;
        max-height: initial;
        min-width: auto;
        left: initial;
        top: initial;
        transform: initial;
        width: 100%;
        z-index: 100000;
        .popin-wrap {
            height: initial;
            padding: 30px 15px;
            .popin-title {
                width: 90%;
            }
            input, select {
                height: 38px;
                width: 100% !important;
            }
            .form-group, .w-50 {
                width: 100% !important;
            }
        }
        .popin-confirmModifyInfos .popin-actions {
            flex-direction: column;
            .js-confirmModifyInfos {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        .popin-payment-bystep {
            .my-bills-delay, .bills-elm-ht, .bills-elm-tva, .bills-delay, .bills-penality, .bills-damage {
                width: initial !important;
            }
            .js-offerEscompte div.d-flex, .st-blocActifMail {
                display: flex !important;
            }
            #js-mailCustomInput {
                height: auto;
                margin-top: 6px;
            }
        }
    }
    .st-blocGlobalFakeLink {
        flex-direction: column;
        .st-relanceAppro {
            margin-left: 0 !important;
            margin-top: 15px !important;
        }
    }
    .st-to-complete {
        margin-right: 10px;
        padding: 5px;
    }
    .js-starting-month {
        color: $cprimary-12 !important;
    }
    .js-send-startingDate {
        position: initial !important;
    }
}

@media (max-width: 360px) {
    .st-truncate-65px-sm-100px {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 65px;
    }

    .st-xs-11px {
        font-size: 11px;
    }

    #header .st-iconBell {
        margin: 0px 5px;
    }

    .li-img-profil {
        padding: 0px 5px 0px 0px;
    }

    .project-acceptance {
        display: block;
    }

    .new-project-box {
        flex-direction: column;
    }

    .st-bloc-userInfos {
        width: 100%;
    }

    .st-companyInfos {
        width: 100%;
    }

    .st-companyInfos .companyinfos-block1 .d-flex, .st-companyInfos .companyinfos-block2 .d-flex {
        flex-direction: column;
    }

    .st-companyInfos .right-info, .st-companyInfos h2 {
        margin-bottom: 10px;
    }

    .st-companyInfos h2 {
        font-size: 1.9em;
        margin-bottom: 15px;
        margin-top: 20px;
        text-align: center;
    }

    .moreOptions .archi-modifinfo-content a {
        width: 80%;
    }

    .js-send-search-user {
        width: 80% !important;
        display: block;
        margin: auto;
    }

    .step-company-container .step-after {
        width: 100px;
    }

    .company-input-container div:nth-child(1) {
        width: 50% !important;
    }

    .info-content.user-info div .d-flex .cgrey {
        text-align: left;
    }

    .submit-wallet-info .form-group {
        width: 100% !important;
        margin: auto !important;
        margin-bottom: 20px !important;
    }

    .submit-wallet-info .form-group button {
        width: 100% !important;
    }

    #change_infos_reset, #change_infos_submit {
        width: 90% !important;
        margin: auto;
    }

    .activity-type:after {
        left: calc(50% - 2.4px) !important;
    }

    .st-div-btn-popinOffline {
        text-align: center;
    }

    .popin-bankwire-infos {
        padding: 30px;
    }

    .popin-close, .popin-flash-close:not(.btn) {
        top: 30px;
        right: 30px;
    }

    .replacedoc {
        padding: 18px;
    }
    #cp1 div .ctc-price, #cp2 div .ctc-iban, #cp3 div .ctc-bic, .reference-content .ctc-reference, .click-to-copy {
        display: none !important;
    }

    .popin-step-header div {
        width: 100%;
    }

    .payment-out .secure-block img.logo-payment {
        padding-right: 0;
        border-right: 0;
    }

    .payment-out .secure-block b {
        padding-top: 0;
    }

    .popin-need-info, .popin-bankwire-infos {
        & .popin-close {
            top: 15px;
            right: 15px;
        }
    }

    .st-escompteMsg {
        margin-left: 10px;
        margin-right: 10px !important;
        font-size: 0.8em;
    }

    .st-default-bank-text {
        font-size: 11px;
    }
    .bank-details {
        div {
            font-size: 15px !important;
        }
        span {
            font-size: 10px !important;
        }
    }

    .timeline-mail {
        .js-addModel {
            margin-top: auto;
            margin-bottom: 15px;
        }
    }

    .st-ourOfferGlobalContainer {
        h1 {
            font-size: 22px !important;
        }
    }
    .popin-buy-offer {
        padding: 30px;
        .popin-close {
            top: 15px;
            right: 15px;
        }
        .js-deleteCodePromo {
            display: block;
        }
    }
}


