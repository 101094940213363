.supplierPage {
    padding-bottom: 50px;
    margin: auto;
    margin-top: 130px;
    width: 1125px;
    .js-redirect-addSupplier {
        background: none;
        border: 0;
        border-radius: 6px;
        height: 38px;
        margin-right: 26px;
        padding: 0 26px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .js-redirect-supplierPayment {
        background-color: $cprimary-12;
        border: 0;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 36px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
    h1 {
        margin-right: 15px;
    }
    .js-openPopin-updateSupplier {
        border-radius: 6px;
        height: 32px;
        width: 32px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .st-supplierBusinessName {
        color: $csecondary-4;
    }
    .st-back-dashboard {
        margin-top: initial;
        width: fit-content;
    }

    // Disbursement bloc
    .st-blocDisbursement {
        background-color: $csecondary-1;
        border-radius: 10px;
        padding: 30px 50px;
        text-align: right;
        width: 300px;
        .js-datepicker {
            border-radius: 6px;
            color: $csecondary-3;
            height: 36px;
            margin-right: 0;
            margin-left: auto;
            // width: 172px;
            input {
                color: $csecondary-3 !important;
            }
            label {
                cursor: pointer;
                margin-top: 8px;
                width: 100%;
            }
            &:hover {
                background-color: white !important;
            }
        }
        .form-control {
            background-color: transparent;
            border: 0;
            height: 36px;
            padding: 0 0 0 4px;
            width: 80px;
        }
    }
    .st-totalDisbursement {
        font-family: "Poppins";
    }

    // Operations bloc
    .st-blocOperations {
        button {
            text-align: center;
        }
    }
    .st-filterOperations, .st-filterBills {
        display: none;
    }
    .st-blocFilterDate {
        margin-top: -2px;
    }
    .st-separatorFilterDate {
        margin-top: 4px;
        font-size: 14px;
    }
    .js-show-filterOperations, .js-show-filterBills {
        background-image: url("/build/images/icon_filter_grey_noBG.svg");
        height: 32px;
        margin-top: -5px;
        width: 32px;
        &:hover {
            background-image: url("/build/images/icon_filter_grey.svg");
        }
    }
    .js-hide-filterOperations, .js-hide-filterBills {
        background-image: url("/build/images/icon_filter_black_noBG.svg");
        display: none;
        height: 32px;
        margin-top: -5px;
        width: 32px;
        &:hover {
            background-image: url("/build/images/icon_filter_black.svg");
        }
    }
    .js-sortOperations {
        background-image: url("/build/images/icon_dropdown_whitegrey.svg");
        background-size: 12px;
        border: 0;
        color: $csecondary-3;
        font-weight: normal;
        height: 32px;
        margin-top: -4px;
        margin-left: 25px;
        padding: 0 20px 0 0;
        option {
            color: $cprimary-12;
        }
    }
    .st-searchOperations, .st-searchBills {
        margin-left: 25px;
        margin-top: -4px;
        img {
            left: 0;
            width: 18px;
        }
        input {
            border: 0;
            height: 32px;
            padding-left: 25px;
        }
    }
    .st-totalWaitingOperations, .st-totalSuccessOperations, .st-totalWaitingBills, .st-totalSuccessBills {
        color: $csecondary-3;
        margin-top: -2px;
    }
    .js-showMoreWaitOpe, .js-showMoreSuccessOpe, .js-showMoreWaitBills, .js-showMoreSuccessBills, 
    .js-reduceWaitOpe, .js-reduceSuccessOpe, .js-reduceWaitBills, .js-reduceSuccessBills {
        background: none;
        border: 0;
        border-radius: 4px;
        color: $cprimary-7;
        display: none;
        height: 28px;
        padding: 0 12px;
        margin-top: 20px;
        &:hover {
            background-color: $cprimary-1;
        }
    }
    .st-emptyOperations {
        display: block;
        text-align: center;
        p {
            color: $csecondary-4;
        }
    }
    .st-rowSupplierPayment {
        height: 82px;
    }
    .st-rowSupplierPayment:nth-of-type(2n+1) {
        background-color: $csecondary-1;
        border-radius: 8px;
    }
    .st-supplierPaymentBloc1 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .st-supplierPaymentBloc2 {
        cursor: pointer;
        padding-left: 10px;
        margin-top: auto;
        margin-bottom: auto;
        width: 210px;
    }
    .st-supplierPaymentBloc3 {
        cursor: pointer;
        padding-left: 230px;
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 125px;
        p {
            width: 110px;
        }
    }
    .st-supplierPaymentBloc4 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        img {
            margin-right: 8px;
            margin-top: 4px;
        }
        p {
            font-style: italic;
            width: 152px;
        }
    }
    .st-supplierPaymentBloc5 {
        margin: auto 18px;
        text-align: right;
        width: 152px;
        .st-toPay {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 50px;
            color: white;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 32px;
        }
        .st-programmedPayment {
            background-color: transparent;
            border: 1px $cprimary-7 solid;
            border-radius: 50px;
            color: $cprimary-7;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
        }
        .st-to-complete {
            background-color: transparent;
            border: 1px solid $cprimary-12;
            border-radius: 50px;
            color: $cprimary-12;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
        }
    }
    .st-supplierPaymentBloc6 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        padding-right: 18px;
        position: relative;
        p {
            background-color: #02130E;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            margin-top: 3px;
            margin-bottom: 3px;
        }
        .st-actionsSupplierPayment {
            display: none;
        }
        & .js-markAsPayed, .js-unmarkAsPayed, .st-alone-delete, .st-mark-delete, .st-unmark-delete {
            background-color: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 4px 4px 0 0;
            color: #aaabaf;
            cursor: pointer;
            font-size: 14px;
            height: auto;
            padding: 10px;
            position: absolute;
            right: 0px;
            text-align: center;
            top: 10px;
            width: 190px;
            z-index: 2;
            &:hover {
                color: $cprimary-7;
            }
        }
        .st-alone-delete {
            border-radius: 0 0 4px 4px;
            top: 0;
        }
        .st-mark-delete {
            border-radius: 0 0 4px 4px;
            top: 53px;
        }
        .st-unmark-delete {
            border-radius: 0 0 4px 4px;
            top: 74px;
        }
    }

    // Bills bloc
    .js-openPopin-downloadAllBills, .js-openPopin-downloadAllDocs {
        background: transparent;
        border: 1px solid $cprimary-12;
        border-radius: 6px;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $csecondary-1;
        }
        img {
            margin-top: -2px;
            margin-right: 5px;
        }
    }
    .js-openPopin-uploadBills, .js-openPopin-uploadFiles, .js-openPopin-addFolderSupplier {
        background: transparent;
        border: 0;
        border-radius: 6px;
        height: 40px;
        padding: 0 10px;
        &:hover {
            background-color: $csecondary-1;
        }
        img {
            margin-top: -4px;
        }
    }
    .js-container-waitingBills, .js-container-successBills {
        display: flex;
        flex-wrap: wrap;
    }
    .st-containerBill {
        border: 2px solid $csecondary-2;
        border-radius: 4px;
        height: 156px;
        &:nth-child(6n) {
            margin-right: 0;
        }
        margin-right: 57px;
        margin-bottom: 30px;
        text-align: center;
        width: 140px;
    }
    .st-emptyBills, .st-emptyFiles, .st-emptySearch {
        background-color: $csecondary-1;
        border-radius: 4px;
        color: $csecondary-4;
        font-size: 14px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        width: 100%;
    }
    .st-emptyBills {
        display: none;
    }
    .st-blocFormError1 {
        width: 400px;
    }
    .js-changeFromDateBill, .js-changeToDateBill {
        border: 0;
        width: 86px;
    }

    // Folder bloc
    .st-containerFolder {
        margin-right: 15px;
        position: relative;
        div {
            left: 20px;
            position: absolute;
            top: 40px;
            z-index: 10;
        }
        h4 {
            margin-bottom: 17px;
        }
    }
}

.form-control[readonly] {
    background-color: transparent !important;
}

/*popin delete supplier */

.st-isCanceled {
    .st-cancel {
        display: none;
    }

    .st-achivedTextAlign {

    }

    .st-deleteSupplierPopinButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .st-blackCross {
        margin-right: -12px;
        height: auto;
        width: 10%;
    }

    //todo : retouches
}

.st-greenCross,
.st-blackCross {
    margin-right: 6px;
    height: auto;
    width: 10%;
}

.st-deleteSupplierPopinButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.st-imgArchive{
    height: auto;
    width: 30px;
}

.st-archiveDeleteSupplier {
    display: none;
}

.st-archiveImg {
    cursor: pointer;
    padding:0;
    margin: 0;
    height: 100%;
}

.st-archiveImg:hover .st-archiveDeleteSupplier{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.st-archiveImg:hover{
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    padding-right: 10px;
}

.st-wordArchiver{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.st-actionsFicheSupplier{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.st-imgTrashFiche {
    height: auto;
    width: 24px;
    margin-left: 20px;
}



.st-imgArchiveFiche,
.st-imgTrashFiche
{
    height: auto;
    width: 24px;
    margin: auto;
}

.st-hr {
    margin-top: 100px;
}

.st-icon-footer-left,
.st-icon-footer-right {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 54px;
    height: 54px;
}

.st-icon-footer-left {
    margin-right: 20px;
}

.st-icon-footer-right {
    margin-left: 20px;
}

.st-icon-footer-left:hover,
.st-icon-footer-right:hover {
    background-color: #F8F8F8;
}