.autocomplete-fail {
    display: none;
}

.autocomplete-fail.active {
    display: block;
}

.autocomplete-rslt {
    display: none;
    background: #f8f8f8;
    color: $cprimary-12;
    border-radius: 0 0 4px 4px;
}

.autocomplete-rslt.active {
    display: block;
}

.autorslt {
    padding: 10px;
    cursor: pointer;
    text-align: left;
}

.companyrslt {
    padding: 10px;
    cursor: pointer;
    text-align: left;
}

input#company_infos_thBusinessName::placeholder {
    color: rgba(255, 255, 255, 0.705);
}

.btn-outline-secondary {
    border-radius: 25px;
}

.autocomplete-submit {
    cursor: pointer;
}

/*Fun*/

// #company_infos_thBusinessName.input-error {
//     animation: blink 1s infinite;
// }

// input#company_infos_thBusinessName::placeholder {
//     color: #fff;
// }

// @keyframes blink {
//     0%, 49% {
//       background-color: $cprimary-7;
//       border: 3px solid $corange;
//     }
//     50%, 100% {
//       background-color: $corange;
//       border: 3px solid $cprimary-7;
//     }
//   }

.autorslt:hover {
    color: $cprimary-7;
    font-weight: bold;
    border-bottom: 1px solid $cprimary-7;
    border-top: 1px solid $cprimary-7;
}

.companyrslt:hover {
    color: $cprimary-7;
    font-weight: bold;
    border-bottom: 1px solid $cprimary-7;
    border-top: 1px solid $cprimary-7;
}