form[name='user_registration'] .steps-info {
    margin: 20px 0;
}

.register-container .steps-info {
    margin: 30px 0;
}


.form-check-label .invalid-feedback span.form-error-message {
    position: absolute;
    top: 15px;
    left: 0;
    max-width: 300px;
    white-space: nowrap;
    right: 0;
}

.form-check span.invalid-feedback.d-block {position: relative;}

#freelance_registration_plainPassword_first.is-invalid {
    background-image: none;
}

.plainpassword .form-group {
    // width: 50%;
    position: relative;
}

.pwdok {
    position: absolute;
    top: 33px;
    right: 10px;
}

// .plainpassword .form-group:last-child {
//     margin-left: 1rem!important;
// }

.st-blocFormError1 {
    margin-bottom: 30px !important;
}
.h584 {
    height: 584px !important;
}
#freelance_registration_submit {
    width: 50%;
    margin: 0 auto;
}

.password-check {
    color: $csecondary-4;
    display: none;
    margin-top: 10px;
}

.good {
    color: $cprimary-7;
}

.err {
    color: $cred;
}
.already-registered:hover {
    color: $csecondary-4;
}
.btn-login {
    border-radius: 6px;
    height: 44px;
}
.register-img {
    margin-right: 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
}
.blueStar, .lbp-logo, .cross-separator, password-confirm {
    display: none;
}
.registration {
    background-color: $csecondary-1;
}
.h1-lbp {
    display: none;
}
.registration .st-login-container {
    height: 514px;
}
.registration .st-login-container {
    height: 514px;
}


.btn-lbp {
    background: transparent;
    border: 1px solid $clbp;
    border-radius: 6px;
    color: $clbp;
    height: 44px;
    width: 271px;
    &:hover {
        background-color: $csecondary-1;
        img {
            opacity: 1;
            transform: translateX(-10px);
        }
        .st-lbpBnt-text {
            transform: translateX(-10px);
        }
    }
}
.label-cgo a {
    color: $cprimary-7;
}
.st-lbp-rounded {
    height: 26px;
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    width: 26px;
}
.st-lbpBnt-text {
    display: inline-block;
    transform: translateX(15px);
    transition: transform 0.5s;
}

.registration.lbp-page {
    background-color: #17479E0D;
    .blueStar, .h1-lbp{
        display: block;
    }
    .partners-logo {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        img {
            display: inline-block;
        }
    }
    .partners-logo p:first-child {
        padding-right: 15px;
    }
    .partners-logo p:last-child {
        padding-left: 15px;
        text-align: left;
    }
    .lbp-logo-container {
        text-align: right;
    }
    .logo-soan {
        height: 66px;
        width: 118px;
    }
    .lbp-logo {
        width: 66px;
    }
    .greenStar, .st-login-partners, .h1-login {
        display: none;
    }
    .cross-separator {
        width: 30px;
    }
    .lbp-color, .already-registered, .already-registered:hover, .label-cgo a {
        color: $clbp;
    }
    .st-login-container {
        height: 439px;
    }
    .btn-login {
        background-color: $clbp;
    }
}