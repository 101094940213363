.document-container {
    display: flex;
    flex-direction: column;
    position: relative; /*for iframe*/
}

.document-top {
    display: flex;
    padding-bottom: 20px;
    border:0;
    margin-top: 20px;
}

.document-delete {
    color: #d91e18;
    &:hover {
        color: #d91e18;
    }
}

.document-image {
    margin-right: 20px;
    height: auto;
    width: 275px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
        .document-delete {
            display: block;
        }
    }
    .document-delete {
        animation: uptomid 1s ease-in-out;
        top: 50%;
        display: none;
        text-align: center;
        position: absolute;
        color: red;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        > div:first-child {
            width: auto;
            border: 2px solid red;
            padding: 5px 12px;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            color: red;
            background: #fff;
            display: inline-block;
        }
        > div:last-child {
            white-space: nowrap;
            font-weight: bold;
        }
    }
    .document-image-responsive {
        width: 75px;
        height: 106px;
    }
    // img {
    //     max-height: 260px;
    // }
}

.document-info a {
    // text-decoration: underline;
    display: block;
    // background-color: white;
    margin: 10px 0;
}

.document-info-text {
    font-weight: normal;
    color: #000;
    margin-right: 20px;
    white-space: nowrap;
    display: block;
    // width: 50%;
    width: auto;
}

.document-info-text + * {
    // width: 50%;
    //display: block !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

button#upload_document_submit {
    width: auto;
    padding: 10px 50px;
    margin-left: auto;
}

.page-document .document-empty {
    width: 60%;
    margin: 0 auto;
}

@keyframes uptomid {
    0% {top: 90%;}
    100% {top: 50%;}
}

.doc-pdf {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 }


.download a {
    display: block;
    width: 250px;
    height: 42px;
    text-align: center;
    background: $cprimary-12;
    border-radius: 4px;
    margin: 0;
    font-weight: bold;
    line-height: 42px;
    color: #fff;
}

.document-bottom {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #d4d4d6;
    padding-top: 20px;
    margin-top: 20px;
    .document-help {
        width: 50%;
        margin-right: 50px;
        .document-text {
            text-align: justify;
        }
    }

    .document-ticket {
        width: 50%;
    }
}

// .document-info {
//     width: 300px;
// }

.iframe-sign {
    display: none;
    &.show {
        display: block;
    }
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        border: 0;
    }
}

iframe.iframe-signature {
    visibility: hidden;
    // display: none;
    opacity:0;
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99;
}

iframe.iframe-signature.act {
   visibility: visible;
//    display: block;
opacity: 1;
}

.c-header-bar {
    background-color: $cprimary-7;
}

.c-sign__actions__banner {
    background-color: rgba(139,221,205,0.9);
}

.c-tab__label--current {
    background-color: $cprimary-7;
    color: #fff !important;
}

.c-tab__label {
    color: $cprimary-7;
}

.c-procedure-sign__title {
    color: $cprimary-7;
}

.c-progressbar {
    border-color: $cprimary-7;
}

.box-none {
    box-shadow: none !important;
}

//document container bottom line
.container-bottom-line {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #f3f3f4;
    margin: 10px 0;
    align-items: center;
    padding-bottom: 30px;
    flex-wrap: wrap;
}

.container-bottom-line:last-child {
    border: 0;
}

.btn-create-doc {
    display: flex;
    align-items: center;
    justify-content: center;
}

.document-actions {
    display : flex;
    justify-content: space-between;
    align-items: center;
}

.document-need {
    border-radius: 8px;
    background-color: #f8f8f8;
    padding: 20px;
    width: auto;
}

.document-need-title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-right: 45px;
    margin-bottom: 20px;
}

.document-validators {
    display: flex;
    align-items: center;
    color: #000;
}

.document-signators {
    display: flex;
    align-items: center;
    color: #000;
}

.second-popin {
    display: none;
}

.document-info button.disable {
    border: solid 1px $cprimary-5;
    background-color: $cprimary-5 !important;
}

.document-info {
    width: 100%;
    overflow: hidden;
}

.document-info .amount-bills {
    font-size: 24px;
    font-weight: bold;
}

.doc-content.doc-facture {
    height: 180px !important;
}

.doc .doc-content.doc-facture .doc-text .doc-text-title {
    border-bottom: 0;
}

.popin-sharedwith {
    .popin-title {
        margin-top: 20px;
    }
    .popin-actions {
        margin-top: 40px;
    }
    .blocAddExternalMember {
        display: none;
    }
    
    .js-addExternalMember {
        color: $cprimary-7;
        font-weight: bold;
    }
    
    .externalMemberEmail {
        margin-top: 25px;
    }
    
    .externalMemberEmail:after {
        content: "*";
        color: #E1280B;
        margin-left: 4px;
    }
    
    .js-allMembers {
        font-weight: bold;
        margin-left: 6px;
        margin-bottom: 20px;
    }

    .js-allMembersContent label {
        margin-left: 10px;
        cursor: pointer;
    }
    
    .externalMemberEmailInput {
        border: 1px solid #D7DCDB;
        width: 208px;
        margin-bottom: 10px;
        height: 30px;
        padding: 0 6px !important;
        &::placeholder {
            font-size: 13px;
        }
    }
    
    .js-cancelSharing {
        color: $cprimary-7;
        border: 0;
        background-color: transparent;
        font-weight: bold;
        width: 82px !important;
    }
    
    .js-submit-sharedwith {
        width: 82px !important;
        height: 36px;
        margin-left: 10px;
        font-weight: normal;
    }
    
    .js-addMemberButton {
        width: auto;
        height: 30px;
        font-weight: normal;
        line-height: 13px;
        margin-top: auto;
        margin-bottom: 10px;
        margin-left: 20px;
    }
    
    .js-addMemberMessage {
        width: 392px;
        margin-right: 44px;
        cursor: initial;
    }

    #js-allMembersInput, .js-allMembersContent input {
        height: auto;
        cursor: pointer;
    }

    .js-allMembersContent {
        display: flex;
        flex-wrap: wrap;
        margin-left: 48px;
        cursor: initial;
        & div {
            width: 50%;
            margin-bottom: 20px;
        }
        & label, input {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .js-blocAllMembers {
        cursor: initial;
        & p {
            display: none;
            margin-bottom: 20px;
        }
    }
    .errorMsgShareEmail {
        display: none;
        color: $cred;
    }
}

.st-spaceDocument {
    margin-right: 5px;
}

.st-thead_versionning, th {
    color: red!important;
}

//.custom-file {
//    display: flex;
//    flex-direction: row;
//    width: 100%;
//}