header {
    background: #fff;
    height: 60px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    h1 {
        text-align: center;
        color: $cprimary-7;
    }
    .right.login {
        position: absolute;
        right: 0;top: 5px;
        width: 20%;
        ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            a.nav-link {
                color: #fff;
            }
        }
    }
}
