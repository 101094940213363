.popin-flash {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    width: 550px;
    max-height: 85vh;
    color: $cprimary-12;
    overflow: auto;
    .popin-flash-wrap {
        position: relative;
        height: 100%;
        padding: 20px; 
        .popin-flash-content {
            position: relative;
            height: 100%;
            padding: 20px;
        }
    }
}

.popin {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    min-width: 550px;
    max-width: 100%;
    //min-height: 250px;
    min-height: 200px;
    max-height: 85vh;
    color: $cprimary-12;
    overflow: auto;
}

.popin .infos-user-content{
    min-width: 1500px !important;
}


.popin .custom-file-input + label {
    border: 1px solid #f3f3f4 !important;
}

.popin-wrap {
    display: none;
    height: 100%;
    padding: 50px;
    position: relative;
    max-width: 555px;
    .popin-close {
        right: 35px;
        top: 35px;
    }
    .popin-title {
        font-size: 32px;
        font-weight: 600;
    }
    label {
        margin-right: auto;
    }
    input, select {
        border: 1px solid #D2D4D4;
        border-radius: 3px;
        font-size: 14px;
        height: 38px !important;
        text-indent: 0;
        &::placeholder {
            font-size: 12px;
            font-style: italic;
        }
    }
    textarea {
        border: 1px solid #D2D4D4;
        border-radius: 3px;
        font-size: 14px;
        &::placeholder {
            font-size: 12px;
            font-style: italic;
        }
    }
    button {
        background: transparent;
        border: 0;
    }
}

.popin-double-size {
    .double-input {
        display: flex;
        align-items: start;
    }
    .popin-step .first-step {
        width: 960px;
    }
    
    .popin-step .company-step {
        .company-step2, .company-step3 {
            width: 960px;
        }
    }
}

.popin-close, .popin-flash-close:not(.btn) {
    position: absolute;
    right: 15px;
    // border-radius: 50%;
    top: 15px;
    width: 24px;
    // background: #ebebeb;
    text-align: center;
    line-height: 25px;
    height: 24px;
    font-weight: bold;
    font-size: 1.3rem;
    background: url('../../images/popin-close-black.svg') no-repeat;
    cursor: pointer;
}

.popin-title {
    font-weight: bold;
    color: $cprimary-12;
    font-size: 18px;
}

.popin-content {
    margin: 20px 0;
    color: $cprimary-12;
}

.popin-actions {
    text-align: right;
    text-align: -moz-right;
    color: $cprimary-12;
}

.backdrop {
    background: rgba($cprimary-12, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
}

.backdrop-fix {
    background: rgba($cprimary-12, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
}

.backdrop-fixed {
    background: rgba($cprimary-12, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
}

.backdrop-calendar {
    background: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.backdropstepstart {
    background: rgba(45, 47, 57, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.backdroptour {
    background: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
}

.backdrop-flash {
    background: rgba($cprimary-12, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.bottom-sep {
    border-bottom: 2px solid #f3f3f4;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.bottom-sep .form-group {
    margin-bottom: 0;
}

.popin > *:not(.popin-payment-bystep):not(.popin-create-efacture):not(.popin-card-form):not(.popin-bankDebit):not(.popin-bankwire-infos):not(.popin-paying-3xFreeOfCharge):not(.popin-payingWithMandate) input {
    //border: 0;
    padding: 0 20px;
    //background: #f3f3f4 !important;
    color: $cprimary-12 !important;
    &:focus {
        color: $cprimary-12 !important;
    }
}

.popin input.green-content {
    border: 1px solid $cprimary-7;
    text-align: center;
    color: $cprimary-7;
}

.popin textarea {
    padding: 20px;
    border: 1px solid $cprimary-7;
    border-color: #d4d4d6;
    border-radius: 4px;
    color: $cprimary-12;
}

/*Popin spec*/
.popin-waiting .popin-content {
    margin: 20px 0;
    min-height: 100px;
    color: $cprimary-12;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*popin design title*/
.popin-content h5 {
    font-weight: bold;
}

// .popin-content {
//     margin-top: 0;
// }

.docname {
    margin-bottom: 20px;
    margin-right: 30px;
    word-break: break-all;
}

.popin-step {
    .zero-step {
        .big-button {
            background: $cwhite;
            width: 100%;
            color: $cprimary-7 !important;
            &:hover {
                background: $cprimary-7;
                color: $cwhite !important;
            }
        }
        &.hide {
            display: none;
        }
    }
    .first-step {
        display: none;
        &.hide {
            display: none;
        }
        &.show {
            display: block;
        }
    }
    .company-step {
        display: none;
        &.show {
            display: block;
        }
    }
    .second-step {
        display: none;
        &.show {
            display: block;
        }
    }
    .third-step {
        display: none;
        &.show {
            display: block;
        }
    }
    .step-number {
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.bank.bankselect {
    cursor: pointer;
}

.st-bankCard {
    align-items: center;
    background: #F8F8F8;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
    line-height: 44px;
    margin: 10px 0 5px 0;
    padding: 0 20px;
}
.check-card-selected {
    display: none;
}
.card-selected .check-card-selected {
    display: block;
}
.select-card-logo {
    margin-right: 10px;
}

.st-backgroundBank {
    background-color: $csecondary-1;
}

.js-wayselected.selected, .js-wayselected.multi-payment-selected, .st-bankCard.card-selected {
    border: 1px solid $cprimary-7;
    background-color: $cprimary-1;
}

.js-checkMarkWaySelected {
    cursor: initial !important;
}

.st-errorMsgWarning {
    color: #FEA50D;
}

.st-errorMsgWarningVirement {
    display: none;
    color: #FEA50D;
}

.newproject {
    .popin-actions {
        .form-group {
            width: auto;
            display: inline-block;
            margin-bottom: 0;
        }
    }
}

.bg-black .popin select {
    color: $csecondary-3;
    height: 45px;
    font-weight: normal;
}

.bg-black .popin .form-control:focus {
    color: $csecondary-3 !important;
}

.popin-wrap:not(.popin-double-size) {
    max-width: 555px;
}
.popin-wrap.popin-tuto {
    width: 960px !important;
}
.st-popin-paymentOffline {
    margin-top: 40px;
}

.st-popin-paymentOffline .d-flex{
    flex-direction: column;
}

.st-btn-popinOffline {
    width: 114px !important;
    height: 40px;
    background-color: $cprimary-7 !important;
    font-weight: normal;
    border-radius: 6px;
    border: 0;
    color: white;
    font-weight: bold;
}

.select-bank, .select-bank-later {
    border: 1px solid #aaabaf !important;
    width: 225px;
    margin-bottom: 8px !important;
    margin-top: 4px !important;
}

.st-div-bankInfos-popinOffline {
    font-style: italic;
    font-size: 14px;
}

.st-bankInfos-popinOffline {
    color: $csecondary-4;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 13px;
    font-style: italic;
    text-decoration: underline;
}

.bank-owner {
    margin-bottom: 24px;
    border-radius: 3px;
    border: 1px $csecondary-2 solid !important;
    height: 38px;
}

.bank-owner::placeholder {
    font-style: italic;
    border-radius: 4px;
    color: #AAABAF !important;
}

.popin-bankwire-infos .popin-title {
    font-size: 24px;
}

.js-payment-data div:nth-child(2), .js-payment-data div:nth-child(3) {
    cursor: pointer;
}

.st-popin-paymentOffline input {
    display: block;
    width: 100%;
}

.st-popin-paymentOffline select {
    display: block;
    width: 50%;
}

.popin-buy-offer {
    padding: 40px;
    & .popin-close {
        top: 40px;
        right: 40px;
    }
    & .bank {
        padding: 20px;
    }
    & .bank.bankselect.selected {
        border: 1px solid $cprimary-7;
        font-size: 13px;
    }
    & .add-account-bank .btn-unfilled-primary {
        margin-top: 10px;
        height: 64px;
    }
    & .js-popinbuyoffer-submit {
        width: auto;
        height: 38px;
    }
    & .js-popin-close {
        background: transparent;
        border: 0;
        height: 38px;
        margin-right: 20px;
        width: auto;
    }
}

.step-header-1-edit, .step-header-2-edit, .step-header-4-edit {
    color: #aaabaf;
    font-size: 18px;
    font-weight: bold;
}

.js-infos-user-content {
    & h3 {
        font-size: 1.5rem;
        font-style: italic;
        text-align: center;
        color: $cprimary-7;
        margin-bottom: 30px;
        margin-top: 30px;
        border-bottom: 2px $cprimary-7 solid;
        padding-bottom: 30px;
    }
    & p {
        font-style: italic;
        & b {
            font-style: normal;
        }
    }
    & .st-libertyOfferDashboard {
        border: #00E9C3 thick double;
        padding: 5px 10px;
        border-radius: 10px;
        margin-left: 10px;
    }
    & .st-businessOfferDashboard {
        border: $cprimary-7 thick double;
        padding: 5px 10px;
        border-radius: 10px;
        margin-left: 10px;
    }
    & .st-premiumOfferDashboard {
        border: #FFCE33 thick double;
        padding: 5px 10px;
        border-radius: 10px;
        margin-left: 10px;
    }
    & .st-blocTable {
        max-width: 1500px;
        height: 250px;
        white-space: nowrap;
        overflow: auto;
        & .st-activityColor {
            color: $cprimary-7;
            font-weight: bold;
        }
        & .st-firstTable {
            width: 150px;
            font-weight: bold;
            font-style: normal;
            color: $cprimary-7;
        }
        & tr {
            border: 2px solid $cprimary-12;
            cursor: initial;
            & td {
                border: 2px solid $cprimary-12;
                padding: 10px;
                width: 100px;
                text-align: center;
                font-style: italic;
            }
        }
    }
}

.errorMsgOffline {
    color: $cprimary-12;
    padding: 12px 20px;

}

.one-clic-payment, .multipayment {
    height: 54px;
    background-color: $csecondary-1;
    border-radius: 8px;
    font-weight: bold;
    & .js-amountFinalPayment {
        display: none;
    }
}

.js-amountFinalPayment {
    font-weight: bold;
}

.js-open-addbank {
    font-weight: normal;
}

.st-popin-paymentOffline {
    .new-design {
        font-size: 12px;
        font-weight: normal;
        border-radius: 3px;
        width: 100%;
        border: 1px $csecondary-2 solid !important;
        height: 38px;
        text-indent: 5px;
    }
}

.st-iconCopy {
    transform: rotate(180deg) scaleX(-1);
}

.js-div-bankInfos-iban {
    background-color: #F7F7F7;
    border-radius: 3px;
    display: none;
    justify-content: space-between;
    padding: 10px 15px;
    margin-bottom: 30px;
    & p:nth-child(1), img {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.st-copiedIban {
    position: absolute;
    background-color: $cprimary-7;
    color: white;
    top: 50px;
    padding: 8px;
    border-radius: 10px;
    display: none;
    right: 16px;
}

.popin-payment-bank .st-payment-bank {
    width: 120px !important;
    height: 44px;
    border-radius: 25px;
    border: 0;
    color: white;
    font-weight: bold;
    background-color: $cprimary-7;
}

.st-contentOneClicPayment, .st-loaderGif, .st-errorMsgBanking {
    display: none;
}

.st-errorMsgBanking {
    color: $cprimary-7;
    & a {
        color: $cprimary-7;
        text-decoration: underline;
        &:hover {
            text-decoration: underline !important;
        }
    }
}

.js-checkMarkWaySelected {
    vertical-align: sub;
}

.popin-payment-bystep {
    .billselm-ttc {
        margin-right: 5px;
    }
    .js-duplicate-billselm {
        margin-left: 5px;
    }
}

.popin-payment-bystep .popin-actions .js-stepActionPrev {
    width: auto;
    background: none;
    border: 0;
}

.js-blocPopinStep {
    display: none;
    & .js-moreInfoEscompte, .js-lessInfoEscompte, .js-lessInfo3x, .js-moreInfo3x {
        color: $cprimary-7;
        text-decoration: underline;
    }
    & .js-contentMoreInfoEscompte, .js-contentMoreInfo3x {
        display: none;
        width: 600px;
    }

    .form-group, .autocomplete-container-payment, .js-offerEscompte, .js-blocMoreInfoEscompte, 
    .js-contentMoreInfoEscompte, .js-offer3x, .js-blocMoreInfo3x, .js-contentMoreInfo3x {
        cursor: initial;
    }
    
    .js-offerEscompte {
        margin-top: 30px;
    }

    #escompteSelect, #escompteSelectDate {
        cursor: pointer;
    }

    .st-selectEscompteNumber {
        margin-right: 30px;
    }

    & .js-reducePriceEscompte {
        color: #7F7F7F;
    }

    .js-escompteSelectChoice, .js-escompteSelectDateChoice {
        display: none;
        box-shadow: 0px 6px 12px #00000029;
        border-radius: 6px;
        width: 222px;
        background-color: white;
        & div {
            margin-top: 16px;
            padding-left: 16px;
            &:hover {
               background-color: #10BC911A;
               border-radius: 4px;
            }
        }
        .js-chooseMyDiscount, .js-chooseMyDiscountDate {
            margin-top: 16px;
            padding-left: 16px;
        }
    }
    .st-selectEmoticone, .st-selectDateIcon {
        width: 222px;
        height: 40px;
        background-color: #10BC911A;
        border-radius: 4px;
        padding: 10px;
    }

    .switch + label {
        width: 40px;
        height: 20px;
        background-color: #CCCCCF;
        margin-top: -20px;
    }

    .switch + label::after {
        left: 0;
        top: 0;
    }

    input.switch:checked + label::after {
        left: auto;
        right: 0;
        transform: initial;
    }

    .st-blocSelectEscompte {
        margin-top: 20px;
        display: flex;
    }

    .st-blocMonthlyPayment {
        display: none;
        & .st-titleMonthly {
            margin-top: 20px;
        }
        .js-monthyPaymentPrice2, .js-monthyPaymentPrice3, .js-monthyPaymentPrice1Escompte {
            color: $cprimary-7;
        }
        .js-monthyPaymentPrice1, .js-monthyPaymentWithEscompte {
            display: none;
        }
        & img {
            margin-right: 15px;
        }
        .js-monthyPaymentPrice1, .js-monthyPaymentPrice2, .js-monthyPaymentPrice3, .js-monthyPaymentPrice1Escompte {
            margin-right: 15px;
        }
        .st-blocMonthlyPrice {
            padding-top: 4px;
            & .st-withEscompte {
                margin-bottom: 0;
            }
        }
    }
    .blocBillWithEscompte, .blocBillWithEscompteDate {
        display: none;
        color: #7F7F7F;
        text-align: right;
        font-style: italic;
        margin-top: 5px;
        font-size: 13px;
    }

    .js-offer3x {
        margin-top: 60px;
    }

    & h2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .js-containerBillPrevisu {
        text-align: center;
    }

    .st-blocEscompteInputDate {
        width: 222px;
        border: 1px solid $cprimary-7;
        border-radius: 4px;
        height: 40px;
        display: none;
        & p {
            width: 70px;
            border-left: 1px solid $cprimary-7;
            height: 39px;
            text-align: center;
            line-height: 38px;
            font-weight: bold;
        }
        & .js-getDateEscompteInput {
            width: 150px;
            border: 0;
            height: 38px;
            padding-top: 2px;
            padding-left: 15px;
        }
    }

    .st-blocEscompteInput {
        width: 222px;
        border: 1px solid $cprimary-7;
        border-radius: 4px;
        height: 40px;
        display: none;
        cursor: text;
        & .js-getNumberEscompteInput {
            width: 25px;
        }
        & p:nth-child(2) {
            width: 54px;
            text-align: center;
            font-weight: bold;
            border-left: 1px solid $cprimary-7;
            height: 39px;
            line-height: 38px;
            cursor: default;
        }
        & .st-getPriceEscompteInput {
            color: #7F7F7F;
            margin-top: auto;
            margin-bottom: auto;
        }
        & .js-getPriceEscompteInput {
            cursor: text;
        }
        .st-negatif {
            margin-left: 15px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 5px;
            font-weight: bold;
        }
        .js-getNumberEscompteInput {
            height: 38px;
            border: 0;
        }
    }
    .js-chooseMyDiscount, .js-chooseMyDiscountDate {
        color: $cprimary-7;
        font-weight: bold;
    }
}

.popin-payment-bystep .popin-close {
    background: url('../../images/popin-close.svg') no-repeat;
    right: 25px;
}

.st-multipayment-tab {
    width: 48%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    background-color: $csecondary-1;
    border-radius: 10px;
}

.st-multipayment-tab-selected {
    background-color: $cprimary-1;
    border: solid 1px $cprimary-7;
}

.trumbowyg-fullscreen-button {
    display: none !important;
}

#paymentByStep-step5 {
    width: 700px;
    max-width: 700px !important;
    padding-left: 42px;
    padding-right: 20px;
    .js-bills-relaunch {
        width: 268px !important;
        height: 44px;
        margin-top: 20px;
    }
}

.st-open-addbank {
    border-radius: 6px;
    font-weight: normal;
    height: 40px;
    margin-top: 10px;
    text-align: left;
    width: 100% !important;
    &:hover {
        background-color: #ECFDF9;
    }
}

.st-error3x, .js-errorDelayedDate {
    color: #FD216B;
    display: none;
}

.payment-send-choice {
    margin-top: 30px;
}

.dl-new-bills {
    margin: auto;
}

.js-bloc3xMail {
    display: none;
}

.popin-wrap.popin-efacture {
    max-width: 600px;
}

.popin-bankwire-infos, .popin-card-form, .popin-bankDebit, .popin-paying-3xFreeOfCharge, .popin-payingWithMandate, .popin-authenticate {
    padding: 50px 50px 30px 50px;
    & .popin-title {
        font-size: 32px;
    }
    & .popin-close {
        top: 35px;
        right: 35px;
    }
    & .popin-content {
        margin-bottom: 0;
    }
    & label {
        margin-right: auto;
    }
    & input {
        height: 38px;
        border: 1px solid $csecondary-2;
        text-indent: 10px;
    }
    & .popin-actions {
        margin-top: 50px;
    }
    & select {
        width: 100%;
        border-radius: 6px;
        border: 1px solid $csecondary-2;
        height: 38px;
        padding: 0;
        font-weight: normal;
        background-image: url(/build/images/arrow_drop_down_black.svg);
    }
    & a {
        color: #0076FF;
        text-decoration: underline;
    }
}

.st-cancelBtn {
    background: none;
    border: 0;
    border-radius: 6px;
    color: $cprimary-12;
    font-weight: bold;
    height: 38px;
    margin-right: 20px;
    padding: 0 26px;
    width: auto !important;
    &:hover {
        background-color: $csecondary-1;
    }
}

.st-firstAlignInput {
    margin-right: 20px;
}

.st-secondAlignInput {
    width: 100%;
    text-indent: 10px;
}

select.st-secondAlignInput {
    text-indent: 5px;
}

.popin-card-form .st-submitCard {
    color: white;
    border-radius: 6px;
    width: 114px;
    height: 40px !important;
    font-weight: bold;
    font-size: 14px;
    border: 0 !important;
    text-indent: 0;
}

.mb-15 {
    margin-bottom: 15px;
}

.st-legalOffline {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
}

.st-SEPA {
    margin-top: 40px;
    margin-bottom: 50px;
    background-color: $csecondary-1;
    text-align: center;
    height: 34px;
    line-height: 34px;
}

.st-mensuality {
    font-size: 20px;
    & img {
        margin-left: 10px;
        margin-right: 5px;
        margin-top: 0;
    }
}

.st-BlocMensuality {
    margin-bottom: 50px;
    & img {
        margin-top: 3px;
    }
}

.st-redirectRegistration {
    width: 440px;
}

.st-ibanMandate {
    border: 1px solid $csecondary-3;
    padding: 10px;
    height: 38px;
    border-radius: 3px;
    color: $csecondary-3;
    margin-bottom: 0;
    font-style: italic;
}

.st-blocPopinError {
    background-color: #FCEAE7;
    border-radius: 6px;
    flex-direction: row;
    height: 38px;
    justify-content: space-between;
    margin-bottom: 50px;
    & p {
        margin-left: 20px;
        margin-top: auto;
        margin-bottom: auto;
    }
}
.st-blockPopinSuccess {
    background-color: $cprimary-1;
    border-radius: 6px;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 50px;
    & p {
        margin-bottom: auto;
        margin-top: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    & p img {
        height: 15px;
        width: 15px;
    }
}

.st-infoPopin {
    background-color: #ECFDF9;
    border-radius: 6px;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 20px;
    & p {
        margin-bottom: auto;
        margin-top: auto;
    }
}

.st-helpIcon {
    margin-right: 63px;
    margin-top: 2px;
}

.st-cryptoMsg {
    background-color: $csecondary-1;
    width: 236px;
    padding: 14px 30px;
    display: none;
    text-align: center;
    border-radius: 3px;
    position: absolute;
    right: -34px;
    top: -130px;
    box-shadow: 0px 3px 6px #00000029;
}

.popin-addSupplier, .popin-replaceInvoice, .popin-replaceInvoiceAppro, .popin-payWaitingApprobation,
.popin-payRefusApprobation, .popin-confirmLeaveSupplierForm, .popin-confirmModifyInfos, .popin-accountingPlan {
    padding: 50px;
    .popin-close {
        top: 30px;
        right: 30px;
    }
    .popin-title {
        font-size: 32px;
        font-weight: 600;
    }
    .content {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    input {
        &::placeholder {
            font-size: 12px;
            font-style: italic;
        }
    }
    .double {
        width: 208px;
    }
    .js-addSupplierRequest, .js-replaceInvoice, .js-replaceInvoiceAppro, .js-payWaitingApprobation,
    .js-payRefusApprobation, .js-confirmLeaveSupplierForm, .js-cancelModifyInfos {
        background-color: $cprimary-12;
        border: 0;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        width: auto;
    }
    .st-cancelBtn {
        margin-right: 40px;
    }
    .js-deleteInvoice, .js-confirmModifyInfos {
        color: $cprimary-12;
        font-weight: bold;
        background: none;
        border: 0;
        width: auto !important;
        margin-right: 40px;
    }
}

.st-infoLink, .st-infoSendLater {
    background-color: $cprimary-1;
    border-radius: 6px;
    flex-direction: row;
    height: 38px;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
    & p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;
    }
}

.js-blocCreatedLater {
    display: none;
}

.st-hoverInfoDateLink {
    background-color: #F8F8F8;
    border-radius: 6px;
    height: 42px;
    line-height: 42px;
    position: absolute;
    text-align: center;
    top: -10px;
    width: 100%;
    display: none;
}

.popin-define-goal {
    input::placeholder {
        font-size: 12px;
        font-style: italic;
    }
}

.popin-payment-bank {
    padding: 50px;
    max-width: 555px;
    .popin-close {
        right: 35px;
        top: 35px;
    }
    .title-lock {
        font-size: 32px;
    }
    .st-bankingCard-title, .st-debiting-title {
        display: none;
    }
    .st-paymentMenu {
        background-color: $csecondary-1;
        border-radius: 6px;
        border: 1px $csecondary-1 solid;
        font-weight: 600;
        height: 40px;
        padding: 0 23px;
        &.active {
            background-color: $cprimary-1;
            border: 1px $cprimary-7 solid;
        }
        & img {
            display: none;
        }
    }
    .st-blocDebitingPayment {
        display: none;
    }
    .st-type-bank {
        border: 1px $csecondary-2 solid;
        height: 38px;
        width: 100%;
    }
    .st-type-bank::placeholder {
        font-style: italic;
    }
    .st-payment-bank {
        background: $cprimary-12 !important;
        border-radius: 6px;
        border: 0;
        color: white;
        font-weight: normal;
        height: 38px;
        padding: 0 26px;
        width: auto !important;
        & img {
            margin-right: 5px;
        }
    }
    .st-hideBloc3x, .st-showBloc3x {
        height: 20px;
        margin-top: 6px;
        p {
            background-color: $cprimary-12;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            color: $cwhite;
            display: none;
            margin-right: -10px;
            margin-bottom: 0;
            padding-left: 10px;
            width: 0;
        }
        img {
            max-width: initial;
        }
    }
    // .st-hideBloc3x:hover p {
    //     visibility: visible;
    //     width: 106px;
    //     transition-duration: 300ms;
    //     transition-property: visibility;
    //     transition-property: width;
    // }
    // .st-showBloc3x:hover p {
    //     visibility: visible;
    //     width: 86px;
    //     transition-duration: 300ms;
    //     transition-property: visibility;
    //     transition-property: width;
    // }
    .st-blocAtout3x {
        background-color: $csecondary-1;
        border-radius: 6px;
        padding: 10px 0;
        text-align: center;
        img {
            margin-left: 5px;
        }
    }
    .tooltip-mandate {
        background: white;
        border-radius: 6px;
        box-shadow: 0px 3px 6px #00000029;
        display: none;
        left: 0;
        margin: 0 auto;
        padding: 10px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 320px;
        z-index: 99999;
    }
    .st-infoPayLayer {
        display: none;
        margin-top: -20px;
    }
    .delayed-date {
        border: 1px solid #D2D4D4;
        border-radius: 3px;
        height: 38px;
        text-indent: 0;
    }
    .switch + label {
        width: 40px;
        height: 20px;
        background-color: #CCCCCF;
        margin-top: -20px;
    }
    .switch + label::after {
        left: 0;
        top: 0;
    }
    input.switch:checked + label::after {
        left: auto;
        right: 0;
        transform: initial;
    }
    .st-input-calendar-logo {
        top: 4px;
    }
    .st-blocPaymentDelayed {
        border-top: 1px #E8E9EA solid;
        margin-bottom: 10px;
        span, div {
            margin-top: 20px;
        }
    }
    .st-errorBankingCard {
        background-color: $csecondary-1;
        border-radius: 6px;
        box-shadow: 0px 3px 6px #00000029;
        color: black;
        display: none;
        left: 120px;
        padding: 14px 0;
        position: absolute;
        text-align: center;
        top: -70px;
        width: 198px;
    }
    .st-errorDebiting {
        background-color: $csecondary-1;
        border-radius: 6px;
        box-shadow: 0px 3px 6px #00000029;
        color: black;
        display: none;
        left: 95px;
        padding: 14px 0;
        position: absolute;
        text-align: center;
        top: -70px;
        width: 250px;
    }
    .js-autocompleteBank {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0px 3px 15px #02130E1A;
        display: none;
        position: absolute;
        top: 50%;
        width: 450px;
        z-index: 10;
        .js-bankAuto {
            color: $cprimary-12;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            min-height: 40px;
            margin-bottom: 0;
            padding: 10px 15px;
            word-break: break-all;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
}

.popin-add-bank, .popin-addBankingCard {
    padding: 50px;
    max-width: 555px;
    .popin-close {
        right: 35px;
        top: 35px;
    }
    .popin-title {
        font-size: 32px;
    }
    label {
        margin-right: auto;
    }
    input, select {
        border: 1px solid #D2D4D4;
        border-radius: 3px;
        height: 38px !important;
        text-indent: 0;
    }
    select {
        background-image: url(/build/images/arrow_drop_down_black.svg);
        font-weight: normal;
        padding: 0 6px;
        text-indent: 8px;
        color: $cprimary-12;
    }
    .js-submit-newbank, .js-addingBankingCard {
        background-color: $cprimary-12;
        border: 0;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        width: 98px;
    }
    .st-helpIcon {
        margin-right: 53px;
    }
}

.popin-wrap.popin-buy-offer-finish {
    padding: 30px;
    .popin-close {
        right: 30px;
        top: 30px;
    }
    .js-popin-offer-redirect {
        font-weight: normal;
    }
}

.popin-additionnalPayment {
    padding: 50px;
    max-width: 555px;
    .popin-close {
        right: 35px;
        top: 35px;
    }
    .st-needChange {
        color: $csecondary-4;
        font-style: italic;
    }
    .st-callBackUser {
        background: transparent;
        border: 0;
    }
    .st-seeBusiness {
        background-color: $cprimary-12;
        border: 0;
        border-radius: 10px;
        color: $cwhite;
        padding: 11px 26px;
    }
}

.st-validationLogoCompany {
    display: none;
}

.st-popinEmail {
    .st-personalization-load-zone {
        height: 38px;
    }
}

.st-legalNotices {
    color: $cprimary-7;
    &:hover {
        color: $cprimary-7;
    }
}

.popin-updateSupplier {
    .js-showCompta {
        color: $csecondary-3;
    }
    .st-blocContact, .st-blocCompta {
        display: none;
    }
    .st-blocFiscalId {
        input:not(.siret) {
            display: none;
        }
        input {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .js-show-selectStatus, .js-show-selectFiscalId {
        border: 1px solid $csecondary-2;
        border-radius: 3px;
        height: 38px;
        padding: 0 20px;
        width: 206px;
        img {
            width: 24px;
        }
    }
    .js-show-selectFiscalId {
        background-color: $csecondary-1;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 130px;
    }
    .js-selectStatus, .js-selectFiscalId {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 0 10px $csecondary-2;
        font-weight: 600;
        display: none;
        position: absolute;
        top: 80px;
        z-index: 10;
        & p:nth-child(1) {
            color: $csecondary-3;
        }
        & p {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
    .js-selectStatus {
        width: 206px;
    }
    .js-selectFiscalId {
        right: 0;
        width: 130px;
    }
    .js-show-blocContact {
        border-radius: 6px;
        height: 40px;
        padding: 0 10px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .js-hideBlocContact {
        background: url("/build/images/close_btn_grey.svg") no-repeat;
        height: 20px;
        width: 20px;
        &:hover {
            background: url("/build/images/close_btn_black.svg") no-repeat;
        }
    }
    .js-request-updateSupplier {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        width: auto;
        &:hover {
            background-color: $cprimary-11;
        }
    }
    .js-show-blocCompta {
        border-radius: 6px;
        color: $csecondary-3;
        margin-left: 30px;
        padding: 3px 10px;
        &:not(.active):hover {
            background-color: $csecondary-1;
        }
    }
    .js-show-blocContactCompany {
        cursor: initial;
        border-radius: 6px;
        padding: 3px 10px;
        &:not(.active):hover {
            background-color: $csecondary-1;
        }
    }

    .st-selectedTag {
        align-items: center;
        background: #02130E;
        border-radius: 4px;
        color: white;
        display: flex;
        font-size: 14px;
        font-weight: bold;
        height: 24px;
        justify-content: center;
        left: 15px;
        line-height: 14px;
        margin: 0 !important;
        padding: 5px 10px;
        position: absolute;
        top: 38px;
    }
    .js-autocompleteTag {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 3px 15px #02130E1A;
        display: none;
        left: 0;
        position: absolute;
        top: unset;
        width: 270px;
        z-index: 10;
        .js-tagAuto {
            color: $cprimary-12;
            cursor: pointer;
            font-size: 12px;;
            margin-bottom: 0;
            width: 85%;
            word-break: break-all;
        }
        .tagP {
            align-items: center;
            height: 40px;
            justify-content: space-between;
            line-height: 40px;
            margin-bottom: 0;
            min-height: 40px;
            padding: 0 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
        .js-addTag {
            display: block;
            line-height: 40px;
            padding: 0 15px;
            width: 100%;
        }
    }
    .st-deleteTagBlock {
        height: 20px;
        text-align: right;
        width: 20px;
    }
    .st-deleteTagBlock {
        background: url("../../images/close_lightgrey.svg") no-repeat center;
        background-size: 8px;
        border-radius: 100%;
        cursor: pointer;
        z-index: 15;
    }
    .st-deleteTagBlock:hover {
        background: url("../../images/close_dark_grey.svg") no-repeat center #D2D4D4;
        background-size: 8px;
    }
    .st-removeFromInput {
        background: url("../../images/close_white.svg") no-repeat center;
        background-size: 7px;
        cursor: pointer;
        height: 24px;
        margin-left: 10px;
        width: 8px;
    }
}

.popin-addFolderSupplier {
    .js-request-addFolderSupplier {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
}

.popin-downloadAllBills {
    .js-show-selectBills {
        border: 1px solid $csecondary-2;
        border-radius: 3px;
        height: 38px;
        padding: 0 20px;
        img {
            width: 24px;
        }
    }
    .js-selectBills {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 0 10px $csecondary-2;
        font-weight: 600;
        display: none;
        position: absolute;
        top: 80px;
        width: 450px;
        z-index: 10;
        & p:nth-child(1) {
            color: $csecondary-3;
        }
        & p {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
    .js-request-downloadBills {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
    .st-input-calendar-logo {
        right: 6px;
        top: 4px;
    }
}

.popin-downloadAllDocs {
    .js-show-selectDocs {
        border: 1px solid $csecondary-2;
        border-radius: 3px;
        height: 38px;
        padding: 0 20px;
        img {
            width: 24px;
        }
    }
    .js-selectDocs {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 0 10px $csecondary-2;
        font-weight: 600;
        display: none;
        position: absolute;
        top: 80px;
        width: 450px;
        z-index: 10;
        & p:nth-child(1) {
            color: $csecondary-3;
        }
        & p {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
    .js-request-downloadDocs {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
}

.popin-uploadFiles {
    .dropzone {
        border: 0;
        background-color: transparent;
    }
    .js-request-uploadFiles {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: white;
        height: 48px;
        width: 100%;
    }
}

.popin-confirmLeavePage {
    .js-redirect-previousPage {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
}

.copyMailContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.js-mailCopyInput {
    display: none;
}

#js-mailCopyInput{
    font-weight: 400;
}

#js-mailCopyInput + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 14px;
    line-height: 1.7;
    height: 28px;
    cursor: pointer;
    padding-top: 3%;
}

#js-mailCopyInput:checked + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 17px;
    height: 17px;
    border: 2px solid $cprimary-12;
    background-color: $cprimary-12;
    border-radius: 3px;
}

#js-mailCopyInput:not(:checked) + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 17px;
    height: 17px;
    border: 2px solid $cprimary-12;
    background-color: white;
    border-radius: 3px;
}

#js-mailCopyInput + label::after{
    content: attr(data-content);
    position: absolute;
    font-size: 13px;
    color: white;
    width: 12px;
    height: 12px;
    top: 6px;
    left: 4px;

}

#js-mailCopyInput:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
}

#js-mailCopyInput:checked + label::after {
    opacity: 1;
    transform: scale(1) rotate(0);
}

.st-blocActifMail {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
}

.switchMailContainer {
    margin-bottom: 1.5rem!important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.js-mailCustomLabel{
    margin-bottom: 1.5rem!important;
}

