section.page-newdocument {
    //margin-left: 180px;
    h1 {
        padding-right: 0 !important;
    }
    .form-group {
        > label {
            font-weight: bold;
        }
        legend {
            font-weight: bold;
        }
    }
    .form-date .form-inline {
        margin-top: 1rem;
    }
    .hide-step {
        display: none;
    }
    .create-document form {
        margin-top: 40px;
        width: 556px;
    }
    #new_document_submit {
        display: none;
    }
    .js-doc-signdate .is-invalid {
        height: 100%;
    }
}

.progressbar {
    margin-top: 1rem;
    height: auto !important;
    position: relative;
    padding: 10px 30px;
    text-transform: uppercase;
    font-size: 1rem;
    background-color: rgba($cprimary-7, 0.3);
}

.progressbar-text {
    position: absolute;
    z-index: 9999;
    text-align: center;
    width: 100%;
    left: 0;
    top: 0;
    color: $cwhite;
    padding: 10px 30px;
    text-transform: uppercase;
    font-size: 1rem;
}

.progressbar-inner {
    border-radius: 100px;
    background-color: $cprimary-7;
    position: absolute;
    height: 100%;
    top: 0;
    left:0;
    z-index: 99;
}