.folder-content {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.folder {
    height: 160px;
    width: 170px;
    position: relative;
    padding: 40px 10px 10px 10px;
    cursor: pointer;
}

.folder-block {
    width: 180px;
    margin-bottom: 40px;
    margin-right: 40px;
}

.folder-title {
    font-weight: bold;
    margin-right: 20px;
    overflow: hidden;
    word-break: break-word;
}

.folder-sstitle {
    margin-top: 10px;
    font-size: 90%;
}

.folder-notif {
    position: absolute;
    top: 5px;
    // .bullet {
    //     margin-left: 15px;
    // }
}

.folder-text {
    color: #808187;
    font-size: 14px;
    overflow: hidden;
    word-break: break-all;
}

.folder-img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
        cursor: pointer;
        width: 32%;
        border: 1px solid #f4f5f4;
        margin: 15px auto;
    }
}

.page-stepfolder {
    .folder-img {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        left: 0;
        position: absolute;
        bottom: 10px;
        img {
            cursor: pointer;
            width: 50%;
            margin: 15px auto;
            border: 0;
        }
    }
}

.bullet {
    height: 20px;
    width: 20px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    line-height: 20px;
}

.bg-green {
    background: $cprimary-7;
}

.bg-orange {
    background: $cprimary-10;
}

.bg-red {
    background: $cred;
}

.file-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.doc-short-link .folder-img img {
    border: 0;
}

.step-date.icon-pen {
    display: none;
}

.autocomplete-container {
    margin-top: 30px;
}

.autocomplete-title {
    font-weight: 600;
    margin-bottom: 10px;
}

.autocomplete-input {
    border-radius: 4px;
    border: solid 1px #d4d4d6 !important;
    width: 100%;
    background: #fff !important;
}

.autocomplete-input-info {
    border-radius: 4px;
    border: solid 1px #d4d4d6 !important;
    width: 100%;
    background: #fff !important;
}

.proposal {
    background-color: #ffffff;
    padding: 2px 7px;
}

.proposal-info {
    background-color: #ffffff;
    padding: 2px 7px;
}

.proposal-info-notselectable {
    background-color: #d4d4d6;
    padding: 2px 7px;
    position: relative;
    .notselectable {
        display: flex;
        align-items: center;
    }
}

.autocomplete-proposition {
    border: solid 1px #d4d4d6;
    border-radius: 0 0 4px 4px;
    display: none;
}

.autocomplete-proposition .proposal {
    &:hover {
        cursor: pointer;
    }
}

.autocomplete-proposition .proposal-info {
    &:hover {
        cursor: pointer;
    }
}

.autocomplete-proposition .proposal .proposal-delete {
    display: none;
}

.autocomplete-proposition .proposal-info .proposal-delete {
    display: none;
}

.autocomplete-proposition .proposal-info-notselectable .proposal-delete {
    display: none;
}

.autocomplete-result .proposal {
    cursor: pointer;
    border-radius: 16px;
    border: solid 1px $cprimary-5;
    background-color: #ffffff;
    padding: 2px 7px;
    margin: 5px 0;
    &:hover {
        .proposal-role {
            display: block;
            padding-left: 35px;
        }
    }
}


.autocomplete-result .proposal-info {
    cursor: pointer;
    border-radius: 16px;
    border: solid 1px $cprimary-5;
    background-color: #ffffff;
    padding: 2px 7px;
    margin: 5px 0;
    &:hover {
        .proposal-role {
            display: block;
            padding-left: 35px;
        }
    }
}

.already-proposal .proposal {
    cursor: pointer;
    border-radius: 16px;
    border: solid 1px $cprimary-5;
    background-color: #ffffff;
    padding: 2px 7px;
    margin: 5px 0;
    &:hover {
        .proposal-role {
            display: block;
            padding-left: 35px;
        }
    }
}


.already-proposal .proposal-info {
    cursor: pointer;
    border-radius: 16px;
    border: solid 1px $cprimary-5;
    background-color: #ffffff;
    padding: 2px 7px;
    margin: 5px 0;
    &:hover {
        .proposal-role {
            display: block;
            padding-left: 35px;
        }
    }
}

.already-proposal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .proposal-container-already {
        height: 55px;
        margin-right: 10px;
    }
    .proposal-name {
        white-space: nowrap;
        overflow: hidden;
        width: 130px;
        text-overflow: ellipsis;
    }
}

.proposal-delete {
    background: url("../images/crossred.svg") no-repeat;
    width: 12.5px;
    height: 12.5px;
    display: inline-block;
    background-size: contain;
    margin-left: 5px;
}

.autocomplete-content {
    &.open {
        .autocomplete-proposition {
            display: block;
            border-top: 0 !important;
        }
        .autocomplete-input {
            border-bottom: 0 !important;
            border-radius: 4px 4px 0 0;
        }
        .autocomplete-input-info {
            border-bottom: 0 !important;
            border-radius: 4px 4px 0 0;
        }
        .proposal {
            border-top: solid 1px #d4d4d6;
        }
        .proposal:last-child {
            border-radius: 0 0 4px 4px;
        }
        .proposal-info {
            border-top: solid 1px #d4d4d6;
            &:hover {
                color: $cprimary-7;
            }
        }
        .proposal-info:last-child {
            border-radius: 0 0 4px 4px;
        }
        .proposal-info-notselectable {
            border-top: solid 1px #d4d4d6;
        }
        .proposal-info-notselectable:last-child {
            border-radius: 0 0 4px 4px;
        }
    }
}
.noinfo {
    font-style: italic;
    font-size: 12px;
}

.autocomplete-result {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .proposal-container-info {
        height: 55px;
        margin-right: 10px;
    }
    .proposal-container {
        height: 55px;
        margin-right: 10px;
    }
    .proposal-name {
        white-space: nowrap;
        overflow: hidden;
        width: 130px;
        text-overflow: ellipsis;
    }
}

.proposal-role {
    display: none;
}

.proposal-content {
    display: flex;
    align-items: center;
}

.dz-button {
    background: transparent;
    border: 0;
}