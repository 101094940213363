.myoffer-block {
    width: 500px;
    background: $cprimary-11;
    border-radius: 8px;
    margin: 20px 0;
    padding: 15px;
    hr {
        border-top: 2px solid $cprimary-7;
    }
}

.myoffer-block .offer-title {
    font-size: 1.5em;
}

// .offer-container {
//     margin-right: 20px;
// }

.slick-slide:last-child .offer-container {
    margin-right: 0;
}

.offer-block {
    width: 280px;
    background: #373945;
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
    img {
        display: inline-block !important;
    }
}

.offer-actual {
    font-weight: bold;
    font-style: italic;
    width: auto;
    text-transform: uppercase;
    display: inline-block;
    padding: 6px 15px;
}

.offer-text {
    margin-top: 20px;
}

.offer-border  {
    border: 2px solid $csecondary-2;
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    width: auto;
    display: inline-block;
}

.account-more-options {
    max-width: 500px;
}

.add-account-bank {
    height: 54px;
    button.btn-unfilled-primary {
        padding: 20px;
        border: 0px;
    }
}

.bank-list {
    max-height: 200px;
    overflow-y: auto;
}

.offer-type-choice {
    background: $cprimary-12;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 16px;
    &.active {
        background: $cprimary-7;
    }
}

/*New Offer*/
.offer-container {
    display: flex;
    align-items: flex-start;
    margin-top: 3em;
    .offer {
        margin-right: 100px;
    }
}
.offer .offer-item{
    width: 255px;
    border-radius: 25px;
    background: #fff;
}

.offer-header {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 25px 42px;
    text-transform: capitalize;
    border-radius: 20px 20px 0 0;
    position: relative;
    &.offer-liberty {
        background-color: $cprimary-2;
        color: $cprimary-11;
    }
    &.offer-business {
        background-color: $cprimary-7;
        color: $cwhite;
    }
    &.offer-premium {
        background-color: $cprimary-11;
        color: white;
    }
}

.offer-body {
    color: #000;
}

.offer-choice {
    margin-top: 30px;
    width: 300px;
    text-align: center;
    .btn {
        padding: 12px;
    }
}

.offer-body-price {
    border-bottom: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    &.offer-liberty {
        .st-offerPriceLiberty {
            margin-left: 24px;
            sup {
                padding-left: 8px;
            }
        }
        .offer-price {
            padding: 14px 0;
            sup {
                font-size: 14px;
                top: -1em;
            }
        }
    }
    &.offer-business {
        .offer-price {
            font-size: 28px;
            margin-left: 24px;
            sup {
                font-size: 14px;
                padding-left: 8px;
                top: -1em;
            }
        }
    }
    &.offer-premium {
        .offer-price {
            font-size: 28px;
            margin-left: 24px;
            sup {
                font-size: 14px;
                padding-left: 8px;
                top: -1em;
            }
        }
    }
}

.offer-date {
    color: $csecondary-3;
    font-style: italic;
}

.offer-freeday {
    position: absolute;
    bottom: -13px;
    color: #000;
    font-size: 13px;
    border-radius: 25px;
    padding: 2px 10px;
    left: 50%;
    transform: translate(-50%);
    width: 170px;
    text-transform: initial;
}

.offer-business .offer-freeday, .offer-liberty .offer-freeday {
    border: 1px solid $cprimary-12;
    background: #fff;
}

.offer-premium .offer-freeday {
    border: 1px solid $cprimary-12;
    background: #fff;
}

.offer-body-avantage {
    padding: 20px;
}

.offer-choice-container {
    display: flex;
    margin-top: 2em;
    justify-content: space-between;
    align-items: center;
}

.offer-every-year {
    color: $cprimary-12;
}

.offer-table {
    width: 100%;
    margin-top: 3em;
    th {
        padding: 15px;
    }
    td {
        border: 1px solid #fff;
        border-bottom: 0;
        border-top: 0;
        padding: 15px;
        text-align: center;
        color: $csecondary-4;
        &.cgrey {
            font-size: 24px;
            font-weight: bold;
            color: $cwhite !important;
        }
    }
    tr {
        td:first-child {
            text-align: left;
        }
    }
    tr.first {
        border-top: 1px solid #fff;
    }
    tr.last {
        border-bottom: 1px solid #fff;
    }
}

tr.bggrey {
    background: #40424B;
    td {
        padding: 0;
    }
}

.table-header {
    text-align: center;
    &.offer-liberty {
        font-size: 24px;
        color: $cprimary-7;
    }
    &.offer-business {
        font-size: 24px;
        color: $cprimary-7;
    }
    &.offer-premium {
        font-size: 24px;
        color: darken($cyellow, 30%);
    }
}

.return-full-view {
    // position: absolute;
    // top: 20px;
    font-weight: bold;
}

.offer-container-validation {
    // position: absolute;
    // top: 0;
    // right: 0;
    .offer-content-validation {
        display: none;
        &.active {
            display: block;
        }
    }
}

.js-show-month {
    display: none;
}

.offer-box {
    box-shadow: 0px 3px 14px #00000029;
    border-radius: 20px;
    margin: 0 auto;
    max-width: 255px;
}

.buy-offer-name {
    border-radius: 20px 20px 0 0; 
    color: $cwhite; 
    height: 72px;
    line-height: 72px;
    text-align: center;
    text-transform: capitalize;
    &.offer-liberty {
        background: $cprimary-2;
    }
    &.offer-business {
        background: $cprimary-7;
    }
    &.offer-premium {
        background: $cprimary-11;
    }
}

.buy-offer-price {
    text-align: center;
    padding: 20px;
    &.offer-liberty {
        color: $cprimary-12;
    }
    &.offer-business {
        color: $cprimary-12;
    }
    &.offer-premium {
        color: $cprimary-12;
    }
}

.buy-offer-bills {
    color: $csecondary-4;
}

.offer-demand-payment-by-month
{
    display: none;
}

.offer-every-year
{
    margin-top: 5px;
    font-size: 13px;
}

.offer-by
{
    margin-top: -10px;
}

.st-file-ariane
{
    color: $cwhite;
    font-weight: bold;
    margin-right: 5px;
    &:hover {
        color: $cprimary-7;
    }
}

.st-efacture, .st-paymentSup, .offer-efacture-by {
    font-size: 14px;
}

.st-relance, .st-automatisation, .st-automatisationOffer {
    font-size: 13px;
}

.st-relanceAutoOffer {
    font-size: 12px;
}

.st-offer-choiceOurOffers {
    margin-top: 38px;
}

.st-continuedOffer {
    height: 44px;
    padding: 10px !important;
}

.st-libertyOffer {
    margin-top: -55px;
}

.st-mbMyOffer {
    margin-bottom: 13px;
}

.offer-content-validation {
    position: relative;
}

.popin-changingOffer {
    padding: 40px;
    & .popin-actions button{
        background-color: $cprimary-7;
        height: 50px;
        width: 150px !important;
    }
}

.st-choiceOffer {
    background-color: $cprimary-7;
    border: 0;
    border-radius: 50px;
    color: $cwhite;
    padding: 13px 0;
    margin-top: 30px;
    width: 255px;
}

.st-htSymbol {
    top: -12px;
}

.st-promoAdding {
    background: transparent;
    border: 0;
    margin: auto;
    margin-right: 0;
    width: 98px;
}

.st-blocAddPromo {
    display: none;
    & input {
        border: 1px solid #D2D4D4;
        border-radius: 3px;
        height: 38px;
        text-indent: 0;
        &::placeholder {
            font-size: 10px;
            font-style: italic;
        }
    }   
}

.st-divBlocCodePromoAdding {
    display: none;
}

.st-infoCodePromo {
    background-color: $cprimary-1;
    border-radius: 6px;
    flex-direction: row;
    height: 38px;
    justify-content: space-between;
    margin-top: 20px;
    & p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;
    }
}

.st-codePromoAdding {
    background-color: $csecondary-1;
    border-radius: 3px;
    height: 38px;
    line-height: 38px;
    padding-left: 20px;
}

.js-deleteCodePromo {
    background-color: $csecondary-2;
    border-radius: 6px;
    color: $csecondary-4;
    display: none;
    margin-bottom: 0;
    padding: 6px 12px;
    position: absolute;
    right: 14px;
    top: 4px;
    z-index: 1;
}

.st-blocCodePromoAdding:hover .js-deleteCodePromo {
    display: block;
}

.st-offerCodePromo {
    background: #03261D;
    border-radius: 8px;
    margin: 20px 0;
    padding: 15px;
    width: 500px;
}