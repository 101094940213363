.supplierPaymentPage {
    padding-bottom: 50px;
    margin: auto;
    width: 1160px;
    .js-blocImportBills {
        display: none;
        margin-right: 110px;
        width: 460px;
    }
    .st-blocNumberBills {
        width: 460px;
    }
    .js-numberBills {
        display: none;
        font-weight: 600;
        margin-top: 5px;
    }
    .st-blocImportFacture {
        background-color: $csecondary-1;
        border-radius: 10px;
        height: 424px;
        margin-right: 110px;
        width: 460px;
        h3 {
            font: normal normal 25px/38px;
            font-weight: 600 !important;
            margin-bottom: 20px;
            margin-top: 50px;
            text-align: center;
        }
        .js-drop {
            height: 152px;
            margin: auto;
            width: 360px;
            .dropzone {
                background: transparent;
                border: 0px;
            }
            .st-textImport {
                margin-bottom: 0;
                margin-top: 10px;
            }
        }
        .js-browseImport {
            background-color: $cprimary-12;
            border: 0;
            border-radius: 6px;
            color: $cwhite;
            display: block;
            height: 38px;
            margin: auto;
            margin-bottom: 50px;
            margin-top: 30px;
            width: 360px;
        }
    }
    .st-titleBillSupplier {
        font-family: 'Poppins', sans-serif;
    }
    .st-blocPayNow .st-type-bank, .st-blocPayLater .js-banksFR {
        border: 1px solid #D2D4D4;
        height: 38px;
        padding-left: 20px;
    }
    .select-bank-later {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .js-blocContact {
        display: none;
        .st-containerContact {
            background-color: $csecondary-1;
            border-radius: 10px;
            height: 76px;
            .st-container1, .st-container2 {
                margin-top: auto;
                width: 200px;
            }
        }
        .st-contact-supplier {
            padding-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .js-changeSupplier {
            color: $csecondary-3;
            display: inline-block;
            font-style: italic;
            margin-top: 10px;
            text-decoration: underline;
        }
    }
    .st-blocFormFacture {
        margin-top: -47px;
        width: 555px;
        .js-autocompleteSupplier {
            background-color: $cwhite;
            border-radius: 6px;
            box-shadow: 0px 3px 15px #02130E1A;
            display: none;
            left: 0;
            position: absolute;
            top: 72px;
            width: 270px;
            z-index: 10;
            .js-supplierAuto {
                color: $cprimary-12;
                cursor: pointer;
                font-size: 12px;
                font-weight: bold;
                min-height: 40px;
                margin-bottom: 0;
                padding: 10px 15px;
                word-break: break-all;
                &:hover {
                    background-color: $csecondary-1;
                }
            }
        }
        .form-group {
            margin-right: 15px;
            input {
                border: 1px solid $csecondary-2;
                font-size: 14px;
                height: 38px;
                width: 175px;
                &::placeholder {
                    font-size: 12px;
                    font-style: italic;
                }
            }
            select {
                background-image: url(/build/images/arrow_drop_down_black.svg);
                border: 1px solid $csecondary-2;
                border-radius: 3px;
                font-size: 12px;
                font-weight: normal;
                height: 38px;
                padding: 6px 40px 6px 10px;
                width: 175px;
            }
            .st-destEmail {
                margin-right: 20px;
                width: 270px;
            }
            .st-input-calendar-logo {
                right: 6px;
                top: 6px;
            }
        }
        .js-addSupplier {
            height: 40px;
            margin-top: auto;
            margin-bottom: 15px;
            margin-left: -15px;
            padding: 9px 20px;
            width: 270px;
            img {
                margin-bottom: 5px;
                margin-right: 5px;
            }
            &:hover {
                background-color: #ECFDF9;
                border-radius: 8px;
            }
        }
        .st-titleFormFacture {
            font-weight: 600;
        }
        .js-step1Supplier {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 6px;
            color: $cwhite;
            height: 38px;
            width: 175px;
        }
        .st-supplierTVA {
            color: $csecondary-2;
        }
    }
    .st-blockComptabilite {
        .invoiceCompta {
            display: block;
        }
        .st-drop-up {
            margin-left: 3px;
            margin-top: 50px;
        }
        .st-drop-down {
            display: none;
            margin-left: 3px;
            margin-top: 50px;
        }
        .form-group {
            align-items: center;
            display: flex;
            justify-content: right;
            margin-right: 0;
            position: relative;
            input.st-inputCompta {
                width: 365px;
            }
            input {
                padding-left: 15px;
            }
            input[disabled] {
                background: #FFFFFF;
            }
        }
        .st-blockCompteFournisseur {
            justify-content: right;
        }
        .js-formAddTag {
            .st-selectedTag {
                align-items: center;
                background: #02130E;
                border-radius: 4px;
                color: white;
                display: flex;
                font-size: 14px;
                font-weight: bold;
                height: 24px;
                justify-content: center;
                left: 15px;
                line-height: 14px;
                margin: 0 !important;
                padding: 5px 10px;
                position: absolute;
            }
        }
        label {
            margin-bottom: 0;
        }
        h4 {
            margin-right: 15px;
        }
        .st-compteFournisseur {
            color: $csecondary-4;
            width: 365px;
        }
        .js-autocompleteTag {
            background-color: $cwhite;
            border-radius: 6px;
            box-shadow: 0px 3px 15px #02130E1A;
            display: none;
            left: 0;
            position: absolute;
            top: 48px;
            width: 270px;
            z-index: 10;
            .js-tagAuto {
                color: $cprimary-12;
                cursor: pointer;
                font-size: 12px;;
                margin-bottom: 0;
                width: 95%;
                word-break: break-all;
            }
            .tagP {
                align-items: center;
                height: 40px;
                justify-content: space-between;
                line-height: 40px;
                margin-bottom: 0;
                min-height: 40px;
                padding: 0 15px;
                &:hover {
                    background-color: $csecondary-1;
                }
            }
            .js-addTag {
                display: block;
                line-height: 40px;
                padding: 0 15px;
                width: 100%;
            }
        }
        .st-deleteTagBlock {
            height: 20px;
            text-align: right;
            width: 20px;
        }
        .st-deleteTagBlock {
            background: url("../../../images/close_lightgrey.svg") no-repeat center;
            background-size: 8px;
            border-radius: 100%;
            cursor: pointer;
            z-index: 15;
        }
        .st-deleteTagBlock:hover {
            background: url("../../../images/close_dark_grey.svg") no-repeat center #D2D4D4;
            background-size: 8px;
        }
        .st-removeFromInput {
            background: url("../../../images/close_white.svg") no-repeat center;
            background-size: 7px;
            cursor: pointer;
            height: 24px;
            margin-left: 10px;
            width: 8px;
        }
        .js-autocompleteTaxAccount, .js-autocompleteBankAccount, .js-autocompleteChargeAccount {
            background-color: $cwhite;
            border-radius: 6px;
            box-shadow: 0px 3px 15px #02130E1A;
            display: none;
            position: absolute;
            top: 48px;
            width: 365px;
            z-index: 10;
            .js-planAuto {
                color: $cprimary-12;
                cursor: pointer;
                font-size: 12px;;
                line-height: 40px;
                margin-bottom: 0;
                min-height: 40px;
                padding: 0 15px;
                word-break: break-word;
                &:hover {
                    background-color: $csecondary-1;
                }
            }
        }
    }
    .st-titleSection
    {
        margin-bottom: 12px;
    }
    .js-supplierInfos, .js-supplierPayment {
        color: #ABB0AF;
        font-weight: 600;
        &.active {
            color: $cprimary-12;
        }
    }
    .js-supplierInfos {
        margin-right: 30px;
    }
    .deleteDoc {
        color: $csecondary-3;
        font-style: italic;
        margin-top: 20px;
        text-align: center;
    }
    .invoice-approval {
        display: none;
        .st-drop-up {
            margin-left: 3px;
            margin-top: 45px;
        }
        .st-drop-down {
            display: none;
            margin-left: 3px;
            margin-top: 45px;
        }
        .st-infoApproval {
            background-color: $cprimary-1;
            border-radius: 6px;
            flex-direction: row;
            height: 38px;
            justify-content: space-between;
            margin-bottom: 20px;
            margin-top: 10px;
            & p {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 20px;
            }
        }
        .st-formApproval {
            .form-group {
                margin-right: 20px;
            }
            .js-send-approval-request {
                background: none;
                border-radius: 6px;
                border: 2px $cprimary-12 solid;
                color: $cprimary-12;
                width: 102px;
                height: 38px;
            }
        }
        .st-sendApproval {
            display: none;
            .st-blocAppro {
                margin-right: 20px;
                width: 270px;
            }
            .st-approvalMail {
                background-color: $cprimary-1;
                height: 32px;
                padding-left: 20px;
                border-radius: 6px;
                line-height: 32px;
            }
            .st-approvalStatus {
                font-weight: bold;
                font-family: "Roboto";
            }
            .st-blocLink {
                border: 1px solid $cprimary-12;
                border-radius: 3px;
                height: 38px;
                justify-content: space-between;
                width: 270px;
                & p {
                    padding-left: 15px;
                    margin-bottom: auto;
                    margin-top: auto;
                }
                & img {
                    margin-bottom: auto;
                    margin-top: auto;
                    margin-right: 13px;
                    margin-left: 13px;
                }
            }
            .st-blocFakeLink {
                border: 1px solid #D2D4D4;
                border-radius: 3px;
                color:#D2D4D4;
                height: 38px;
                justify-content: space-between;
                width: 270px;
                & p {
                    padding-left: 15px;
                    margin-bottom: auto;
                    margin-top: auto;
                }
                & img {
                    margin-bottom: auto;
                    margin-top: auto;
                    margin-right: 13px;
                    margin-left: 13px;
                }
            }
            .st-relanceAppro {
                background: none;
                border: 1px solid $cprimary-12;
                border-radius: 6px;
                font-weight: bold;
                height: 38px;
                margin-left: 20px;
                margin-top: 10px;
                width: 188px;
            }
            .st-linkAppro {
                height: 38px;
                line-height: 36px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 220px;
                white-space: nowrap;
            }
        }
    }
    .st-blocPayment {
        display: none;
        .st-blocRadio1 {
            background-color: $csecondary-1;
            width: 185px;
        }
        .st-blocRadio1, .st-blocRadio2, .st-blocRadio3 {
            border-radius: 6px;
            padding-left: 25px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: auto;
            margin-bottom: auto;
        }
        .st-saveQuit1, .st-saveQuit2, .st-saveQuit3 {
            background: transparent;
            border: 0;
            margin-right: 36px;
        }
        .js-toPayNow, .js-toPayLater, .js-toAlreadyPay {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 6px;
            color: $cwhite;
            height: 38px;
            width: auto;
            padding: 0 26px;
        }
        select:not(.form-control) {
            background-image: url("/build/images/arrow_drop_down_black.svg");
            border-radius: 3px;
            border: 1px solid #D2D4D4 !important;
            font-weight: normal;
            height: 38px;
            margin-top: 0 !important;
        }
        .js-bankIban, .js-bankOwner, .js-bankIban-later, .js-bankOwner-later {
            border: 1px solid #D2D4D4;
            height: 38px;
        }
        .js-bankOwner::placeholder, .js-bankOwner-later::placeholder {
            font-size: 12px !important;
        }
        .st-bankNotFound{
            text-decoration: underline;
            color: $csecondary-4;
        }
        .st-virementClassic {
            display: none;
            color: $csecondary-4;
        }
        [type="checkbox"] {
            position: absolute;
            left: 0;
            top: 20px;
            display: none;
        }
        
        [type="checkbox"] + label {
            position: relative;
            padding-left: 38px;
            font-size: 1.05em;
            line-height: 1.7;
            height: 28px;
            cursor: pointer; 
            margin-left: -10px;
            margin-bottom: 0;
        }
    
        /* Aspect de la case */
        [type="checkbox"]:checked + label::before {
            content: '';
            position: absolute;
            left: 10px;
            top: 5px;
            width: 17px;
            height: 17px;
            border: 2px solid $cprimary-12;
            background-color: $cprimary-12;
            border-radius: 3px;
        }
        [type="checkbox"]:not(:checked) + label::before {
            content: '';
            position: absolute;
            left: 10px;
            top: 5px;
            width: 17px;
            height: 17px;
            border: 2px solid $cprimary-12;
            background-color: white;
            border-radius: 3px;
        }
    
        /* Aspect de la coche */
        [type="checkbox"]:checked + label::after{
            content: attr(data-content);
            position: absolute;
            font-size: 12px;
            color: white;
            width: 12px;
            height: 12px;
            top: 4px;
            left: 14px;
        }
    
        /* Aspect non cochée */
        [type="checkbox"]:not(:checked) + label::after {
            opacity: 0;
            transform: scale(0) rotate(45deg);
        }
    
        /* Aspect cochée */
        [type="checkbox"]:checked + label::after {
            opacity: 1;
            transform: scale(1) rotate(0);
        }
        // .st-infoPayLater {
        //     background-color: $cprimary-1;
        //     border-radius: 6px;
        //     flex-direction: row;
        //     height: 38px;
        //     justify-content: space-between;
        //     margin-bottom: 20px;
        //     margin-top: 10px;
        //     & p {
        //         margin-top: auto;
        //         margin-bottom: auto;
        //         margin-left: 20px;
        //     }
        // }
        .st-blocPayLater, .st-blocAlreadyPay {
            display: none;
        }
        .st-blocSentLater {
            height: 38px;
            width: 175px;
        }
        & .st-input-calendar-logo {
            right: 10px;
            top: 5px;
        }
        .js-autocompleteBank {
            background-color: $cwhite;
            border-radius: 6px;
            box-shadow: 0px 3px 15px #02130E1A;
            display: none;
            position: absolute;
            z-index: 10;
            .js-bankAuto {
                color: $cprimary-12;
                cursor: pointer;
                font-size: 12px;
                font-weight: bold;
                min-height: 40px;
                margin-bottom: 0;
                padding: 10px 15px;
                word-break: break-all;
                &:hover {
                    background-color: $csecondary-1;
                }
            }
        }
    }

    // items on mobile
    .st-footerMobile {
        background-color: $cwhite;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border: 1px $csecondary-1 solid;
        bottom: 0;
        box-shadow: $csecondary-1 0px -3px 15px;
        display: none;
        height: 78px;
        position: fixed;
        width: 100%;

        .js-importBillMobile {
            background-color: $cprimary-12;
            border: 0;
            border-radius: 6px;
            color: white;
            display: block;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 260px;
        }
    
        .js-nextAfterImportBill {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 6px;
            color: white;
            display: none;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 260px;
        }

        .js-showImportBill {
            background-color: $csecondary-1;
            border: 0;
            border-radius: 6px;
            color: black;
            display: none;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 170px;
            img {
                margin-right: 6px;
                margin-top: -3px;
            }
        }

        .js-step1Supplier {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 6px;
            color: $cwhite;
            display: none;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 170px;
        }

        .st-saveQuit1, .st-saveQuit2, .st-saveQuit3 {
            background: transparent;
            border: 0;
            display: none;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 170px;
        }

        .js-toPayNow, .js-toPayLater, .js-toAlreadyPay {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 6px;
            color: $cwhite;
            display: none;
            height: 38px;
            margin: auto;
            margin-top: 20px;
            width: 170px;
        }
    }
}

.pageRecap.supplierPaymentPage {
    .js-blocImportBills, .st-blockPdf {
        width: 460px;
    }
    .st-btnDl {
        border-radius: 6px;
        height: 38px;
        line-height: 26px;
        width: 144px;
    }
    .st-btnDl:hover {
        background-color: $csecondary-1;
    }
    .js-saveRecap {
        background-color: $cprimary-7;
        border-radius: 6px;
        color: #FFF;
        height: 38px;
        padding: 0 26px;
        width: 182px;
    }
    .invoiceCompta {
        display: block;
    }
    .st-blockComptabilite {
        .st-drop-up {
            display: block;
        }
        .st-drop-down {
            display: none;
            margin-left: 3px;
            margin-top: 50px;
        }
    }
    .st-blocFormFacture {
        padding-top: 45px;
    }
    .invoice-infos {
        .st-drop-up {
            display: none;
            margin-left: 3px;
        }
        .st-drop-down {
            margin-left: 3px;
        }
        .invoice-resume {
            display: none;
        }
        .info-group {
            width: 33%;
            p {
                color: $csecondary-3;
            }
        }
        .st-approvalStatus {
            margin-right: 5px;
        }
        .st-approvalCommentResume {
            padding-left: 20px;
        }
        .st-bankEntry {
            line-height: 38px;
        }
    }
}

.st-blocFormError1, .st-blocFormError2, .st-blocFormError3, .popin-addSupplier .st-blocPopinError, .st-blocFormErrorImport,
.st-approval-errors, .popin-payWaitingApprobation .st-blocPopinError, .popin-payRefusApprobation .st-blocPopinError, .popin-accountingPlan .st-blocPopinError {
    background-color: #FCEAE7;
    border-radius: 6px;
    flex-direction: row;
    height: 38px;
    justify-content: space-between;
    margin-bottom: 20px;
    & p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;
    }
}

.st-blocFormErrorImport {
    width: 460px;
    & p:nth-child(2) {
        margin-left: 0;
    }
}

.st-crossInfos, .st-crossInfosLater, .st-crossInfosLink  {
    margin-right: 20px;
}


// ---* Popin Plan Comptable *--

.popin-accountingPlan {
    .st-blockAddPlan {
        margin-top: 20px;
    }
    .st-addPlan {
        cursor: pointer;
        margin-bottom: 0;
        padding: 12px 10px;
        width: 250px;
        img {
            margin-right: 10px;
        }
        &:hover {
            background-color: $csecondary-1;
            border-radius: 6px;
        }
    }
    .st-addPlan.st-formVisible {
        cursor: initial;
        padding-left: 0;
        &:hover {
            background: none;
        }
    }
    .st-blockAccountingPlan {
        border: 1px solid $csecondary-2;
        border-radius: 3px;
        height: 455px;
        margin-top: 20px;
        overflow-y: scroll;
        padding: 20px 10px 20px 30px;
    }
    .st-planElement {
        margin-bottom: 10px;
        .st-planReference {
            color: $csecondary-3;
            display: inline-block;
            margin-right: 20px;
            min-width: 39px;
            text-align: right;
        }
    }
    .st-planElement[data-tag="TITLE"] {
        text-transform: uppercase;
    }
    .st-planElement[data-tag="SUBTITLE"], .st-planElement[data-tag="TITLE"] {
        font-weight: bold;
    }

    .st-formAddPlan {
        display: none;
    }
    .form-group {
        margin-bottom: 0;
        input {
            border: 1px solid $csecondary-2;
            font-size: 14px;
            height: 38px;
            &::placeholder {
                font-size: 12px;
                font-style: italic;
            }
        }
        #planRef {
            margin-right: 10px;
        }
        .st-savePlan {
            border-radius: 6px;
            height: 38px;
            margin-top: 10px;
            width: 122px;
        }
    }
    .st-planHeader {
        p {
            min-width: fit-content;
        }
        .input-group {
            width: auto;
            img {
                left: 13px;
            }
            input {
                border: none;
                cursor: pointer;
                padding-left: 35px !important;
                padding-right: 0 !important;
                width: 113px;

                &:hover {
                    background-color: $csecondary-1
                }
            }
        }
    }
}