.new-payment-form {
    width: 500px;
    // margin: 0 auto;
    // margin-left: calc(50% - 420px);
}

.new-payment-createbills {
    [class^="step-content-"] {
        width: 500px;
        .steps-info {
            justify-content: start;
        }
        // input, select, textarea {
        //     width: 100%;
        // }
        .step-title {
            font-weight: bold;
            font-size: 24px;
        }
        .step-step {
            margin-left: 20px;
            font-style: italic;
        }
    }
    .step-footer {
        justify-content: flex-end;
        .next-step {
            display: flex;
            margin-top: 20px;
            align-items: center;
        }
    }
}

// section.section-header {
//     width: 650px;
//     margin: 0 auto 0 calc(50% - 420px);
// }

.payment-choice-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.payment-choice-block {
    border-radius: 4px;
    margin: 10px;
    border: 2px solid $cprimary-7;
    padding: 20px;
    width: 30%;
    max-width: 350px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    &.disabled {
        opacity: 0.5;
    }
}

.new-payment-form {
    .custom-file {
        position: absolute;
        top: 0px;
        left: 0;
    }

    .custom-file input {
        font-size: 16px;
        font-weight: normal;
        width: 30%;
    }

    .custom-file input + label {
        background: transparent;
        border: 0;
        display: inline-block;
        margin: 0;
        // color: $cwhite;
        min-height: 80px;
        line-height: 65px;
        padding-left: 0;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }

    .custom-file input + label:after {
        border: 1px solid $cprimary-7;
        border-radius: 100px;
        background: #fff;
        height: 45px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: $cprimary-7;
        width: 200px;
        content: "Choisir un fichier";
        font-size: 16px;
        cursor: pointer;
    }
    hr {
        border-color: $cprimary-7;
    }

    label {
        font-weight: bold;
    }
}

.element-realise-btn {
    padding: 20px;
    border: 2px solid $cprimary-7;
    text-align: center;
    color: $cprimary-7;
    border-radius: 8px;
}

.input-title {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
}

.popin-add-element input {
    border: 1px solid $csecondary-1;
}

.js-createbills-elements {
    margin-bottom: 20px;
}

.billselm-title {
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.billselm-desc {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
}

.bills-recap-element {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $cprimary-7;
}

.js-createbills-elements {
    background: $csecondary-1;
    border-radius: 8px;
    padding: 20px;
}

.recap img{
    border: 1px solid $csecondary-2;
}

.recap .loader {
    position: static;
}

.recap .loader .loader-inside {
    position: static;
    transform: none;
    img {
        border: 0;
    }
}

.payment-not-enought {
    background: $cprimary-7;
    border-radius: 4px;
    padding: 10px;
    font-style: italic;
    max-width: 720px;
    color: $cwhite;
    a {
        font-weight: bold;
        color: $cwhite;
        font-style: normal;
    }
}