.btn {
    border-radius: 100px;
    padding: 6px 15px;
    border: 0;
    font-weight: bold;
    background: transparent;
    cursor: pointer;
    &:hover {
        border: 0;
        background: transparent;
    }
}

.btn-primary {
    background: $cprimary-7 !important;
    background-color: $cprimary-7 !important;
    color: $cwhite !important;
    text-align: center;
    width: 100%;
    font-weight: bold;
    border: 0;
    &:hover {
        background: $cprimary-7 !important;
        background-color: $cprimary-7 !important;
        color: $cwhite;
        border: 0;
    }
}

.btn-danger {
    background: $cred !important;
    background-color: $cred !important;
    color: $cwhite !important;
    text-align: center;
    width: 100%;
    font-weight: bold;
    border: 0;
    &:hover {
        background: $cred !important;
        background-color: $cred !important;
        color: $cwhite;
        border: 0;
    }
}

.btn-primary-light {
    background-color: $cprimary-5;
}
.btn-primary-light a {
    color: $cwhite ;
}

.btn-black {
    background: $cprimary-12;
    color: $cwhite;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: $cprimary-12;
        color: $cwhite;
    }
}

.btn-error {
    background: $corange;
    color: $cwhite;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: $corange;
        color: $cwhite;
    }
}

.btn-grey {
    background: lightgray;
    color: $cwhite;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: lightgray;
        color: $cwhite;
    }
}

.btn-orange {
    background: $corange;
    color: $cwhite;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: $corange;
        color: $cwhite;
    }
}

.btn-orangefade {
    background: $corangefade;
    color: $cprimary-12;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: $corangefade;
        color: $cprimary-12;
    }
}

.btn-greenfade {
    background: rgba(#439AFF, 0.2);
    color: $cprimary-12;
    text-align: center;
    width: 100%;
    font-weight: bold;
    &:hover {
        background: rgba(#439AFF, 0.2);
        color: $cprimary-12;
    }
}

.btn-green {
    background: $cprimary-7 !important;
    background-color: $cprimary-7 !important;
    color: $cwhite !important;
    text-align: center;
    width: 100%;
    font-weight: bold;
    border: 0;
    &:hover {
        background: $cprimary-7 !important;
        background-color: $cprimary-7 !important;
        color: $cwhite;
        border: 0;
    }
}

a.active {
    color: $cprimary-7 !important;
}

a.underline {
    text-decoration: underline !important;
}

a:hover {
    text-decoration: none;
}

.ignorebtn button {
    background: #f3f3f4;
    color: $cprimary-7;
}

.fullbtn {
    width: 100%;
}

.btn-primary.disabled, .btn-primary:disabled {
    background: $cprimary-7;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
     color: $cprimary-7; 
     background-color: #fff; 
     border-color: $cprimary-7; 
}

a.btn.btn-primary {
    color: #fff;
    &:hover {
        color: #fff;
    }
}

.btn-greenfilled-primary {
    border: 2px solid $cprimary-7;
    color: $cprimary-7;
    background: #fff;
    width: 145px !important;
    padding: 10px;
    font-weight: bold;
    border-radius: 4px;
}

.btn-greenfilled-primary:hover {
    border: 2px solid $cprimary-7;
    color: $cprimary-7;
}

.btn-whitefilled-primary {
    border: 2px solid $cprimary-7;
    color: $cprimary-7;
    background: #fff;
    width: 145px !important;
    padding: 6px 15px;
    font-weight: bold;
    border-radius: 4px;
}

.btn-whitefilled-primary:hover {
    border: 2px solid $cprimary-7;
    color: $cprimary-7;
}

.btn-unfilled-primary {
    border: 1px solid $cprimary-7;
    color: $cprimary-7;
    width: auto !important;
    // min-width: 175px !important;
    padding: 6px 15px;
}

.btn-unfilled-primary:hover {
    border: 1px solid $cprimary-7;
    color: $cprimary-7;
}

.btn-unfilled-white {
    border: 1px solid $cwhite;
    color: $cwhite;
    padding: 6px 15px;
}

.btn-unfilled-white:hover {
    border: 1px solid $cwhite;
    color: $cwhite;
}

.btn-unfilled-red {
    border: 1px solid $cred;
    color: $cred;
    width: auto !important;
    background: $cwhite;
    padding: 10px;
}

.btn-unfilled-red:hover {
    border: 1px solid $cred;
    color: $cred;
}

.btn-delete-red {
   /* border-radius: 7%;
    color: $cred;
    width: auto !important;
    background: $cwhite;*/
    padding: 6px 15px;
    font-weight: bold;

    width: 118px;
    height: 38px;
    color: $cred;
    border: 1px solid #E1280C!important;
    border-radius: 6px;
    opacity: 1;
}

.btn-delete-red:hover {
    background-color: #C7230A0D;
}

.swal-button--confirm {
    background-color: $cprimary-10;
    &:not([disabled]):hover {
        background-color: $cprimary-10;
    }
}

.swal-button--danger:not([disabled]):hover {
    background-color: #df4740;
}

.swal-button--danger {
    background-color: #e64942;
}

.no-btn {
    border-radius: 100px;
    background-color: $cprimary-7 !important;
    color: $cwhite;
    font-weight: bold;
    &:hover {
        background-color: $cprimary-7;
        color: $cwhite;
    }
}

.white-btn {
    border-radius: 100px;
    background-color: $cwhite;
    color: $cprimary-7;
    font-weight: bold;
    border: solid 1px $cprimary-7;
    &:hover {
        background-color: $cprimary-7;
        color: $cwhite;
    }
}

.st-no-btn-disabled {
    opacity: .4;
    cursor: default;
    pointer-events: none;
}

.tooltip-sign {
    // margin-left: 10px;
    // background: #fff;
    // border-radius: 0px 4px 4px 0px;
    // width: 30px;
    // height: 100%;
    // display: inline-block;
    // color: $corange;
    // line-height: 40px;
    // position: absolute;
    // right: 0;
    // top: 0;
    // border: 1px solid;
    // cursor: pointer;
    margin-left: 10px;
    background: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    color: $corange;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 7px;
    line-height: 19px;
    border: 1px solid;
    cursor: pointer;
}

.btn-project a {
    background: $cwhite;
    white-space: nowrap;
    padding: 5px 15px;
    border-radius: 20px;
    margin: 0 10px 0 20px;
    font-weight: bold;
}

.linkblue {
    color: $cprimary-7;
    &:hover {
        color: $cprimary-7;
    }
}

.fullbutton {
    background: $cprimary-7;
    color: #fff !important;
    padding: 10px;
    border-radius: 4px;
    white-space: nowrap;
    width: auto;
    display: inline-block;
    margin-right: 15px;
    margin-top: 40px;
}

.btn-white {
    background: $cwhite;
    color: $cprimary-7;
}

.btn-transparent {
    background: transparent;
    color: $cprimary-12 !important;
    text-align: center;
    width: 100%;
    font-weight: bold;
    border: solid 1px $cprimary-12;
    &:hover {
        border: solid 1px $cprimary-12;
    }
}


.btn-light-green {
    background: $cprimary-1 !important;
    border-radius: 0;
    color: #10BC91;
    height: 36px;
    width: 122px;
    &:hover {
        background: $cprimary-2 !important;
        color: #10BC91;
    }
}