.st-height-auto {
    height: auto;
}

.st-height-15px {
    height: 15px;
}

.st-height-20px {
    height: 20px !important;
}

.st-height-25px {
    height: 25px;
}

.st-height-43px-md-63px {
    height: 63px;
}

.st-height-44px {
    height: 44px;
}

.st-height-50px {
    height: 50px;
}

.st-height-54px {
    height: 54px;
}

.st-height-60px {
    height: 60px;
}

.st-height-70px {
    height: 70px;
}

.st-height-80px {
    height: 80px;
}

.st-height-90px {
    height: 90px;
}

.st-height-135px {
    height: 135px;
}

.st-height-140px {
    height: 140px;
}

.st-min-height-auto-md-425px {
    min-height: 425px;
}

.st-min-height-80px {
    min-height: 80px;
}

.st-height-100vh {
    height: 100vh;
}

.st-height-100 {
    height: 100%;
}
.st-min-height-385px { min-height: 385px; }
.st-min-height-390px {
    height: 390px;
}
.st-height-439px {
    height: 439px !important;
}
.st-max-width-none {
    max-width: none !important;
}

.st-max-width-11Opx {
    max-width: 110px;
}

.st-max-width-16Opx {
    max-width: 160px;
}

.st-max-width-200px {
    max-width: 200px;
}

.st-max-width-220px {
    max-width: 220px;
}

.st-max-width-275px {
    max-width: 275px;
}

.st-max-width-500px {
    max-width: 500px;
}

.st-max-width-540px {
    max-width: 540px;
}

.st-max-width-605px {
    max-width: 605px;
}

.st-max-width-660px {
    max-width: 660px;
}

.st-min-width-78px {
    min-width: 78px;
}

.st-min-width-100px {
    min-width: 100px;
}

.st-min-width-120px {
    min-width: 120px;
}

.st-min-width-160px {
    min-width: 160px;
}

.st-min-width-175px {
    min-width: 175px;
}

.st-min-width-180px {
    min-width: 180px;
}

.st-min-width-200px {
    min-width: 200px;
}

.st-min-width-210px {
    min-width: 210px;
}

.st-min-width-sm-364px {
    min-width: 364px;
}

.st-width-20px {
    width: 20px !important;
}

.st-width-25px {
    width: 25px;
}

.st-width-30 {
    width: 30%;
}

.st-width-32 {
    width: 32%;
}

.st-width-45 {
    width: 45%;
}

.st-width-48 {
    width: 48%;
}

.st-width-65 {
    width: 65%;
}

.st-width-70 {
    width: 70%;
}

.st-width-15px {
    width: 15px;
}

.st-width-78px {
    width: 78px;
}

.st-width-80px { width: 80px !important; }

.st-width-90px {
    width: 90px;
}

.st-width-100px {
    width: 100px;
}

.st-width-120 {
    width: 120%;
}

.st-width-145px {
    width: 145px;
}

.st-width-165px {
    width: 165px;
}

.st-width-185px {
    width: 185px;
}

.st-width-200px {
    width: 200px;
}

.st-width-228px {
    width: 228px;
}
.st-width-270px { width: 270px !important; }
.st-width-275px { width: 275px; }
.st-width-360px { width: 360px; }

.st-width-700px {
    width: 700px !important;
}

.st-width-100-xs-364px {
    width: 364px;
}

.st-width-100-md-25 {
    width: 25%;
}

.st-width-100-md-50 {
    width: 50%;
}

.w-100-lg-50 {
    width: 50%;
}

.w-100-md-auto {
    width: auto;
}