.st-avatarEmpty {
    border: dashed 1px $cprimary-7;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}

.st-personalization-load-zone {
    border: dashed 1px $cprimary-7;
    border-radius: 8px;
}