.menu-round-picto {
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    border-radius: 50%;
    height: 115px;
    width: 115px;
    padding: 8px;
    margin: 0 auto;
}

.menu-links > div {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}


.menu-links > div a {
    margin-left: 20px;
    font-weight: bold;
}

.menu-links {
    margin-left: 10px;
}

.open-menu-dots {
    cursor: pointer;
    width: 40px;
    text-align: right;
}

.menu-dots {
    min-width: 155px;
    display: none;
    background: #fff;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 10px;
    text-align: left;
    position: absolute;
    white-space: nowrap;
    z-index: 100;
    right: -20px;
    top: -20px;
    a {
        display: block;
        border-bottom: 1px solid $csecondary-2;
        padding: 10px 15px;
        text-decoration: none !important;
        &:hover {
            background-color: $csecondary-1;
            font-weight: 700;
        }
    }
}

.js-changeLocale {
    position: relative;
    margin-right: 25px;
    top: 8px;
}

.st-locales {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 1px 3px #00000033;
    color: #000000; 
    left: -8px;
    & a {
        padding: 10px 15px;
        margin-bottom: 0;
        border-radius: 10px;
        display: block;
        &:hover {
            background-color: #F7F7F8;
        }
    }
}

.st-test {
    .js-changeLocale {
        right: 0;
        margin-right: 0;
        top: 6px;
        position: absolute;
    }
}

.signup-step1, .signup-step2, .st-offlinePayment, .st-loginPage {
    .js-changeLocale {
        right: 15px;
        margin-right: 0;
        top: 15px;
        position: absolute;
        z-index: 10;
    }
}
