$cprimary-12: #02130E; //$cmainblack
$cprimary-11: #03261D; //$cmainblackLight
$cprimary-10: #074B3A; //$cmaingreenDark
$cprimary-9: #0A7157;
$cprimary-8: #0D9674;
$cprimary-7: #10BC91; //$cmaingreen // MAIN GREEN
$cprimary-6: #14E1AE; //$cmaingreenLight
$cprimary-5: #50F0C7; //$cmaingreenFluo
$cprimary-4: #7CF4D6; //$cmaingreenAqua
$cprimary-3: #A1F7E1; //$cmaingreenMint
$cprimary-2: #C7FAED; //$cmaingreenPale
$cprimary-1: #ECFDF9; //$cmaingreenVerylight
///////////////
$csecondary-4: #54645F; //$cmaingreyVerydark
$csecondary-3: #ABB0AF; //$cmaingrey // MAIN GREY
$csecondary-2: #D2D2D4; //$cmaingreyIntermediary
$csecondary-1: #F8F8F8; //$cmaingreyLight
///////////////
$cgreenValidation: #14E1AE;
$cred: #E1280C;

$cwhite: #ffffff;
$corange: #FFCD5D;
$corangefade: lighten($corange, 20%);
$cbrownred: #881f53;
$cyellow: #fff8d9;

$clbp: #28395F;
.clbp {
    color: $clbp;
}
.bggrey-input {
    input {
        background: #f3f3f4;
    }
    textarea {
        background: #f3f3f4;
    }
    select {
        background: #f3f3f4;
    }
}

.bgprimary-1 {
    background-color: $cprimary-1;
}

.bgprimary-5 {
    background-color: $cprimary-5 !important;
}

.bgprimary-12 {
    background-color: $cprimary-12;
}

.bgsecondary-1 {
    background-color: $csecondary-1;
}

.cbrownred {
    color: $cbrownred !important;
    &:hover {
        color: $cbrownred !important;
    }
}

.cerror {
    color: $cred; 
    &:hover {
        color: $cred !important;
    }
}

.blackFilePopin {
    color:$cprimary-12;
    &:hover {
        color: $cprimary-12!important;
    }
}

.cerror * {
    color: $cred; 
}

.corange {
    color: $corange;
    &:hover {
        color: $corange !important;
    }
}

.corangefade {
    color: $corangefade;
}

.cprimary-5 {
    color: $cprimary-5;
}

.cprimary-6 {
    color: $cprimary-6;
}

.cprimary-7 {
    color: $cprimary-7;
}

.cprimary-12 {
    color: $cprimary-12;
}

.csecondary-3 {
    color: $csecondary-3;
}
.csecondary-4 {
    color: $csecondary-4;
}

.ctext{
    color: $cprimary-12 !important;
}

.cwhite {
    color: $cwhite !important;
    &:hover {
        color: $cwhite !important;
    }
}

.cyellow {
    color: $cyellow !important;
    &:hover {
        color: $cyellow !important;
    }
}

/*table header*/
thead tr th {
    color: $csecondary-1;
    white-space: nowrap;
}

textarea.error {
    border: 1px solid $cred !important;
}

input.error {
    border: 1px solid $cred !important;
}

div.error {
    border: 1px solid $cred !important;
}

/* VALIDATIONS / ERRORS / ALERTS etc... */
.border-red {
    border: 1px solid red;
}

input.error {
    border: 1px solid $cred !important;
}

.alert-success {
    color: #fff;
    background-color: lighten($cprimary-7, 10%);
    border-color: lighten($cprimary-7, 20%);
}

.cgreenvalidate {
    color: $cgreenValidation;
}

// HOVER

.st-hover-cprimary12 {
    &:hover {
        color: $cprimary-12;
    }
}