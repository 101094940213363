@media (hover: hover) {
    .menu-item-dashboard {
        &:hover {
            font-weight: bold;
        }
    }
}
.st-list-overlay-home {
    padding: 0 0 0 20px;
    li {
        display: list-item;
        list-style-type: '✓';
        padding-inline-start: 1ch;
    }
}
.st-dashboard-section-title {
    font-size: 18px;
}
.new-project-content h1 {
    // text-align: center;
    margin: 20px;
    font-size: 56px;
    font-weight: bold;
}

.activity-project-content h1 {
    text-align : center;
}

.new-project-actions {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-left: 70px;
    img {
        margin-left: -45px;
    }
}

.table-sort {
    padding: 20px 0;
    display: flex;
    display: none;
}

.table-sort > div {
    margin: 0 10px;
}

.new-project-status a {
    margin: 0 10px;
}

#mytable_length, #mytable1_length, #mytable2_length{
    display: none;
}

// #mytable_filter, #mytable1_filter, #mytable2_filter {
//     position: absolute;
//     right: 0;
//     top: -60px;
// }

#mytable_wrapper, #mytable1_wrapper, #mytable2_wrapper {
    position: relative;
}

// #mytable_filter {
//     display: none;
// }

#mytable_info, #mytable1_info, #mytable2_info{
    display: none;
}

#mytable, #mytable1, #mytable2 {
    margin-bottom: 15px;
}
#mytable_paginate > span {
    margin: 0 10px;
}
// #mytable_paginate {
//     display: none;
// }
#mytable th {
    font-weight: normal;
    padding: 20px 0;
}

#mytable tbody tr, #mytable1 tbody tr, #mytable2 tbody tr {
    height: 50px;
    border-bottom: 1px solid #d4d4d6;
}

td.color-primary a {
    color: $cprimary-7;
}

#mytable th, #mytable1 th, #mytable2 th {
    cursor: pointer;
    padding-left: 20px;
}

#mytable td, #mytable1 td, #mytable2 td {
    padding-left: 20px;
    max-width: 200px;
    //word-break: break-word;
}

  th.sorting:empty {
    background-image: none !important;
}

.dataTables_filter input[type="search"] {
    border: 1px solid #cdcdcd;
    outline: 0;
    outline-offset: 0;
    padding: 5px;
}

a.paginate_button.current {
    color: $cprimary-7;
    font-weight: bold;
}

a.paginate_button {
    cursor: pointer;
    margin: 0 5px;
}

.doc:nth-child(4n+1) {
    margin-left: 0;
}

.container-block-project {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 17px;
    height: 0px;
    position: relative
}

.block-project {
    width: 325px;
    height: 245px;
    margin: 5px;
    background-color: white;
    border: 1px solid #212529;
    border-radius: 8px;
    height: 180px;
    width: 180px;
    &.add-card {
        display: block;
        cursor: pointer;
        z-index: 1;
        background-color: white;
        border: 1px solid #212529;
        &:hover {
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        }
    }
}

.block-project.new-project {
    border: 2px dashed $cbrownred;
    color: $cbrownred;
    // font-size: 24px;
    font-weight: bold;
}

.project-name {
    // font-size: 24px;
    margin-right: 20px;
    word-break: break-word;
    overflow: hidden;
    font-weight: bold;
}

.project-content {
    padding: 20px 0;
}

.item {
    display: block;
    cursor: pointer;
    z-index: 1;
    position: absolute;
}

.item:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}
  .item.muuri-item-dragging {
    z-index: 3;
  }
  .item.muuri-item-releasing {
    z-index: 2;
  }
  .item.muuri-item-hidden {
    z-index: 0;
  }
  .item-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .project-footer {
    position: absolute;
    bottom: 0;
  }

.search-project {
    padding: 0 10px;
    width: 240px;
    border: none;
}
.input-search-group {
    width: 80px !important;
}
.dash-top-bar {
    margin-top: 50px;
    background: #881f53;
    height: 185px;
    display: flex;
    justify-content: space-between;
}

.dash-topbar-text {
    padding: 20px;
}

.dash-topbar-img {
    position: relative;
    width: 285px;
}

.dash-topbar-img img {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
}

.dash-pay {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.dash-wallet {
    margin-top: 30px;
    padding: 40px;
    border-radius: 4px;
    background: $cwhite;
    div {
        font-weight: bold;
    }
}

.dash-info {
    margin-top: 30px;
    padding: 40px;
    background: $cwhite;
    border-radius: 4px;
}

.dash-info-text {
    width: calc(100% - 80px);
}

.dash-price {
    font-weight: bold;
    font-size: 36px;
}

.dash-wallet-info {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dash-gauge {
    padding: 20px 40px 20px 40px;
    background: $cwhite;
    margin-top: 20px;
    height: calc(50% - 10px);
}

.dash-level {
    padding: 40px;
    text-align: center;
    width: 33%;
    background: $cyellow;
    font-size: 16px;
    &.nolvl {
        background: #e5fdf7;
        padding: 60px 40px;
    }
}

.dash-level-title {
    font-weight: bold;
    font-size: 24px;
}

.dash-project {
    background: $cwhite;
    border-radius: 4px;
}

.dash-block-pay {
    margin-right: 20px;
    width: 75%;
}

.dash-progress-box {
    margin: 50px 0 40px 0;
}

.nolvl .dash-progressbar {
    height: 30px;
    width: 100%;
    border-radius: 20px;
    background-color: #f3f3f4;
    position: relative;
}

.nolvl .dash-progressbar-inside {
    position: relative;
    height: 100%;
    border-radius: 20px;
    background: none;
}

.nolvl .dash-progressbar-info {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: -20px;
    right: -10%;
    * {
        align-self: center;
    }
}

.dash-check-info {
    text-align: left;
    font-weight: bold;
}

.dash-level-title + div {
    font-size: 14px;
}

.dash-progressbar {
    height: 30px;
    width: 100%;
    border-radius: 20px;
    background-color: #e5fdf7;
    position: relative;
}

.dash-progressbar-inside {
    position: relative;
    height: 100%;
    border-radius: 20px;
    background: rgb(0,233,195);
    background: linear-gradient(90deg, rgba(0,233,195,1) 0%, rgba(132,244,216,1) 100%);
}

.dash-progressbar-info {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    min-width: 170px;
    top: -60px;
    // right: calc(0% - 60px);
    * {
        align-self: center;
    }
}

.dash-progresspicto-end {
    position: absolute;
    right: 0;
    top: -12px;
}

.dash-euro-start {
    position: absolute;
    top: 150%;
    font-size: 12px;
    left: 5px;
}

.dash-euro-end {
    position: absolute;
    top: 150%;
    font-size: 12px;
    right: 5px;
}

.dash-lvl-botright {
    position: absolute;
    right: 0;
    top: 200%;
    text-align: right;
}

.dash-lvl-botleft {
    position: absolute;
    left: 0;
    top: 200%;
}

.dash-progresspicto-lvl {
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 50px;
}

.dash-project-closed {
    display: none;
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    width: 1160px;
    margin-left: auto;
    margin-right: auto;
}

.dash-progressbar-info.left {
    justify-content: left;
    text-align: left;
    left: 0;
}

.dash-progressbar-info.left .text-center {
    align-self: auto;
    text-align: left !important;
}

.dash-progressbar-info.left * {
    align-self: flex-start;
}

.dash-progressbar-info.right {
    justify-content: right;
    text-align: right;
    right: 0;
}

.dash-progressbar-info.right .text-center {
    align-self: auto;
    text-align: right !important;
}

.dash-progressbar-info.right * {
    align-self: flex-right;
}

.project-acceptance {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item.project-invitation {
    background-color: white;
}

/*V3 Accueil*/
.dash-top-bar {
    position: absolute;
    width: 100%;
    left: 0;
    top: -110px;
    margin-top: 0;
    height: 75px;
    background: $cprimary-12;
}

.dash-topbar-text {
    width: 1140px;
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
}

.hide-topbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

// .block-project:nth-child(3n) {
//     margin-right: 0;
// }

.dash-needinfo {
    margin-top: 20px;
    padding: 40px;
    border-radius: 4px;
    background: $cwhite;
    .dash-needinfo-incomplete {
        padding: 20px;
        border: dashed 1px #e1280c;
        background-color: #fbdfdb;
        color: #e1280c;
        border-radius: 4px;
        margin-bottom: 20px;
        text-align: center;
    }
}

.project-page .swal-icon--warning {
    display: none;
}

/*New design page project*/
.st-home-dashboard-container {
    width: 1160px;
    margin: auto;
    padding-bottom: 20px;
    h1 {
        font-size: 44px;
        font-weight: 700;
    }
    h3 {
        font-size: 18px;
    }
    h3.st-dossier-title {
        margin-top: 220px;
    }
    .st-shortcuts-submenu {
        box-shadow: 0px 6px 12px #00000029;
        bottom: 100%;
        left: -120px;
        background-color: white;
        min-width: 200px;
        a {
            &:hover {
                background-color: $csecondary-1;
                font-weight: 700;
            }
        }
    }
    .new-project-box {
        margin-bottom: 30px;
    }
    .st-plusButton-submenu-container {
        position: fixed;
        bottom: 35px;
        z-index: 10;
    }
    .st-plusButton {
        height: 75px;
        width: 75px;
    }
    .st-home-main-item {
        position: sticky;
        top: 50px;
        background: white;
        z-index: 10;
        padding: 16px;
        padding-top: 40px;
    }
    .active-menu-item-dashboard {
        border-bottom: solid 2px $cprimary-7;
        font-weight: 700 !important;
    }
}
// Array Dashboard 

.blocArrayDashboard {
    background-color: white;
    margin-bottom: 50px;
    & .js-servicesSoan {
        margin-top: auto;
        margin-bottom: auto;
    }
    & #js-servicesSoan {
        background-image: url("/build/images/small-down-darkBlue.svg");
        width: 210px;
        margin-right: 57px;
    }
    & [class^="js-"]:not(input) {
        cursor: initial;
    }

    & p {
        margin-top: auto;
        margin-bottom: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & .st-userName p:first-child {
        max-width: 70%;
    }

    & .st-arrayDashboard {
        width: 1160px;
        margin: auto;
        
        & .st-tabHeader {
            margin-bottom: 20px;
            margin-left: 0;
        }
        & #js-sortBills, #js-sortActions {
            border: 0;
            color: $csecondary-4;
        }
        & .js-search-dashboard {
            border: 0;
        }
        & .st-searchDashboard {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 40px;
            & img {
                margin-left: -45px;
            }
        }
        & .js-reduceDashboard {
            background-color: white;
            color: $cprimary-7;
            border: 0;
            font-weight: bold;
            margin-right: 15px;
            display: none;
        }
        & .js-sortBills {
            //text-align: left;
            margin-top: auto;
            margin-bottom: auto;
        }
        & .js-sortActions {
            //text-align: left;
            margin-top: auto;
            margin-bottom: auto;
        }
        & .st-paymentName {
            width: 20%;
            //text-align: left;
            margin-top: auto;
            margin-bottom: auto;
            font-weight: bold;
            //cursor: pointer;
        }
        & .st-paymentName p {
            font-size: 14px;
        }
        & .js-divReduceDashboard {
            width: 50%;
            text-align: right;
            margin-top: auto;
            margin-bottom: auto;
        }
        & #js-sortBills, .js-reduceDashboard {
            cursor: pointer;
        }
        & #js-sortBills {
            padding: 0 40px 0 0;
        }
        & .st-paymentPrice {
            //cursor: pointer;
            width: 30%;
            justify-content: space-between;
            margin: auto;
            height: 80px;
            & div:nth-child(1) {
                margin-top: auto;
                margin-bottom: auto;
            }
            & p, del {
                font-weight: bold;
                width: 130px;
                text-align: right;
                display: block;
            }
            & .st-statePayment {
                margin-top: auto;
                margin-bottom: auto;
                & div:nth-child(2) img {
                    height: initial;
                    width: 80px;
                }
            }
            & .st-statePayment p {
                margin: auto;
                width: 200px;
                font-style: italic;
                font-weight: normal;
                font-size: 13px;
                text-align: left;
            }
            & .st-escompteDashboard {
                color: $cprimary-7;
                width: 130px;
                font-size: 14px;
                margin-bottom: 0;
                margin-top: 0;
            }
            & .st-labelEscompteDashboard img {
                width: 68px;
                height: 14px;
                margin-right: 10px;
            }
            & .st-statePayment img {
                margin-right: 16px;
                margin-left: 100px;
                margin-top: auto;
                margin-bottom: auto;
                height: 15px;
                width: 15px;
            }
            & .st-amountPrice {
                font-size: 13px;
                position: relative;
                & .st-barredPrice {
                    position: absolute;
                    height: 1px;
                    background-color: $cprimary-12;
                    width: 100px;
                    margin-left: 30px;
                    margin-top: -9px;
                    display: block;
                }
            }
        }
        & .st-rowDashboard:nth-of-type(odd) {
            background-color: $csecondary-1;
            border-radius: 8px;
            height: 80px;
        }
        & .st-rowDashboard:nth-of-type(even) {
            background-color: white;
            height: 80px;
        }
        & .st-servicesSoan {
            width: 25%;
        }
        & .st-userName {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            width: 25%;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 20px;
            & p {
                margin-left: 24px;
                font-weight: bold;
                font-size: 14px;
                & span {
                    font-size: 12px;
                    font-weight: normal;
                }
            }
        }
        & .st-paymentAction, .st-divBottomRight {
            width: 25%;
            justify-content: flex-end;
            & .actionButtonToPay {
                background-color: $cprimary-7;
                border-radius: 100px;
                color: #F5F5F5;
                margin-right: 15px;
                margin-left: 40px;
                margin-top: auto;
                height: 32px;
                width: 100px;
                text-align: center;
                line-height: 32px;
            }
            & .actionButtonToPay.js-openpopin-bank, .goToSupplierPayment {
                margin-right: 30px;
            }
            & .actionButtonToSign {
                background-color: transparent;
                //box-shadow: 0px 6px 12px #00000029;
                border-radius: 100px;
                border: 2px solid $cprimary-7;
                color: $cprimary-7;
                font-weight: bold;
                margin-right: 30px;
                margin-left: 40px;
                margin-top: auto;
                height: 32px;
                width: 100px;
                text-align: center;
                line-height: 32px;
            }
            & a {
                margin-top: auto;
                margin-bottom: auto;
                color: $cprimary-7;
            }
            & .st-to-complete, .actionButtonToPayNow {
                border: 1px solid $cprimary-12;
                border-radius: 40px;
                color: $cprimary-12;
                font-size: 14px;
                font-weight: bold;
                height: 32px;
                line-height: 32px;
                margin-right: 30px;
                margin-left: 40px;
                margin-top: auto;
                text-align: center;
            }
            & .st-to-complete {
                margin-right: 15px;
            }
            & .actionButtonToPayNow {
                margin-right: 15px;
                width: 142px;
            }
            & .st-to-complete {
                width: 118px;
            }
        }
        
    }
    & .st-showMore {
        background-color: white;
        color: $cprimary-7;
        border: 0;
        font-weight: bold;
        display: none;
        margin: 40px auto;
    }
    & .st-showMoreTop {
        background-color: white;
        color: $cprimary-7;
        border: 0;
        font-weight: bold;
        margin-right: 15px;
    }
    & .js-reduceDashboardMobile {
        background-color: white;
        color: $cprimary-7;
        border: 0;
        font-weight: bold;      
        display: none;
        width: 100px;
        cursor: pointer !important;
        text-align: left;
        margin-left: 25px;
        background-image: url("../images/icon_dropdown_green_smallUp.svg");
        background-repeat: no-repeat;
        background-position-x: 0px;
        background-position-y: center;
        padding-left: 12px;
    }
    & .st-divMarkAsPayed {
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
        cursor: pointer;
        & .st-points {
            background-color: $cprimary-12;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            margin-top: 3px;
            margin-bottom: 3px;
        }
        & .st-pMarkAsPayed {
            background-color: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 4px 4px 0 0;
            color: #aaabaf;
            cursor: pointer;
            font-size: 14px;
            padding: 10px;
            position: absolute;
            right: 0px;
            text-align: center;
            top: 10px;
            width: 190px;
            z-index: 2;
            &:hover {
                color: $cprimary-7;
            }
        }
        & .st-pCopyPaymentLink {
            border-radius: 0 0 4px 4px;
            top: 53px;
        }
        & .st-pCopyPaymentLinkUnpayed {
            border-radius: 0 0 4px 4px;
            top: 74px;
        }
        & .st-pCopyPaymentLinkSupplier {
            top: 74px;
        }

    }
    & .st-emptyMessage {
        background-color: $csecondary-1;
        height: 80px;
        border-radius: 8px;
        text-align: center;
        line-height: 80px;
    }
    & .st-showRecovery {
        display: inline;
    }

    .st-escompteLabelCustom {
        background-color: $cprimary-5;
        color: white;
        font-family: 'Roboto', sans-serif;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        padding-left: 7px;
        font-size: 10px;
        font-weight: 600 !important;
        text-align: initial !important;
        width: 62px !important;
        margin-left: 58px;
        margin-top: 4px !important; 
    }

    .st-3xLabelCustom {
        background-color: $cprimary-11;
        color: white;
        font-family: 'Roboto', sans-serif;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        padding-left: 7px;
        font-size: 10px !important;
        font-weight: 600 !important;
        text-align: initial !important;
        width: 76px !important;
        margin-left: 100px !important;
        margin-top: 4px !important;
    }

    .st-3xLabelCustom:lang(es) {
        background-color: #002e7a;
        color: white;
        font-family: 'Roboto', sans-serif;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        padding-left: 7px;
        font-size: 10px !important;
        font-weight: 600 !important;
        text-align: initial !important;
        width: 98px !important;
        margin-left: 100px !important;
        margin-top: 4px !important;
    }
}

.st-operationAction {
    display: none;
}

.st-blocDashNewPayment {
    padding: 0 14px;
}

.redirectToPay {
    background-color: $cprimary-7;
    border-radius: 100px;
    color: #F5F5F5;
    margin-right: 15px;
    margin-left: 40px;
    margin-top: 18px;
    height: 44px;
    width: 100px !important;
    text-align: center;
    font-weight: normal;
}

// .dash-project-favorite {
//     display: none;
// }

// Notification Bell

.st-blocNotificationBell {
    position: relative;
}

.st-notificationBell {
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 3px 3px 3px #00000033;
    color: #000000;
    height: 300px;
    overflow-wrap: break-word;
    overflow-y: scroll;
    position: absolute;
    right: -70px;
    top: 50px;
    width: 350px;
    z-index: 20;
    & p {
        height: 76px;
        font-size: 13px;
        text-align: left;
        margin-bottom: 0px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
    }
    & p:hover {
        background-color: $csecondary-1;
    }
}
.st-notificationBell::-webkit-scrollbar {
    background: transparent;
    width: 0;
}

.st-promotion-banner-discount {
    & div:nth-child(1) {
        & p:nth-child(2) {
            margin-left: 34px !important;
            font-size: 14px;
        }
    }
    & .js-deleteDiscountedNotification {
        cursor: pointer !important;
    }
}

.js-total-turnover {
    margin-right: 5px;
}

.st-folderFavoriteDashboard {
    margin-bottom: -20px;
}