.archi-modifinfo {
    .account-content h1 {
        text-align: left !important;
        margin: 20px 20px 20px 0 !important;
    }
    .account-avatar {
        margin: 30px 0;
    }
    .icon-pen.js-openpopin {
        display: inline-block;
    }
    #change_password .form-group {
        width: 100%;
    }
    
    #change_password button {
        margin-left: auto;
        width: auto;
    }

    .archi-modifinfo-title {
        text-align: left !important;
        margin: 20px 20px 20px 0 !important;

    }
    .account-avatar .icon-pen.js-openpopin {
        display: inline-block;
    }
    .history-wallet {
        display: none;
    }
    #change_infos_submit, #mangopay_infos_submit {
        width: auto;
        padding: 10px 100px;
        margin-left: auto;
    }
    hr {
        background: #fff;
    }
    input {
        color: #fff;
    }
    .js-openwallet-history {
        margin-top: 10px;
        text-align: right;
    }
    .history-wallet span.js-back-wallet {
        font-size: 56px !important;
    }
}

.subscription-list .subscription {
    width: 100% !important;
    margin: 10px 0;
}

button.btn.btn-primary.js-popin-submit.subscription {
    background: #f2fbf9;
    color: $cprimary-12;
    text-align: left;
}

button.btn.btn-primary.js-popin-submit.subscription.active {
    background: $cprimary-7;
}

button.btn.btn-primary.js-popin-submit.stop-subscription {
    display: block;
    width: 100%;
    text-align: left;
    background: #f2fbf9;
    color: $cprimary-12;
}

.progress-box {
    margin: 10px 0 40px 0;
}

.progress-sstitle {
    font-size: 24px;
    margin: 5px 0;
}

.progressbar {
    height: 20px;
    width: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.15);
}

.progressbar-grey{
    height: 20px;
    width: 100%;
    display: flex;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.15);
}

.progressbar-start {
    border-radius: 20px 0 0 20px;
}

.progressbar-step {
    width: 2px;
    background: #000;
}

.progressbar-inside {
    height: 100%;
    border-radius: 20px;
}

.bottom-menu {
    // position: absolute;
    bottom: 35px;
    color: white;
    padding: 15px
}

.account-page .simplebar-content {
    height: 100%;
}


button#change_infos_user_submit {
    margin-left: auto;
    display: block;
    width: 200px;
}

.submit-wallet-info .form-group {
    margin-right: 0 !important;
}

.form-wallet > div > .form-group:first-child {
    width: 50%;
    margin-right: 10px;
}

.form-wallet > div > .form-group:last-child {
    width: 50%;
    margin-left: 10px;
}

.form-wallet select, .form-wallet input {
    height: 50px !important;
}

.form-wallet > div > .form-group .col-form-label {
    padding-top: 0 !important;
}

.form-wallet form > div > .form-group:first-child {
    width: 50%;
    margin-right: 10px;
}

.form-wallet form > div > .form-group:last-child {
    width: 50%;
    margin-left: 10px;
}

.form-wallet form > div > .form-group .col-form-label {
    padding-top: 0 !important;
}

.popin-actions .stop-subscription {
    width: 100%;
    color: $cprimary-12;
    background: #f3f3f4;
}

.subscription {
    background-color: #f2fbf9 !important;
    text-align: left;
    color: $cprimary-12 !important;
}

.subscription.active {
    background-color: $cprimary-7 !important;
    text-align: left;
    color: #fff !important;
}

.stop-subscription {
    background: #ccc !important;
}

.form-wallet {
    width: 100%;
}

.kyc-second-step .kyc-row label {
    //display: none;
}

.kyc-title + .form-group > label {
    display: none;
}

.kyc-title {
    font-weight: bold;
    font-size: 22px;
    z-index: 99;
}

.kyc-shareholder .kyc-title {
    position: static;
}

.kyc-row {
    //border-bottom: 1px solid rgba(#fff, 0.2);
    //margin-bottom: 20px;
    min-height: 80px;
}

.kyc-bottom-line {
    border-bottom: 1px solid #e8e9ea;
    padding-bottom: 50px;
}

.kyc-row .kyc-title + .corange {
    padding-top: 30px;
}

.kyc-row .kyc-title + .cerror {
    padding-top: 30px;
}

.kyc-row .kyc-title + i {
    display: block;
    width: 60%;
}

.kyc-row .kyc-title + .cprimary-7 {
    padding-top: 30px;
}

.kyc-row .form-group {
    margin-bottom: 0;
}

.kyc-row .custom-file input {
    font-size: 16px;
    font-weight: normal;
    width: 30%;
}

.kyc-row .custom-file input + label {
    background: transparent;
    border: 0;
    display: inline-block;
    margin: 0;
    color: $cwhite;
    min-height: 80px;
    padding-left: 0;
    text-align: center;
}

.kyc-row .custom-file input + label:after {
    border: 1px solid $cprimary-7;
    border-radius: 25px;
    background: transparent;
    height: 45px;
    line-height: 30px;
    text-align: center;
    color: $cprimary-7;
    width: 200px;
    content: "Choisir un fichier";
    font-size: 16px;
    cursor: pointer;
}

.kyc-row .form-group > label {
    margin-top: 15px;
}

// .kyc-row .form-group > label {
//     visibility: hidden;
// }

// .kyc-row.kyc-registration .form-group > label {
//     visibility: hidden;
// }

.kyc-second-step button#form_envoyer {
    width: 200px;
}

.kyc-text-validation {
    padding-top: 30px;
}

.promoform label {
    display: none;
}

.promoform {
    border: 2px solid $cprimary-7;
    padding: 20px;
    border-radius: 4px;
}

.promoform input {
    width: calc(25% - 12px);
    margin-left: auto;
}

.promoform button[type="submit"] {
    //display: none;
}

/*Popin*/
.swal-content {
    text-align: justify;
    color: $cprimary-12;
}

/*Pack signature*/
.pack-container {
    display: flex;
    margin: 20px 0;
}

.pack-content {
    width: 20%;
    text-align: center;
}

.pack-content > label {
    display: block;
}

.pack-sign {
    font-weight: bold;
}

.pack-reveal {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 10px 0;
}

.pack-content.active .pack-sign {
    color: $cprimary-7;
}

.pack-content.active .pack-price {
    color: $cprimary-7;
}

/*Wallet UBO*/
.popin-ubo {
    display: none;
    &.active {
        display: block;
    }
}

section.archi-modifinfo.hide {
    display: none;
}

.inside-w50 .form-group {
    width: 49%;
}

select#new_ubo_thBirthday_day {
    margin-left: 0;
}
.popin-ubo .archi-modifinfo-title {
    text-align: left !important;
    margin-left: 0 !important;
}
.popin-ubo hr {
    background: #fff;
}
.popin-ubo .archi-modifinfo-title .js-back-ubo {
    font-size: 56px !important;
}

.custom-file-input:focus~.custom-file-label {
    border-color: transparent;
    box-shadow: none;
}

.kyc-shareholder {
    border-bottom: 1px solid #e8e9ea!important;
    margin-bottom: 40px;
    padding-bottom: 40px;
}

select#mangopay_infos_thBirthday_day {
    margin-left: 0;
}

.new-ubo input {
    color: #fff;
}

.ubo-beneficiary {
    border: 1px solid #e8e9ea;
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0 5px 0;
    display: flex;
}

.ubo-declaration {
    width: 200px;
}

.js-open-ubo {
    color: #fff;
    &:hover {
        color: #fff;
    }
}

.autocomplete-false {
    cursor: pointer;
}

select#company_infos_thLRBirthday_day, select#company_infos_thLRBirthday_month, select#company_infos_thLRBirthday_year, select#change_infos_thBirthday_day, select#change_infos_thBirthday_month, select#change_infos_thBirthday_year {
    margin-left: 0;
    cursor: pointer;
}

//modif password
.modif-password-submit {
    margin-top: 2.8rem;
}

//mes info
.account-avatar .btn-unfilled-primary {
    white-space: nowrap;
}

.disconnect {
    text-align: center;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 15px;
}

.js-accountBack {
    margin-top: 4em;
}

.bank {
    border-radius: 4px;
    margin: 5px 0 5px 0;
    display: flex;
    margin: 10px 0 5px 0;
    max-width: 800px;
    padding: 15px;
}
.bank-details {
    cursor: pointer;
}
.bank.st-default-bank {
    background: $cprimary-11;
    border: 1px solid $cprimary-5;
}
.bank.bank-not-complete {
    border: 1px solid  $corange;
}
.bank:hover .st-bank-trash {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
.st-default-bank-text {
    font-size: 20px;
}
.st-bank-trash {
    height: 32px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 28px;
}

.bank-active {
    border: 1px solid  $cprimary-7;
}

.bank-failed {
    border: 1px solid  $cred;
}

.account-page input[type=search] {
    color: #000;
}

section.change-password {
    max-width: 450px;
}

// .account-info {

    .info-modify-form {
        display: none;
    }

    .banner-profil {
        background: $cprimary-11;
        border-radius: 6px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
        max-width: 700px;
        padding: 10px;
    }

    .company-input-container .input-group-append {
        margin-top: 6px;
    }

    .banner-content {
        padding: 15px;
    }

    .banner-btn {
        background: $cwhite;
        color: $cprimary-7;
        padding: 5px 10px;
        font-weight: bold;
        display: inline-block;
        border-radius: 20px;
        margin-top: 15px;
        text-transform: uppercase;
    }

    .banner-grey-btn {
        background: transparent;
        color: $corange;
        padding: 5px 10px;
        font-weight: bold;
        display: inline-block;
        border-radius: 20px;
        margin-top: 15px;
        font-style: italic;
        text-transform: uppercase;
    }

    .info-button {
        text-align: right;
        margin-top: 20px;
        max-width: 700px;
    }

    .banner-company {
        border: 1px solid $cprimary-7;
        position: relative;
        overflow: hidden;
        max-width: 700px;
        border-radius: 6px;
        margin-bottom: 30px;
    }
// }

section.account-without-menu {
    text-align: center;
    margin: 0 auto;
    max-width: 450px; 
}

section.account-without-menu-tall {
    text-align: center;
    margin: 0 auto;
    max-width: 700px; 
}

.big-button {
    border: 1px solid $cprimary-7;
    border-radius: 100px;
    padding: 30px;
    cursor: pointer;
    margin-bottom: 30px;
    color: $cwhite !important;
    display: block;
    &:hover {
        background: $cprimary-7;
        color: $cwhite !important;
    }
}

.info-particular-form {
    text-align: left;
    input, select {
        color: $cwhite !important;
        background-color: transparent !important;
        border: 1px solid #d4d4d4 !important;
    }
}

.thstatus-name {
    color: $cwhite !important;
    background-color: transparent !important;
    border: 1px solid #d4d4d4 !important; 
}

//Step account create Company

.step-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $csecondary-4;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    margin-top: 17.5px;
    margin-bottom: 17.5px;
    &.active {
        border: 2px solid $cprimary-7;
        height: 50px;
        width: 50px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.step3 {
    position: relative;
}

.step-before {
    height: 2px;
    width: 200px;
    background: $csecondary-4;
    &.active {
        background: $cprimary-7;
    }
}

.step-after {
    height: 2px;
    width: 200px;
    background: $csecondary-4;
    &.active {
        background: $cprimary-7;
    }
}

.step-circle img {
    display: none;
}

.step-circle.active img {
    display: inline-block;
}

.step-circle.active.past {
    position: relative;
    background: $cprimary-7;
    &:after {
        content: url('../../images/icon_round_fill_check.svg');
        position: absolute;
        height: 25px;
        width: 25px;
    }
    img {
        display: none;
    }
}

.step1.active {
    color: $cprimary-7;
    font-weight: bold;
}

.step2.active {
    color: $cprimary-7;
    font-weight: bold;
}

.step3.active {
    color: $cprimary-7;
    font-weight: bold;
}

.company-block-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.companyinfos-form {
    display: none;
}

.left-info {
    color: $csecondary-4;
    width: 200px;
}

.companyinfos-banner {
    padding: 20px;
    background: $cprimary-11;
    border-radius: 8px;
    max-width: 450px;
}

.company-step2 {
    display: none;
    transition: all 1s;
    &:active {
        display: block;
    }
}

.company-step3 {
    display: none;
    transition: all 1s;
    &:active {
        display: block;
    }
}

.company-block {
    border-radius: 8px;
    padding: 30px;
    background: $cprimary-11;
    margin-top: 30px;
}

.company-block-form {
    input, select {
        color: $cwhite !important;
        border: 1px solid $cwhite !important;
        background: transparent !important;
    }
}

.company-step1, .company-step2, .company-step3 {
    display: none;
    transition: all 1s;
    &.active {
        display: block;
    }
}

.company-input-container {
    margin-top: 1em;
    text-align: left;
}
.double-input {
    .company-input-container {
        width: 49%;
        &:first-child {
            margin-right: 1%;
        }
        &:last-child {
            margin-left: 1%;
        }
        .double-no-mt {
            margin-top: 0 !important;
        }
        .input-group-append {
            margin-top: 0;
        }
    }
}

.banner-wallet {
    background: $cprimary-11;
    border-radius: 8px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    max-width: 500px;
    .banner-img {
        align-items: center;
        display: flex;
        margin-left: 50px;
    }
}

// .banner-img {

// }

.banner-btn {
    background: $cwhite;
    color: $cprimary-7;
    padding: 5px 10px;
    font-weight: bold;
    display: inline-block;
    border-radius: 20px;
    margin-top: 15px;
    text-transform: uppercase;
}

.banner-grey-btn {
    background: transparent;
    color: $corange;
    padding: 5px 10px;
    font-weight: bold;
    display: inline-block;
    border-radius: 20px;
    margin-top: 15px;
    font-style: italic;
    text-transform: uppercase;
}

.step-company-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em;
}

.step-company-content {
    display: flex;
    align-items: center;
}

.step-company-item {
    position: relative;
}

.step-company-1 {
    .step-text {
        position: absolute;
        left: 0;
        text-transform: uppercase;
    }
    &.active {
        .step-text {
            color: $cprimary-7;
            font-weight: bold;
        }
    }
}

.step-company-2 {
    .step-text {
        position: absolute;
        left: -45px;
        text-transform: uppercase;
        color:$csecondary-4;
    }
    &.active {
        .step-text {
            color: $cprimary-7;
            font-weight: bold;
            left: -25px;
        }
    }
}

.step-company-3 {
    .step-text {
        position: absolute;
        right: 0;
        text-transform: uppercase;
        color:$csecondary-4;
    }
    &.active {
        .step-text {
            color: $cprimary-7;
            font-weight: bold;
        }
    }
}

.step-company-2, .step-company-3 {
    &.active {
        display: block;
    }
}

.submit-wallet-info {
    max-width: 600px;
    margin-left: auto;
}

#change_infos_reset {
    padding: 10px 50px;
}

button#add_bank_user_submit {
    margin-left: auto;
    width: auto;
}

.edit-info {
    display: none;
}

.used-name-info {
    position: absolute;
    top: 0px;
    left: 150px;
}

.st-blocRadioButton-White {
    color: $cwhite;
    input[type="radio"].st-paymentsOffline {
        display: none;
    }
    
    input[type="radio"].st-paymentsOffline + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        border: 2px solid $cwhite;
        border-radius: 50%;
        background: $cprimary-12;
    }
    
    input[type="radio"].st-paymentsOffline:checked + label:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        width: 7px;
        height: 7px;
        border: 2px solid $cwhite;
        border-radius: 50%;
        background: $cwhite;
        transition: none;
    }

    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {

        position: relative;
        padding-left: 20px;
        height: 5px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: $cwhite;
    }
    
    input[type="radio"].st-paymentsOffline:checked + label, input[type="radio"].st-paymentsOffline + label {
        line-height: 14px;
        margin-right: 15px;
    }
}

.st-statusES, .st-OtherActif {
    display: none;
}

.company-step1 a {
    color: $cwhite;
}