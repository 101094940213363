.st-kycChooseFileButton {
    border: 1px solid $cprimary-7;
    border-radius: 25px;
    background: transparent;
    height: 45px;
    line-height: 30px;
    color: $cprimary-7;
    width: 200px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.st-kycChooseFileTitle {
    font-size: 22px;
    font-weight: bold;
}

.st-kycChooseFileInput {
    border: transparent;
}