.st-confirmDeleteContainer {
    .swal-modal {
        width: 536px;
        height: auto;
    }
    
    .swal-title {
        color: #212529;
    }
    
    .swal-footer {
        flex-direction: row-reverse;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        & .swal-button-container:nth-child(2) button {
            background-color: #E1280B;
            width: auto;
            height: 42px;
            border-radius: 22px;
            padding: 0 15px;
        }
        & .swal-button-container:nth-child(1) button {
            background-color: transparent;
            border: none;
            color: $cprimary-7;
        }
    }
    
    .swal-icon--warning {
        border-color: #E1280B !important;
    }
    
    .swal-content {
        text-align: center;
    }
    
    .swal-icon--warning__body, .swal-icon--warning__dot {
        background-color: #E1280B;
    }

    
    .link-warning-before-text {
        text-align: center;
        & p {
            color: #212529;
        }
    }
    
    .st-confirmDeleteTitle {
        font-size: 25px;
        font-weight: bold;
    }
    
    .st-iconDelete {
        margin-bottom: 30px;
    }    
}
