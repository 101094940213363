a{
    color: $cprimary-12;
    &:hover {
        color: $cprimary-10;
    }
}

input {
    height: 45px;
    border-radius: 4px;
    border: 1px inset $csecondary-2;
    color: $cprimary-12;
}

.input-group img {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
}

input[type=checkbox].switch{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switch + label {
	cursor: pointer;
	text-indent: -9999px;
	width: 70px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switch + label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 20px;
	height: 20px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.35);
	border-radius: 90px;
	transition: 0.3s;
}

input.switch:checked + label {
	background: $cprimary-7;
}

input.switch:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.switch + label:active:after {
	width: 30px;
}

.bg-black {
    .form-wallet {
        input, select {
            color: black !important;
        }
    }
	.form-control {
		border: 0;
		background-color: $cprimary-1;
		outline: 0;
        color: $cprimary-12;
		&:focus {
			border: 0;
			outline: 0;
            background-color: $cprimary-1;
		}
	}
    .popin .popin-newEmail .form-control {
        border: 1px solid $csecondary-1;
        color: $cprimary-12 !important;
    }
}

.swapbtn{
	position: relative;
	display: block;
	width: 40px;
	height: 20px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transform: translate3d(0, 0, 0);
  }
  .swapbtn:before {
	content: "";
	position: relative;
	top: 3px;
	left: 3px;
	width: 34px;
	height: 14px;
	display: block;
	background: #9A9999;
	border-radius: 8px;
	transition: background 0.2s ease;
  }
  .swapbtn span {
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	display: block;
	background: white;
	border-radius: 10px;
	box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
	transition: all 0.2s ease;
  }
  .swapbtn span:before {
	content: "";
	position: absolute;
	display: block;
	margin: -18px;
	width: 56px;
	height: 56px;
	background: rgba($cprimary-7, 0.5);
	border-radius: 50%;
	transform: scale(0);
	opacity: 1;
	pointer-events: none;
  }
  
  .swap:checked + .swapbtn:before {
	background: #947ADA;
  }
  .swap:checked + .swapbtn span {
	background: $cprimary-7;
	transform: translateX(20px);
	transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
	box-shadow: 0 3px 8px rgba($cprimary-7, 0.2);
  }
  .swap:checked + .swapbtn span:before {
	transform: scale(1);
	opacity: 0;
	transition: all 0.4s ease;
  }
  /*
 <input type="checkbox" id="cbx" class="swap" style="display:none"/>
  <label for="cbx" class="swapbtn"><span></span></label>    
  */
  $checked-color: $cprimary-7;
  $unchecked-color: $csecondary-2;
  $checkbox-height: 20px;
  $background-color:#667788;
  $font-color:#efefef;
  $duration: .4s;
  
@-moz-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@-webkit-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2;  }
}

@keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}
@-webkit-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}
@-moz-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.2; }
}

input[type=checkbox].form-check-input{
    display:none;
}

.form-check {
    padding-left: 0;
}

.form-check-label {
    cursor: pointer;
}


.lbp-page {
    input[type=checkbox] + .form-check-label {
        &::before, &::after {
            background-color: $clbp;

        }
    }
    input[type=checkbox]:checked + .form-check-label,
    input[type=checkbox] + .form-check-label.checked {
        border-color: $clbp;
    }
}
input[type=checkbox] + .form-check-label {
    height: $checkbox-height;
    width: $checkbox-height;
    background-color: transparent;
    border: $checkbox-height * .1 solid $unchecked-color;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease $duration/2;
    -o-transition: border-color ease $duration/2;
    -webkit-transition: border-color ease $duration/2;
    transition: border-color ease $duration/2;
    cursor:pointer;

    &::before, &::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: $checkbox-height * .2;
        background-color: $cprimary-7;
        display: inline-block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        -webkit-transition: opacity ease .5;
        -moz-transition: opacity ease .5;
        transition: opacity ease .5;
    }

    &::before {
        top:$checkbox-height * .72;
        left: $checkbox-height * .41;
        //box-shadow: 0 0 0 $checkbox-height * .05 $background-color;
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }

    &::after {
        top: $checkbox-height * .37;
        left: $checkbox-height * .05;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

.st-containerCheckboxAutoComplete {
  align-items: flex-start;
  width: 100%;
  margin: 20px auto auto auto;

}

.st-labelTxtCheckBox{
  margin-left: 20px;
}


input[type=checkbox]:checked + .form-check-label,
input[type=checkbox] + .form-check-label.checked{
    border-color:$cprimary-7;
    
    &::after{
        height: $checkbox-height * .5;
        -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
        -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
        animation: dothabottomcheck $duration/2 ease 0s forwards;
    }
     
    &::before{
        height: $checkbox-height * 1.2;
        -moz-animation: dothatopcheck $duration ease 0s forwards;
        -o-animation: dothatopcheck $duration ease 0s forwards;
        -webkit-animation: dothatopcheck $duration ease 0s forwards;
        animation: dothatopcheck $duration ease 0s forwards;
    }
}
  /*
  <input type="checkbox" id="cbtest" class="checkbox-input" />
    <label for="cbtest" class="checkbox"></label>  
  */

  .form-inline select {
    margin: 0 10px;
}
.submit-right {
    text-align: right;
}
.submit-right button[type='submit']{
    width: auto;
    min-width: 240px;
    display: inline-block;
}

input[type=radio] {
    height: auto;
}

input#th_meeting_choosing_date_thSelectedDate_placeholder {
    display: none;
}

input#th_meeting_choosing_date_thSelectedDate_placeholder + label {
    display: none;
}

/*Radio input css custom*/
/*
** usage
**  <input type="radio" id="test1" name="radio-name">
**  <label for="test1">Apple</label>
*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 20px;
    height: 5px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $cprimary-12;
}

[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $cprimary-12;
    border-radius: 50%;
    background: #fff;
}
[type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $cprimary-7;
    border-radius: 50%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $cprimary-7;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/*Hide errors in label fields Displayed by default in bootstrap 4 layout*/
label > .invalid-feedback {
    display: none !important;
}
legend > .invalid-feedback {
    display: none !important;
}

select:not(.form-control) {
    border-radius: 100px;
    border: solid 1px $cprimary-12;
    padding: 6px 40px 6px 15px;
    font-weight: bold;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: transparent;
    background-image: url("../images/icon_dropdown_grey.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    // background-position-y: 5px;
    background-position-y: center;
}

select.select-noborder {
    border: none;
    padding: 6px 40px 6px 15px;
    font-weight: bold;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: $csecondary-4;
    appearance: none;
    background: transparent;
    background-image: url('../images/icon_dropdown_grey.svg');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 5px;
}


.bg-black {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $csecondary-3 !important;
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $csecondary-3 !important;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $csecondary-3 !important;
    }

    .popin {
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #cccccc !important;
            opacity: 1; /* Firefox */
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #cccccc !important;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: #cccccc !important;
        }
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cccccc !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #cccccc !important;
}

.form-check label > .invalid-feedback {
    display: block !important;
}

input.hiddeninput {
    border: 0;
    background: transparent;
}

.popin-wrap.popin-add-element input {
    background: #fff;
}

.popin-wrap.popin-add-element input.error {
    border-color: $cred !important;
}

span.input-group-text {
    background: #fff;
}

input.error {
    border-color: $cred !important;
}

.new-sent-email {
    min-height: calc(1.5em + .75rem + 2px);
}

.error-maxipopin > div {
    background: $cred;
    color: $cwhite;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}

.bg-black a.paginate_button {
    color: white;
}

[class^="step-content-"] {
    width: 500px;
    .steps-info {
        justify-content: start;
    }
    // input, select, textarea {
    //     width: 100%;
    // }
    .step-title {
        font-weight: bold;
        font-size: 24px;
    }
    .step-step {
        margin-left: 20px;
        font-style: italic;
    }
}
.step-footer {
    justify-content: flex-end;
    .next-step {
        display: flex;
        margin-top: 20px;
        align-items: center;
    }
}

.st-input-calendar-logo {
    width: 30px;
    height: 30px; 
    right: 0;
    top: 0;
}

.company-step2 input, .company-step3 input {
    border: 1px solid #fff !important;
}

.popin-wrap.popin-add-folder input,
.popin-wrap.popin-renamefolder input, .popin-wrap.renamedoc input, .popin-wrap.popin-contact input,
.popin-wrap.popin-payment-noiban input {
    border: 1px solid $csecondary-4;
}

.popin-wrap.popin-contact input, .popin-wrap.popin-contact textarea {
    background-color: white;
}

.popin-wrap.popin-payment-noiban select, .st-selectStyleCountry {
    border-radius: 3px !important;
    height: 44px;
    border: solid 1px $cprimary-12 !important;
    background-image: url(/build/images/icon_dropdown_grey.9c0ce191.svg) !important;
    background-repeat: no-repeat !important;
    background-position-x: calc(100% - 10px) !important;
    background-position-y: center !important;
    background-color: white !important;
}

[id^=new_ubo_th] {
    color: black !important;
}

.new_ubo_thBirthCountry, .new_ubo_thNationality, .new_ubo_thCountry, 
#form_oldPassword, #form_newPassword_first, #form_newPassword_second {
    color: black !important;
}

.a-white{
    color: white;
    &:hover {
        color: rgb(0, 135, 153);
    }
}