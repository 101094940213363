.doc {
    border: solid 2px #e8e9ea;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    width: 145px;
    margin: 10px;
    &:first-child {
        margin-left: 0;
    }
    .doc-header {
        background: #ff6565;
        color: #fff;
        text-align: center;
        font-weight: bold;
        position: absolute;
        z-index:9;
        width: 100%;
        padding: 5px 10px;
    }
    .doc-content {
        // padding: 10px;
        background: #fff;
        height: 250px;
        .doc-view {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            height: 100px;
            cursor: pointer;
            overflow: hidden;
        }
        .doc-text {
            .doc-text-title {
                // white-space: nowrap;
                font-size: 16px;
                // text-overflow: ellipsis;
                padding: 10px;
                height: 70px;
                // overflow: hidden;
                text-align: center;
                border-bottom: 1px solid #e8e9ea;
                font-weight: 600;
            }
            .doc-text-auth {
                font-size: 13px;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .doc-text-sign {
                font-size: 10px;
            }
        }
    }
    &.create {
        .doc-content {
            .doc-view {
                box-shadow: none;
                text-align: center;
                overflow: hidden;
            }
        }
    }
    &.help {
        .doc-content {
            height: 240px;
            .doc-text-title {
                white-space: normal;
                height: auto;
                padding-top: 10px;
            }
        }
    }
    .document-activity{
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 999;
    }
    
}

.doc-footer {
    text-align: right;
    font-weight: bold;
    padding-right: 10px;
}

.waiting-add-btn {
    height: 42px;
    border-radius: 4px;
    display: flex;
    background: rgba(#33F7FF, 0.2);
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-weight: bold;
}

.waiting-add-btn img {
    margin-right: 10px;
}

.add-folder a {
    display: inline-block;
}

.doc-actions {
    font-size: 13px;
    text-align: center;
}

.efacture-list {
    .doc {
        width: 175px;
    }
}