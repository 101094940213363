.activatepopin {
    display: flex;
    position: absolute;
    align-items: center;
    z-index: 999;
    height: 100%;
    justify-content: center;
    width: 100%;
    background: rgba(0,0,0,0.35);
}

.activatepopin-content {
    background: #fff;
    border-radius: 4px;
    width: 570px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    padding: 40px;
    text-align: center;
}

.connectwith {
    margin: 20px 0;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    color: $csecondary-4;
}

.separatortext {
    width: 25%;
    height: 1px;
    background: $csecondary-4;
}

.linkdinconnect, .googleconnect {
    cursor: pointer;
    font-size: 16px;
    height: 44px;
    width: 47%;
    border-radius: 4px;
    border: 1px solid $cprimary-12;
    font-weight: bold;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 10px;
    }
}

// .connectblock {
//     display: none;
// }

.btnlogin {
    border-radius: 4px;
    padding: 5px 30px;
    border: 1px solid $cprimary-7;
    width: 45%;
    color: $cprimary-7;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    &.active {
        background: $cprimary-5;
    }
}

.js-password-show {
    position: absolute;
    right: 10px;
    top: 10px;
    .eye-close {
        display: none;
    }
    &.openeye {
        .eye-close {
            display: block;
        }
        .eye-open {
            display: none;
        }
    }
}

.st-login-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: #00000029 0px 3px 6px;
    height: 439px;
    padding: 30px;
    width: 394px;

    .form-error-message {
        font-size: 10px;
    }
    
    .login-title {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        span {
            font-size: 16px;
        }
    }
    .signin-title {
        margin-bottom: 5px;
    }
    .step-indicator {
        border: $cprimary-12 1px solid;
        border-radius: 100%;
        height: 5px;
        width: 5px;
    }
    .step-indicator.active {
        background-color: $cprimary-12;
    }
    label {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    input {
        border: solid 1px #D4D4D6;
        height: 38px;
        padding: 12px 14px;
        font-size: 13px;
    }

    .mdp-forgot {
        font-size: 13px;
        margin: 6px 0px 14px 0px;
        text-decoration: underline;
    }

    #archi_registration_submit {
        height: 44px;
        font-size: 16px;
    }
}