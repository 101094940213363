.dashed-container {
    border: 2px dashed $cprimary-7;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    max-width: 500px;
    display: flex;
    align-items: center;
}

.event-content {
    padding: 20px;
    border-radius: 8px;
    background: #D9EBFF;
    margin: 10px 0;
    display: inline-block;
}

.info-left {
    color: #AAABAF;
}

.info-right {
    text-align: left;
    width: 200px;
}

.event-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.container-double-info {
    max-width: 500px;
}

[class^='recovery-step-content-'] {
    display: none;
    position: relative;
    &.active {
        display: block;
    }
}

.no-menu .vertical-menu {
    display: none !important;
}

.recovery-step-container {
    .custom-file {
        position: relative;
        top: 0px;
        left: 0;
    }

    .custom-file input {
        font-size: 16px;
        font-weight: normal;
        width: 30%;
        display: none;
    }

    .custom-file input + label {
        background: transparent;
        border: 0;
        display: inline-block;
        margin: 0;
        font-weight: bold;
        cursor: pointer;
        color: $cprimary-7;
        // color: $cwhite;
        min-height: 80px;
        // line-height: 65px;
        padding-left: 0;
        padding-right: 0;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
    }

    .custom-file input + label:after {
        border: 1px solid $cprimary-7;
        border-radius: 100px;
        background: #fff;
        height: 45px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: $cprimary-7;
        width: 200px;
        content: "Choisir un fichier";
        font-size: 16px;
        cursor: pointer;
        display: none;
    }
}

.recovery-amount {
    text-align: left;
    margin-bottom: 15px;
}

.pop-info {
    position: absolute;
    left: 120%;
    top: 30%;
    width: 300px;
    background: $cprimary-10;
    padding: 20px;
    border-radius: 12px;
    color: #fff;
    text-align: left;
}

.recovery-step-step {
    text-transform: uppercase;
    color: $csecondary-4;
    font-size: 13px;
}

.recovery-step-title {
    margin-top: 3em;
    color: $csecondary-4;
}

.document-inline-container {
    display: none;
}

.document-inline {
    // display: none;
    cursor: pointer;
    padding: 10px;
}

.next-menu {
    display: none;
    cursor: pointer;
}

.folder-quotation {
    display: none;
    &.active {
        display: block;      
    }
}

.folder-menu {
    &.active + .document-inline-container {
        display: block;
        .document-inline {
            display: flex;
            align-items: center;
        }   
    }
    &.active {
        > .next-folder {
            > .next-menu {
                display: flex;
                align-items: center;
            }
        }
    }
}

.folder-menu-inline {
    display: flex;
    align-items: center;
}

.next-folder.active > .next-folder .next-menu {
    display: flex;
    align-items: center;
}

.next-folder.active {
    display: block;
}

.next-folder {
    display: none;
}

.next-menu {
    font-weight: bold;
    cursor: pointer;
    &.active + .recursive-folder > .next-folder > .next-menu{
        display: flex;
        align-items: center;
    }
    &.active {
        display: flex;
        align-items: center;
    }
}

.search-doc-autocomplete {
    max-height: 300px;
    overflow: auto;
}

.document-inline.selected {
    border: 1px solid $cprimary-7;
    background: $cprimary-7;
    border-radius: 8px;
    color: $cwhite;
}

.js-delete-file-recovery {
    font-weight: bold;
    font-size: 18px;
}

.contract-container {
    position: relative;
    min-height: 600px;
    margin-right: -200px;
    margin-top: 20px;
    margin-left: -200px;


}
iframe.iframe-sign-reco {
    // display: none;
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 99;
}

.recovery-block-text {
    border-bottom: 1px solid $csecondary-4;
    padding-bottom: 20px;
}

.folder-menu {
    cursor: pointer;
}

.folder-menu.active .folder-menu-inline {
    display: none;
}

.folder-menu-inline {
    padding: 10px;
    margin-bottom: 5px;
    .chevron {
        transform: rotate(-90deg);
    }
    &:hover {
        background: $csecondary-4; 
    }
}

.next-menu {
    padding: 10px;
    margin-bottom: 5px;
    .chevron {
        transform: rotate(-90deg);
    }
    &:hover {
        background: lighten($csecondary-4, 20%); 
    }
    &.active {
        display: none !important;
    }
}

.js-return-link-menu {
    display: inline-flex;
    align-items: center;
    background: lighten($csecondary-4, 20%);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.chevron-left {
    transform: rotate(90deg);
}

.quotation-doc-block {
    border: 2px solid #E8E9EA;
    border-radius: 4px;
    padding: 10px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.popin-add-doc {
    height: 480px;
}

.search-doc-container {
    height: 280px;
}

.next-menu.active + .recursive-folder + .document-inline-container {
    display: block;
    .document-inline {
        display: flex;
        align-items: center;
    }
}


.next-step-link-disabled {
    background: $csecondary-4 !important;
    &:hover {
        background: $csecondary-4 !important;
    }
}

.btn-white-inbluecontainer {
    &:hover {
        background: $cwhite;
        color: $cprimary-7;
    }
}

.recovery-step-content-1 .big-button {
    color: $cprimary-7 !important;
    &:hover {
        color: $cwhite !important;
    }
}

.quotation-filename-ellips {
    text-overflow: ellipsis;
    overflow: hidden;
}

.quotation-td-delete {
    width: 60px !important;
}

.quotation-td-actions {
    width: 150px !important;
}

.quotation-td-client {
    width: 120px !important;
}

.quotaton-td-bills {
    width: 220px !important;
}

.scrollable {
    overflow-x: auto;
    width: 100%;
}

.page-recovery thead th {
    color: $cprimary-12;
}