#header {
    .st-clickMenuDropdown {
        max-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .li-img-profil {
        padding: 0px 20px 0px 0px;
    }

    .login ul li.nav-item a img.help-img {
        max-width: none;
    }

    .logo-title {
        text-align: center;
        height: 100%;
    }

    .logo-img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: transparent;
    }

    .st-iconBell {
        max-width: none;
    }
}

#st-dropdown-menu {
    border-radius: 0 0 8px 8px;
    padding: 15px 0 0;
    position: absolute;
    right: 15px;
    top: 50px;
    z-index: 20;

    .navbar-dropdown-menu-item {
        height: 48px;
        max-height: 48px;
        padding: 10px;
    }
}

.dropdown-black {
    background-color: $cprimary-11;
    a {
        color: #FFFFFF;
        &:hover {
            background-color: $cprimary-10;
            font-weight: 700;
        }
    }
}

.dropdown-white  {
    background-color: #FFFFFF;
    box-shadow: 0 3px 6px #000000B3;
    a {
        color: #000000 !important;
        &:hover {
            font-weight: 700;
            background-color: $csecondary-1;
        }
    }
}