.popin-updateClient {
    .js-showCompta {
        color: $csecondary-3;
    }
    .st-blocContact, .st-blocCompta {
        display: none;
    }
    .st-blocFiscalId {
        input:not(.siret) {
            display: none;
        }
        input {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .js-show-selectStatus, .js-show-selectFiscalId {
        border: 1px solid $csecondary-2;
        border-radius: 3px;
        height: 38px;
        padding: 0 20px;
        width: 206px;
        img {
            width: 24px;
        }
    }
    .js-show-selectFiscalId {
        background-color: $csecondary-1;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 130px;
    }
    .js-selectStatus, .js-selectFiscalId {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 0 10px $csecondary-2;
        font-weight: 600;
        display: none;
        position: absolute;
        top: 80px;
        z-index: 10;
        & p:nth-child(1) {
            color: $csecondary-3;
        }
        & p {
            cursor: pointer;
            margin-bottom: 0;
            padding: 10px 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
    .js-selectStatus {
        width: 206px;
    }
    .js-selectFiscalId {
        right: 0;
        width: 130px;
    }
    .js-show-blocContact {
        border-radius: 6px;
        height: 40px;
        padding: 0 10px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .js-hideBlocContact {
        background: url("/build/images/close_btn_grey.svg") no-repeat;
        height: 20px;
        width: 20px;
        &:hover {
            background: url("/build/images/close_btn_black.svg") no-repeat;
        }
    }
    .js-request-updateClient {
        background-color: $cprimary-12;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 26px;
        width: auto;
        &:hover {
            background-color: $cprimary-11;
        }
    }
    .js-show-blocCompta {
        border-radius: 6px;
        color: $csecondary-3;
        margin-left: 30px;
        padding: 3px 10px;
        &:not(.active):hover {
            background-color: $csecondary-1;
        }
    }
    .js-show-blocContactCompany {
        cursor: initial;
        border-radius: 6px;
        padding: 3px 10px;
        &:not(.active):hover {
            background-color: $csecondary-1;
        }
    }

    .st-selectedTag {
        align-items: center;
        background: #02130E;
        border-radius: 4px;
        color: white;
        display: flex;
        font-size: 14px;
        font-weight: bold;
        height: 24px;
        justify-content: center;
        left: 15px;
        line-height: 14px;
        margin: 0 !important;
        padding: 5px 10px;
        position: absolute;
        top: 38px;
    }
    .js-autocompleteTag {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0 3px 15px #02130E1A;
        display: none;
        left: 0;
        position: absolute;
        top: unset;
        width: 270px;
        z-index: 10;
        .js-tagAuto {
            color: $cprimary-12;
            cursor: pointer;
            font-size: 12px;;
            margin-bottom: 0;
            width: 85%;
            word-break: break-all;
        }
        .tagP {
            align-items: center;
            height: 40px;
            justify-content: space-between;
            line-height: 40px;
            margin-bottom: 0;
            min-height: 40px;
            padding: 0 15px;
            &:hover {
                background-color: $csecondary-1;
            }
        }
        .js-addTag {
            display: block;
            line-height: 40px;
            padding: 0 15px;
            width: 100%;
        }
    }
    .st-deleteTagBlock {
        height: 20px;
        text-align: right;
        width: 20px;
    }
    .st-deleteTagBlock {
        background: url("../../images/close_lightgrey.svg") no-repeat center;
        background-size: 8px;
        border-radius: 100%;
        cursor: pointer;
        z-index: 15;
    }
    .st-deleteTagBlock:hover {
        background: url("../../images/close_dark_grey.svg") no-repeat center #D2D4D4;
        background-size: 8px;
    }
    .st-removeFromInput {
        background: url("../../images/close_white.svg") no-repeat center;
        background-size: 7px;
        cursor: pointer;
        height: 24px;
        margin-left: 10px;
        width: 8px;
    }
}

.clientPage {
    padding-bottom: 50px;
    margin: auto;
    margin-top: 130px;
    width: 1125px;
    .js-redirect-addClient {
        background: none;
        border: 0;
        border-radius: 6px;
        height: 38px;
        margin-right: 26px;
        padding: 0 26px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .js-redirect-create-edevis {
        background-color: $cprimary-12;
        border: 0;
        border-radius: 6px;
        color: $cwhite;
        height: 38px;
        padding: 0 36px;
        &:hover {
            background-color: $cprimary-11;
        }
    }
    h1 {
        margin-right: 15px;
    }
    .js-openPopin-updateClient {
        border-radius: 6px;
        height: 32px;
        width: 32px;
        &:hover {
            background-color: $csecondary-1;
        }
    }
    .st-clientBusinessName {
        color: $csecondary-4;
    }
    .st-back-dashboard {
        margin-top: initial;
        width: fit-content;
    }

    // Disbursement bloc
    .st-blocDisbursement {
        background-color: $csecondary-1;
        border-radius: 10px;
        padding: 30px 50px;
        text-align: right;
        width: 300px;
        .js-datepicker {
            border-radius: 6px;
            color: $csecondary-3;
            height: 36px;
            margin-right: 0;
            margin-left: auto;
            // width: 172px;
            input {
                color: $csecondary-3 !important;
            }
            label {
                cursor: pointer;
                margin-top: 8px;
                width: 100%;
            }
            &:hover {
                background-color: white !important;
            }
        }
        .form-control {
            background-color: transparent;
            border: 0;
            height: 36px;
            padding: 0 0 0 4px;
            width: 80px;
        }
    }
    .st-totalDisbursement {
        font-family: "Poppins";
    }

    // Operations bloc
    .st-blocOperations {
        button {
            text-align: center;
        }
    }
    .st-filterOperations, .st-filterBills {
        display: none;
    }
    .st-blocFilterDate {
        margin-top: -2px;
    }
    .st-separatorFilterDate {
        margin-top: 4px;
        font-size: 14px;
    }
    .js-show-filterOperations, .js-show-filterBills {
        background-image: url("/build/images/icon_filter_grey_noBG.svg");
        height: 32px;
        margin-top: -5px;
        width: 32px;
        &:hover {
            background-image: url("/build/images/icon_filter_grey.svg");
        }
    }
    .js-hide-filterOperations, .js-hide-filterBills {
        background-image: url("/build/images/icon_filter_black_noBG.svg");
        display: none;
        height: 32px;
        margin-top: -5px;
        width: 32px;
        &:hover {
            background-image: url("/build/images/icon_filter_black.svg");
        }
    }
    .js-sortOperations, .js-sortOperationsClient {
        background-image: url("/build/images/icon_dropdown_whitegrey.svg");
        background-size: 12px;
        border: 0;
        color: $csecondary-3;
        font-weight: normal;
        height: 32px;
        margin-top: -4px;
        margin-left: 25px;
        padding: 0 20px 0 0;
        option {
            color: $cprimary-12;
        }
    }
    .st-searchOperations, .st-searchBills {
        margin-left: 25px;
        margin-top: -4px;
        img {
            left: 0;
            width: 18px;
        }
        input {
            border: 0;
            height: 32px;
            padding-left: 25px;
        }
    }
    .st-totalWaitingOperations, .st-totalSuccessOperations, .st-totalWaitingBills, .st-totalSuccessBills {
        color: $csecondary-3;
        margin-top: -2px;
    }
    .js-showMoreWaitOpe, .js-showMoreSuccessOpe, .js-showMoreWaitBills, .js-showMoreSuccessBills, .js-showMoreWaitOpeClient,
    .js-reduceWaitOpe, .js-reduceSuccessOpe, .js-reduceWaitBills, .js-reduceSuccessBills {
        background: none;
        border: 0;
        border-radius: 4px;
        color: $cprimary-7;
        display: none;
        height: 28px;
        padding: 0 12px;
        margin-top: 20px;
        &:hover {
            background-color: $cprimary-1;
        }
    }
    .st-emptyOperations {
        display: block;
        text-align: center;
        p {
            color: $csecondary-4;
        }
    }
    .st-rowClientPayment {
        height: 82px;
    }
    .st-rowClientPayment:nth-of-type(2n+1) {
        background-color: $csecondary-1;
        border-radius: 8px;
    }
    .st-clientPaymentBloc1 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .st-clientPaymentBloc2 {
        cursor: pointer;
        padding-left: 10px;
        margin-top: auto;
        margin-bottom: auto;
        width: 210px;
    }
    .st-clientPaymentBloc3 {
        cursor: pointer;
        padding-left: 230px;
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 125px;
        p {
            width: 110px;
        }
    }
    .st-clientPaymentBloc4 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        img {
            margin-right: 8px;
            margin-top: 4px;
        }
        p {
            font-style: italic;
            width: 152px;
        }
    }
    .st-clientPaymentBloc5 {
        margin: auto 18px;
        text-align: right;
        width: 152px;
        .st-toPay {
            background-color: $cprimary-7;
            border: 0;
            border-radius: 50px;
            color: white;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 32px;
        }
        .st-programmedPayment {
            background-color: transparent;
            border: 1px $cprimary-7 solid;
            border-radius: 50px;
            color: $cprimary-7;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
        }
        .st-to-complete {
            background-color: transparent;
            border: 1px solid $cprimary-12;
            border-radius: 50px;
            color: $cprimary-12;
            cursor: pointer;
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
        }
    }
    .st-clientPaymentBloc6 {
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        padding-right: 18px;
        position: relative;
        p {
            background-color: #02130E;
            width: 5px;
            height: 5px;
            border-radius: 10px;
            margin-top: 3px;
            margin-bottom: 3px;
        }
        .st-actionsClientPayment {
            display: none;
        }
        & .js-markAsPayed, .js-unmarkAsPayed, .st-alone-delete, .st-mark-delete, .st-unmark-delete {
            background-color: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 4px 4px 0 0;
            color: #aaabaf;
            cursor: pointer;
            font-size: 14px;
            height: auto;
            padding: 10px;
            position: absolute;
            right: 0px;
            text-align: center;
            top: 10px;
            width: 190px;
            z-index: 2;
            &:hover {
                color: $cprimary-7;
            }
        }
        .st-alone-delete {
            border-radius: 0 0 4px 4px;
            top: 0;
        }
        .st-mark-delete {
            border-radius: 0 0 4px 4px;
            top: 53px;
        }
        .st-unmark-delete {
            border-radius: 0 0 4px 4px;
            top: 74px;
        }
    }

    // Bills bloc
    .js-openPopin-downloadAllBills, .js-openPopin-downloadAllDocs {
        background: transparent;
        border: 1px solid $cprimary-12;
        border-radius: 6px;
        height: 38px;
        padding: 0 26px;
        &:hover {
            background-color: $csecondary-1;
        }
        img {
            margin-top: -2px;
            margin-right: 5px;
        }
    }
    .js-openPopin-uploadBills, .js-openPopin-uploadFiles, .js-openPopin-addFolderClient {
        background: transparent;
        border: 0;
        border-radius: 6px;
        height: 40px;
        padding: 0 10px;
        &:hover {
            background-color: $csecondary-1;
        }
        img {
            margin-top: -4px;
        }
    }
    .js-container-waitingBills, .js-container-successBills {
        display: flex;
        flex-wrap: wrap;
    }
    .st-containerBill {
        border: 2px solid $csecondary-2;
        border-radius: 4px;
        height: 156px;
        &:nth-child(6n) {
            margin-right: 0;
        }
        margin-right: 57px;
        margin-bottom: 30px;
        text-align: center;
        width: 140px;
    }
    .st-emptyBills, .st-emptyFiles, .st-emptySearch {
        background-color: $csecondary-1;
        border-radius: 4px;
        color: $csecondary-4;
        font-size: 14px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        width: 100%;
    }
    .st-emptyBills {
        display: none;
    }
    .st-blocFormError1 {
        width: 400px;
    }
    .js-changeFromDateBill, .js-changeToDateBill {
        border: 0;
        width: 86px;
    }

    // Folder bloc
    .st-containerFolder {
        margin-right: 15px;
        position: relative;
        div {
            left: 20px;
            position: absolute;
            top: 40px;
            z-index: 10;
        }
        h4 {
            margin-bottom: 17px;
        }
    }
}

.form-control[readonly] {
    background-color: transparent !important;
}

/* POPIN suppression fiche client */

.st-isCanceled {
    .st-cancel {
        display: none;
    }

    .st-achivedTextAlign {

    }

    .st-deleteClientPopinButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .st-blackCross {
        margin-right: -12px;
        height: auto;
        width: 10%;
    }

    //todo : retouches
}

.st-greenCross,
.st-blackCross {
    margin-right: 6px;
    height: auto;
    width: 10%;
}

.st-deleteClientPopinButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.st-imgArchive{
    height: auto;
    width: 30px;
}

.st-archiveDeleteClient {
    display: none;
}

.st-archiveImg {
    cursor: pointer;
    padding:0;
    margin: 0;
    height: 100%;
}

.st-archiveImg:hover .st-archiveDeleteClient{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.st-archiveImg:hover{
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    padding-right: 10px;
}

.st-wordArchiver{
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.st-actionsFicheClient{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.st-imgTrashFiche {
    height: auto;
    width: 24px;
    margin-left: 20px;
}



.st-imgArchiveFiche,
.st-imgTrashFiche
{
    height: auto;
    width: 24px;
    margin: auto;
}

.st-hr {
   margin-top: 100px;
}

.st-icon-footer-left,
.st-icon-footer-right {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 54px;
    height: 54px;
}

.st-icon-footer-left {
    margin-right: 20px;
}

.st-icon-footer-right {
    margin-left: 20px;
}

.st-icon-footer-left:hover,
.st-icon-footer-right:hover {
    background-color: #F8F8F8;
}



