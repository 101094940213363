.js-drop {
    width: 100%;
    text-align: center;
}

.dropzone {
    border-radius: 4px;
    border: dashed 2px $cprimary-7;
    background-color: $cprimary-1;
    margin: 10px 0;
    padding: 20px;
    
}

.dropzone.dz-drag-hover {
    animation: border-dance 4s infinite alternate;
}
  @keyframes border-dance {
    0% {
        border-color: lighten($cprimary-7, 20%);
    }
    100% {
        border-color: darken($cprimary-7, 20%);
    }
  }

.dotmenu-folder {
    position: absolute;
    top: 40px;
    right: 5px;
    text-align: center;
    width: 30px;
    display: none;
    transition: 0.3s;
    // transform: rotate(90deg);
}

.folder:hover .dotmenu-folder {
    display: block;
}

.dotmenu {
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(90deg);
}

.menuhide {
    display: none;
    position: absolute;
    background: #fff;
    top: 30px;
    width: 115px;
    left: 94%;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    z-index: 999;
    border-radius: 4px;
    &.open {
        display: block;
    }
}

.doc.js-file {
    overflow: visible;
}

.menuhide a {
    padding: 10px 15px;
    border-bottom: 2px solid #e8e9ea;
    display: block;
    text-transform: capitalize;
    &:hover {
        // font-weight: bold;
        color: $cprimary-7 !important;
    }
}

.menuhide a:last-child {
    border-bottom: 0;
}

.dz-filename span {
    width: 120px;
    display: block;
    overflow: hidden;
}