.cookielaw {
    position: fixed;
    display: flex;
    z-index: 999;
    color: #fff;
    font-weight: bold;
    bottom: 10px;
    width: 333px;
    justify-content: center;
    height: 468px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $cprimary-1;
    padding: 15px;
    right: 10px;
    flex-direction: column;
    align-items: center;
}

.cookielaw .okcookie {
    background: $cprimary-7;
    width: 130px;
    height: 42px;
    margin: 20px auto;
    border-radius: 4px;
    line-height: 42px;
    padding: 0;
    text-align: center;
    cursor: pointer;
}

.cookie-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 30px;
}

.cookie-text {
    text-align: center;
    font-weight: normal;
}

.cookie-link {
    margin-top: 20px;
    a {
        color: $cprimary-7;
    }
}

.cookielaw {
    display: none;
    &.active {
      display: flex;  
    }
}

.cookie-textbox {
    text-align: center;
}
