.activity-tree-start {
    text-align: center;
}

.activity-tree {
    display: flex;
    flex-direction: column-reverse;
}

.activity-day .day {
    text-align: center;
    font-size: 14px;
    color: $cprimary-12;
    margin: 10px 0;
    font-weight: bold;
}

.activity-day {
    position: relative;
}

.activity-tree .activity-day:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 60px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    width: 2px;
    background: $cprimary-12;
    height: calc(100% - 70px);
    z-index: -1;
}

.st-openingSoan:after {
    height: calc(100% - 100px) !important;
    top: 40px !important;
}

.st-openingSoan {
    .st-arrowActivity1, .st-arrowActivity2 {
        top: 39px;
    }
}

.st-arrowActivity1 {
    width: 2px;
    position: absolute;
    height: 10px;
    background-color: $cprimary-12;
    left: 50%;
    margin-left: -4px;
    top: 57px;
    transform: rotate(45deg);
}
.st-arrowActivity2 {
    width: 2px;
    position: absolute;
    height: 10px;
    background-color: $cprimary-12;
    left: 50%;
    margin-left: 2px;
    top: 57px;
    transform: rotate(-45deg);
}

.activity-type {
    display: flex;
    flex-direction: column-reverse;
}

.activity-start {
    display: flex;
    justify-content: center;
}

.activity-round {
    width: 175px;
    height: 175px;
    line-height: 160px;
    border-radius: 50%;
    background-color: $cprimary-12;
    font-size: 14px;
    text-align: center;
    padding: 20px;
}

.st-projectActivity {
    line-height: 175px !important;
}

.round-inside {
    display: inline-block;
    line-height: normal;
    color: $cwhite;
    width: 94px;
}

.activity-step {
    display: flex;
    justify-content: center;
}

.activity-square {
    padding: 20px 40px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    background: $cprimary-7;
    color: #fff;
    flex-direction: column;
    align-items: center;
}

.activity-type > div {
    margin: 20px 0;
}

.activity-message.user-msg {
    display: flex;
    justify-content: flex-start;
}

.activity-message.archi-msg {
    display: flex;
    justify-content: flex-end;
}

.activity-msg {
    padding: 20px;
    width: 470px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    word-break: break-word;
    &:hover {
        box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    }
}

.nocursor {
    .activity-msg {
        &:hover {
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        }
    }
}

.activity-text {
    margin-left: 15px;
    width: calc(100% - 40px);
}

.paiement .activity-msg {
    background: $cprimary-12;
    color: #fff;
}

.activity-msg {
    flex-direction: column;
}

.activity-hour {
    display: flex;
    color: $csecondary-3;
    justify-content: flex-end;
    width: 90%;
    & span:nth-child(1) {
        margin-right: 20px;
    }
    margin-bottom: 5px;
}
.user-msg .activity-msg::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: -15px;
    box-sizing: border-box;
    
    border: .5em solid transparent;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(225deg) translate(-50%, -50%);
    
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
  }

  .archi-msg .activity-msg::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 0;
    box-sizing: border-box;
    
    border: .5em solid transparent;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(45deg) translate(-50%, -50%);
    
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
  }

  .archi-msg .paiement .activity-msg::after{
    border-color: transparent transparent $cprimary-12 $cprimary-12;
  }
  
.activity-change .activity-msg {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    // background-color: $cprimary-12;
}

.activity-change.user-msg .activity-msg::after {
    border-color: transparent transparent $cprimary-12 $cprimary-12;
    right: -14px;
}

.activity-change.archi-msg .activity-msg::after {
    //border-color: transparent transparent $cprimary-12 $cprimary-12;
}

.activity-change .activity-text {
    color: $cprimary-12;
}

.allActivity{
    // .initial {
    //     color: ;
    // }
    .st-titleSection {
        margin-bottom: 50px;
    }
}