section.payment .dataTables_wrapper {
    margin-top: 20px;
}

#bills-amount.error {
    border: 1px solid $cred !important;
}

.payment-content:last-child hr {
    display: none;
}
.needbank-error {
    color: #D9534F;
    font-size: 15px;
    margin-top: 15px;
}
.bills-name {
    font-weight: bold;
}

.iban-amount {
    font-weight: bold;
}

.payment-receive {
    width: 48%;
    margin-right: 2%;
}

.payment-send {
    width: 48%;
    margin-left: 2%;
}

.payment-list-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $csecondary-1;
    border-radius: 8px;
    padding: 10px;
    &.hideme {
        display: none;
    }
}

.payment-list-block.delayed-block {
    border-radius: 8px;
    border: dashed 2px $cprimary-7;
}

.payment-header-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $cprimary-6;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.payment-empty{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: dashed 2px $cprimary-12;
    padding: 20px;
}

.payment-subject {
    font-size: 20px;
    font-weight: bold;
}

.payment-amount-number {
    font-size: 20px;
    white-space: nowrap;
}

.payment-amount {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
}

.payment-amount-receiver {
    color: $cprimary-12;
    font-size: 20px;
    font-weight: bold;
}

.payment-amount-sender {
    color: $cprimary-12;
    font-weight: bold;
    font-size: 20px;
}

.js-search-payment {
    border-radius: 4px;
    padding: 6px 15px 6px 45px;
    border: solid 1px $csecondary-4;
    background-color: #ffffff;
}

.payafter {
    display: none;
}

.popinpayment-company-block {
    display: none;
}

.company-step input#company_infos_thBusinessName {
    color: $cprimary-12;
}

.popin .company-block-form input {
    //background: #f3f3f4 !important;
    color: $csecondary-3 !important;
}

.popin .popinpayment-company-block {
    background: $cwhite;
    margin-top: 0;
    padding-top: 0;
    padding: 0;
}

.company-block-actions .btn-send-company-step2 {
    margin-left: auto;
}

.payment-subject-modif {
    display: none;
    &.active {
        display: flex;
    }
}

.popin-step-header {
    display: flex;
    justify-content: space-between;
}

[class^="step-header-"] {
    color: $csecondary-4;
    font-size: 18px;
    font-weight: bold;
    &.active {
        color: $cprimary-12;
    }
    // &.valid {
    //     cursor: pointer;
    // }
}

// [class^="popin-step-content-"] {
//     padding: 0 20px;
//     display: none;
//     &.active {
//         display: block;
//     }
// }

.popin-step-error, .popin-error-sendlater {
    display: none;
    color: $cred;
    font-weight: bold;
    text-align: center;
}
.popin-step-error .elmerrormaxamount {
    color: $corange;
}

hr.cgrey {
    border-top: 1px solid #707070;
}

.accordeon-content {
    display: none;
    padding: 10px 0;
}

.menu-efacture {
    display: none;
    &.active {
        display: block;
    }
}

.menu-facture {
    display: none;
    &.active {
        display: block;
    }
}

.popin-step-menu {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    margin-bottom: 30px;
    width: 50%;
    padding-bottom: 5px;
    position: relative;
    height: 30px;
    &.no-border-bottom {
        border-bottom: 0;
    }
}


.step-menu.step-menu-efacture.active {
    border-bottom: 4px solid $cprimary-7;
    font-weight: bold;
    padding-bottom: 2.5px;
    color: #000000;
}

.step-menu {
    position: absolute;
    bottom: -3px;
    cursor: pointer;
    padding-bottom: 7.5px;
    color: #AAABAF;
}

.step-menu-efacture {
    right: 0;
}

.step-menu.step-menu-facture.active {
    border-bottom: 5px solid $cprimary-7;
    font-weight: bold;
    padding-bottom: 2.5px;
    color: #000000;
}

.accordeon-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #707070;
    margin-bottom: 5px;
    cursor: pointer;
}

.accordeon-head.active .accordeon-img img {
    transform: rotate(180deg);
}

.accordeon-title {
    font-size: 18px;
    font-weight: bold;
}

.elm-empty {
    background-color: #00000026;
    border-radius: 8px;
    text-align: center;
    padding: 15px;
    margin: 20px 0;
}

.add-elm {
    display: none;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 10px 30px 30px 30px;
}

.js-show-add-elm {
    cursor: pointer;
}

.js-createbills-elements {
    text-align: left;
}

.if-discount-facture {
    display: none;
}

.popin-payment-bystep .popin-title {
    font-size: 24px !important;
    border-bottom: 1px solid $cprimary-12;
    padding-bottom: 10px;
}

.popin-payment-bystep {
    max-width: 700px;
}

.popin .popin-payment-bystep {
    width: 700px;
    max-width: 700px !important;
    padding-left: 30px;
    padding-right: 30px;
}

.recap-content {
    display: none;
    text-align: center;
    .recap {
        text-align: center;
    }
    .recap-efacture {
        padding: 40px 0 20px;
    }
}

.preview-bills {
    display: inline-block;
    box-shadow: 0px 3px 6px #00000029;
}

.js-tooltips-content {
    position: absolute;
    left: calc(-150px + 50%);
    top: 45px;
    text-align: center;
    z-index: 9;
    padding: 10px;
    background: #fff;
    border: 1px solid;
    width: 300px;
    display: none;
    font-size: 12px;
}

.js-tooltips-content-top {
    position: absolute;
    left: calc(-150px + 50%);
    bottom: 45px;
    text-align: center;
    display: none;
    z-index: 9;
    padding: 10px;
    background: #fff;
    border: 1px solid;
    width: 300px;
    font-size: 12px;
}

.js-tooltips-content-top::after {
    content: " ";
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  .js-tooltips-content-top::before {
    content: " ";
    position: absolute;
    top: calc(99% + 1px);
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: $cprimary-12 transparent transparent transparent;
  }

.js-tooltips {
    position: relative;
}

.js-tooltips-content::after {
    content: " ";
    position: absolute;
    bottom: 99%;
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .js-tooltips-content::before {
    content: " ";
    position: absolute;
    bottom: calc(99% + 1px);
    left: 50%;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent $cprimary-12 transparent;
  }

// [class^="step-header-"].valid ~ [class^="step-header-"] {
//     cursor: pointer;
// }

.autocomplete-container-payment, .autocomplete-container-payment-project {
    margin-bottom: 15px;
    margin-top: 40px;
}

.dest-item, .project-item {
    border: 1px solid #70707030;
    border-radius: 8px;
    padding: 5px 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dest-img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 20px !important;
}

#dropzone-payment {
    input[type='file'] {
        opacity: 0;
    }
}

.drop-file-uploaded {
    margin: 30px 0;
}

.drop-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drop-file-delete {
    cursor: pointer;
}

.popin-step-header {
    margin-top: 20px;
}

.error-add-bills {
    color: $cred;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: left;
}

.elm-list .js-createbills-elements {
    background-color: $csecondary-1;
    border-radius: 8px;
    padding: 15px;
    margin: 20px 0;
}

.elm-list .js-createbills-elements .billselm-title {
    margin-bottom: 0;
}

.st-divMarkAsPayed-payment {
    position: relative;
    margin: auto 10px;
    cursor: pointer;
    & .st-points-payment {
        background-color: $cprimary-12;
        width: 5px;
        height: 5px;
        border-radius: 10px;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    & .st-notMarkAsPayed {
        top: 98px !important;
    }
    & .st-pMarkAsPayed-payment {
        background-color: #FFFFFF;
        border: 2px solid #F8F8F8;
        border-radius: 4px 4px 0 0;
        color: #aaabaf;
        cursor: pointer;
        font-size: 14px;
        padding: 10px;
        position: absolute;
        right: 0px;
        text-align: center;
        top: 30px;
        width: 190px;
        z-index: 2;
        &:hover {
            color: $cprimary-7;
        }
    }
    & .st-pCopyPaymentLink-payment {
          border-radius: 0 0 4px 4px;
          top: 72px;
      }
}
.st-PaymentRequest {
    font-size: 14px;
}

.payment-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 45%;
}

.st-labelEscompte {
    width: 68px;
    margin-top: -2px;
}

.st-label3x {
    width: 80px;
}

.st-payedBefore {
    margin-bottom: 5px;
}

.st-actionButtonPayed {
    margin-bottom: 14px;
}

.st-escompteLabelCustomPayment {
    background-color: $cprimary-5;
    color: white;
    font-family: 'Roboto', sans-serif;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 7px;
    font-size: 10px;
    font-weight: 600 !important;
    text-align: initial !important;
    width: 62px !important;
    margin-top: 3px !important; 
    margin-right: 5px;
    margin-bottom: 10px;
}

.st-3xLabelCustomPayment {
    background-color: $cprimary-11;
    color: white;
    font-family: 'Roboto', sans-serif;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 7px;
    font-size: 10px !important;
    font-weight: 600 !important;
    text-align: initial !important;
    width: 76px !important;
    margin-top: 2px !important;
    margin-right: 5px;
}

.st-3xLabelCustomPayment:lang(es) {
    background-color: #002e7a;
    color: white;
    font-family: 'Roboto', sans-serif;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 7px;
    font-size: 10px !important;
    font-weight: 600 !important;
    text-align: initial !important;
    width: 98px !important;
    margin-top: 2px !important;
    margin-right: 5px;
}

.st-htPriceLocale:lang(es) {
    width: 35% !important;
}