.promoTable {
    width: 100%;
}
.addPromoForm {
    width: 50%;
    margin-top: 60px;
}
.generate-promo {
    width: 250px;
}

.error-promo {
    background: red;
    border-radius: 6px;
    height: 35px;
    line-height: 35px;
}

.input-search-table input {
    color: #03261d;
}
