.members-list-container {
    width: 70%;
}

.members-list {
    display: flex;
    border-bottom: 1px solid $cprimary-12;
    margin: 10px 0;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.members-list > div {
    margin-right: 15px;
}

.js-send-invitation {
    padding: 6px 12px;
}

.members-list-picture {
    margin-right: 10px;
}

.js-change-role {
    border-radius: 100px;
    border-color: $cprimary-12;
}

.js-change-role-invitation {
    border-radius: 100px;
    border-color: $cprimary-12;
}

.members-invitation {
    .members-list-info {
        color: $csecondary-4;
    }
}

.members-notice-header {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
}
.members-list-option {
    position: relative;
}

.st-superAdmin {
    margin-left: -40px;
}

.st-iconRoles {
    margin-top: -5px;
}

#change_infos_thBirthday_day, #change_infos_thBirthday_month, #change_infos_thBirthday_year {
    color: black;
    &:focus {
        color: black;
    }
}