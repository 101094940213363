.display-none {
    display: none;
}

.st-background-size-15px {
    background-size: 15px !important;
}

.st-background-cover {
    background-size: cover;
}

.st-border-radius-025rem {
    border-radius: 0.25rem;
}

.st-border-radius-8px {
    border-radius: 8px;
}
.st-border-radius-6px {
    border-radius: 6px;
}
.st-border-radius-10px {
    border-radius: 10px;
}
.st-border-radius-50 {
    border-radius: 50%;
}

.st-border-radius-50px {
    border-radius: 50px;
}

.st-border-none {
    border: none !important;
}

.st-border-1px-solid {
    border: 1px solid;
}

.st-cursor-default {
    cursor: default !important;
}

.st-cursor-pointer {
    cursor: pointer !important;
}

.st-overflow-x-scroll {
    overflow-x: auto;
}

.st-text-decoration {
    text-decoration: underline;
}

// TO APPLY TO ELEMENT THAT WE WANT TRUNCATE DEPENDING ON PARENT TAG WIDTH
.st-truncate {
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}

.st-z-index-10 {
    z-index: 10;
}

// MANAGE TO DON'T DISPLAY DEFAULT ICONS OF INPUTS TYPE DATE (NECESSARY FOR MOBILE CAUSE FLATPICKR CHANGE TYPE TEXT TO DATE)
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="text"]::-webkit-calendar-picker-indicator {
    display: none;
}

.st-top-48px {
    top: 48px;
}

.st-left-5px {
    left: 5px;
}

.st-left-22px {
    left: 22px;
}

.st-top-0px {
    top: 0px;
}

.st-top-5px {
    top: 5px;
}

.st-right-5px {
    right: 5px;
}

.st-box-shadow {
    box-shadow: 0px 3px 6px #00000029;
}

.st-text-decoration-line-throught {
    text-decoration: line-through;
}

.st-whitespace-nowrap {
    white-space: nowrap;
}