.slider {
    width: 100%;
    height: 400px;
    .slide-wrapper {
        .arrow-left {
            position: absolute;
            top: 50%;
            left: 10px;
            height: 30px;
            width: 30px;
            transform: translate(0, -50%);
        }
        .slide{
            .slide-img {
                
            }
        }
    }
}