.archi-modifinfo-signature {
    max-width: 500px;
}
.generate-signature {
    width: 40%;
}
.signature-container {
    align-items: center;
    background: white;
    display: flex;
    height: 170px;
    width: 500px;
}
.tuto-gif { height: 500px; }
.btn-container {
    text-align: center;
    width: 500px;
    button {
        width: 40%;
    }
}
.hubspot {
    display: none;
}