.asking-payment-mail {
    padding: 20px;
    border-radius: 4px;
    border: 2px solid $cprimary-12;

    .mail-cta-button {
        width: 100%;
        height: 50px;
        background-color: $cprimary-3;
    }
}
.st-blockPaymentLinkPopin {
    margin-top: 30px;
}
.st-payment-link-popin {
    border: 1px solid $cprimary-12;
    border-radius: 4px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    line-height: 40px;
    padding: 0 10px;
}
.st-disabled-payment-link {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    border: 1px solid #ABB0AF;
    color: #ABB0AF;
    user-select: none;
}
.st-payment-link-popin span:first-child {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.st-payment-later-block {
    margin-left: 50px;
}

.st-logoInEmail {
    width: 80px;
    height: 80px;
    & img {
        max-width: 100%;
    }
}