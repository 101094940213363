.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    //padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    //border: 1px solid #ccc;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .popover.top {
    margin-top: -10px;
  }
  .popover.right {
    margin-left: 10px;
  }
  .popover.bottom {
    margin-top: 10px;
  }
  .popover.left {
    margin-left: -10px;
  }
  .popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
  }
  .popover-content {
    padding: 9px 14px;
  }
  .popover > .arrow,
  .popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .popover > .arrow {
    border-width: 11px;
  }
  .popover > .arrow:after {
    border-width: 10px;
    content: "";
  }
  .popover.top > .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999999;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px;
  }
  .popover.top > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff;
  }
  .popover.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999999;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  .popover.right > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff;
  }
  .popover.bottom > .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px;
  }
  .popover.bottom > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff;
  }
  .popover.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999999;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  .popover.left > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px;
  }

  .popover.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  .popover.fade.in {
    opacity: 1;
  }
//   .collapse {
//     display: none;
//   }
//   .collapse.in {
//     display: block;
//   }
//   tr.collapse.in {
//     display: table-row;
//   }
//   tbody.collapse.in {
//     display: table-row-group;
//   }
//   .collapsing {
//     position: relative;
//     height: 0;
//     overflow: hidden;
//     -webkit-transition-property: height, visibility;
//     transition-property: height, visibility;
//     -webkit-transition-duration: 0.35s;
//     transition-duration: 0.35s;
//     -webkit-transition-timing-function: ease;
//     transition-timing-function: ease;
//   }
  .tour-backdrop {
    position: absolute;
    z-index: 1100;
    background-color: #000;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
  .popover[class*="tour-"] {
    z-index: 1102;
  }
  .popover[class*="tour-"] .popover-navigation {
    padding: 9px 14px;
    overflow: hidden;
  }
  .popover[class*="tour-"] .popover-navigation *[data-role="end"] {
    float: right;
  }
  .popover[class*="tour-"] .popover-navigation *[data-role="prev"],
  .popover[class*="tour-"] .popover-navigation *[data-role="next"],
  .popover[class*="tour-"] .popover-navigation *[data-role="end"] {
    cursor: pointer;
  }
  .popover[class*="tour-"] .popover-navigation *[data-role="prev"].disabled,
  .popover[class*="tour-"] .popover-navigation *[data-role="next"].disabled,
  .popover[class*="tour-"] .popover-navigation *[data-role="end"].disabled {
    cursor: default;
  }
  .popover[class*="tour-"].orphan {
    position: fixed;
    margin-top: 0;
  }
  .popover[class*="tour-"].orphan .arrow {
    display: none;
  }
  
  .popover {
    .btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .btn:focus,
      .btn:active:focus,
      .btn.active:focus,
      .btn.focus,
      .btn:active.focus,
      .btn.active.focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
      .btn:hover,
      .btn:focus,
      .btn.focus {
        color: #333;
        text-decoration: none;
      }
      .btn:active,
      .btn.active {
        outline: 0;
        background-image: none;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
      .btn.disabled,
      .btn[disabled],
      fieldset[disabled] .btn {
        cursor: not-allowed;
        opacity: 0.65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      a.btn.disabled,
      fieldset[disabled] a.btn {
        pointer-events: none;
      }
      .btn-default {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
      }
      .btn-default:focus,
      .btn-default.focus {
        color: #333;
        background-color: #e6e6e6;
        border-color: #8c8c8c;
      }
      .btn-default:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
      }
      .btn-default:active,
      .btn-default.active,
      .open > .dropdown-toggle.btn-default {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
      }
      .btn-default:active:hover,
      .btn-default.active:hover,
      .open > .dropdown-toggle.btn-default:hover,
      .btn-default:active:focus,
      .btn-default.active:focus,
      .open > .dropdown-toggle.btn-default:focus,
      .btn-default:active.focus,
      .btn-default.active.focus,
      .open > .dropdown-toggle.btn-default.focus {
        color: #333;
        background-color: #d4d4d4;
        border-color: #8c8c8c;
      }
      .btn-default:active,
      .btn-default.active,
      .open > .dropdown-toggle.btn-default {
        background-image: none;
      }
      .btn-default.disabled:hover,
      .btn-default[disabled]:hover,
      fieldset[disabled] .btn-default:hover,
      .btn-default.disabled:focus,
      .btn-default[disabled]:focus,
      fieldset[disabled] .btn-default:focus,
      .btn-default.disabled.focus,
      .btn-default[disabled].focus,
      fieldset[disabled] .btn-default.focus {
        background-color: #fff;
        border-color: #ccc;
      }
      .btn-default .badge {
        color: #fff;
        background-color: #333;
      }
      .btn-primary {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
      }
      .btn-primary:focus,
      .btn-primary.focus {
        color: #fff;
        background-color: #286090;
        border-color: #122b40;
      }
      .btn-primary:hover {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
      }
      .btn-primary:active,
      .btn-primary.active,
      .open > .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
      }
      .btn-primary:active:hover,
      .btn-primary.active:hover,
      .open > .dropdown-toggle.btn-primary:hover,
      .btn-primary:active:focus,
      .btn-primary.active:focus,
      .open > .dropdown-toggle.btn-primary:focus,
      .btn-primary:active.focus,
      .btn-primary.active.focus,
      .open > .dropdown-toggle.btn-primary.focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
      }
      .btn-primary:active,
      .btn-primary.active,
      .open > .dropdown-toggle.btn-primary {
        background-image: none;
      }
      .btn-primary.disabled:hover,
      .btn-primary[disabled]:hover,
      fieldset[disabled] .btn-primary:hover,
      .btn-primary.disabled:focus,
      .btn-primary[disabled]:focus,
      fieldset[disabled] .btn-primary:focus,
      .btn-primary.disabled.focus,
      .btn-primary[disabled].focus,
      fieldset[disabled] .btn-primary.focus {
        background-color: #337ab7;
        border-color: #2e6da4;
      }
      .btn-primary .badge {
        color: #337ab7;
        background-color: #fff;
      }
      .btn-success {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
      }
      .btn-success:focus,
      .btn-success.focus {
        color: #fff;
        background-color: #449d44;
        border-color: #255625;
      }
      .btn-success:hover {
        color: #fff;
        background-color: #449d44;
        border-color: #398439;
      }
      .btn-success:active,
      .btn-success.active,
      .open > .dropdown-toggle.btn-success {
        color: #fff;
        background-color: #449d44;
        border-color: #398439;
      }
      .btn-success:active:hover,
      .btn-success.active:hover,
      .open > .dropdown-toggle.btn-success:hover,
      .btn-success:active:focus,
      .btn-success.active:focus,
      .open > .dropdown-toggle.btn-success:focus,
      .btn-success:active.focus,
      .btn-success.active.focus,
      .open > .dropdown-toggle.btn-success.focus {
        color: #fff;
        background-color: #398439;
        border-color: #255625;
      }
      .btn-success:active,
      .btn-success.active,
      .open > .dropdown-toggle.btn-success {
        background-image: none;
      }
      .btn-success.disabled:hover,
      .btn-success[disabled]:hover,
      fieldset[disabled] .btn-success:hover,
      .btn-success.disabled:focus,
      .btn-success[disabled]:focus,
      fieldset[disabled] .btn-success:focus,
      .btn-success.disabled.focus,
      .btn-success[disabled].focus,
      fieldset[disabled] .btn-success.focus {
        background-color: #5cb85c;
        border-color: #4cae4c;
      }
      .btn-success .badge {
        color: #5cb85c;
        background-color: #fff;
      }
      .btn-info {
        color: #fff;
        background-color: #5bc0de;
        border-color: #46b8da;
      }
      .btn-info:focus,
      .btn-info.focus {
        color: #fff;
        background-color: #31b0d5;
        border-color: #1b6d85;
      }
      .btn-info:hover {
        color: #fff;
        background-color: #31b0d5;
        border-color: #269abc;
      }
      .btn-info:active,
      .btn-info.active,
      .open > .dropdown-toggle.btn-info {
        color: #fff;
        background-color: #31b0d5;
        border-color: #269abc;
      }
      .btn-info:active:hover,
      .btn-info.active:hover,
      .open > .dropdown-toggle.btn-info:hover,
      .btn-info:active:focus,
      .btn-info.active:focus,
      .open > .dropdown-toggle.btn-info:focus,
      .btn-info:active.focus,
      .btn-info.active.focus,
      .open > .dropdown-toggle.btn-info.focus {
        color: #fff;
        background-color: #269abc;
        border-color: #1b6d85;
      }
      .btn-info:active,
      .btn-info.active,
      .open > .dropdown-toggle.btn-info {
        background-image: none;
      }
      .btn-info.disabled:hover,
      .btn-info[disabled]:hover,
      fieldset[disabled] .btn-info:hover,
      .btn-info.disabled:focus,
      .btn-info[disabled]:focus,
      fieldset[disabled] .btn-info:focus,
      .btn-info.disabled.focus,
      .btn-info[disabled].focus,
      fieldset[disabled] .btn-info.focus {
        background-color: #5bc0de;
        border-color: #46b8da;
      }
      .btn-info .badge {
        color: #5bc0de;
        background-color: #fff;
      }
      .btn-warning {
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
      }
      .btn-warning:focus,
      .btn-warning.focus {
        color: #fff;
        background-color: #ec971f;
        border-color: #985f0d;
      }
      .btn-warning:hover {
        color: #fff;
        background-color: #ec971f;
        border-color: #d58512;
      }
      .btn-warning:active,
      .btn-warning.active,
      .open > .dropdown-toggle.btn-warning {
        color: #fff;
        background-color: #ec971f;
        border-color: #d58512;
      }
      .btn-warning:active:hover,
      .btn-warning.active:hover,
      .open > .dropdown-toggle.btn-warning:hover,
      .btn-warning:active:focus,
      .btn-warning.active:focus,
      .open > .dropdown-toggle.btn-warning:focus,
      .btn-warning:active.focus,
      .btn-warning.active.focus,
      .open > .dropdown-toggle.btn-warning.focus {
        color: #fff;
        background-color: #d58512;
        border-color: #985f0d;
      }
      .btn-warning:active,
      .btn-warning.active,
      .open > .dropdown-toggle.btn-warning {
        background-image: none;
      }
      .btn-warning.disabled:hover,
      .btn-warning[disabled]:hover,
      fieldset[disabled] .btn-warning:hover,
      .btn-warning.disabled:focus,
      .btn-warning[disabled]:focus,
      fieldset[disabled] .btn-warning:focus,
      .btn-warning.disabled.focus,
      .btn-warning[disabled].focus,
      fieldset[disabled] .btn-warning.focus {
        background-color: #f0ad4e;
        border-color: #eea236;
      }
      .btn-warning .badge {
        color: #f0ad4e;
        background-color: #fff;
      }
      .btn-danger {
        color: #fff;
        background-color: #d9534f;
        border-color: #d43f3a;
      }
      .btn-danger:focus,
      .btn-danger.focus {
        color: #fff;
        background-color: #c9302c;
        border-color: #761c19;
      }
      .btn-danger:hover {
        color: #fff;
        background-color: #c9302c;
        border-color: #ac2925;
      }
      .btn-danger:active,
      .btn-danger.active,
      .open > .dropdown-toggle.btn-danger {
        color: #fff;
        background-color: #c9302c;
        border-color: #ac2925;
      }
      .btn-danger:active:hover,
      .btn-danger.active:hover,
      .open > .dropdown-toggle.btn-danger:hover,
      .btn-danger:active:focus,
      .btn-danger.active:focus,
      .open > .dropdown-toggle.btn-danger:focus,
      .btn-danger:active.focus,
      .btn-danger.active.focus,
      .open > .dropdown-toggle.btn-danger.focus {
        color: #fff;
        background-color: #ac2925;
        border-color: #761c19;
      }
      .btn-danger:active,
      .btn-danger.active,
      .open > .dropdown-toggle.btn-danger {
        background-image: none;
      }
      .btn-danger.disabled:hover,
      .btn-danger[disabled]:hover,
      fieldset[disabled] .btn-danger:hover,
      .btn-danger.disabled:focus,
      .btn-danger[disabled]:focus,
      fieldset[disabled] .btn-danger:focus,
      .btn-danger.disabled.focus,
      .btn-danger[disabled].focus,
      fieldset[disabled] .btn-danger.focus {
        background-color: #d9534f;
        border-color: #d43f3a;
      }
      .btn-danger .badge {
        color: #d9534f;
        background-color: #fff;
      }
      .btn-link {
        color: #337ab7;
        font-weight: normal;
        border-radius: 0;
      }
      .btn-link,
      .btn-link:active,
      .btn-link.active,
      .btn-link[disabled],
      fieldset[disabled] .btn-link {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      .btn-link,
      .btn-link:hover,
      .btn-link:focus,
      .btn-link:active {
        border-color: transparent;
      }
      .btn-link:hover,
      .btn-link:focus {
        color: #23527c;
        // text-decoration: underline;
        background-color: transparent;
      }
      .btn-link[disabled]:hover,
      fieldset[disabled] .btn-link:hover,
      .btn-link[disabled]:focus,
      fieldset[disabled] .btn-link:focus {
        color: #777777;
        text-decoration: none;
      }
      .btn-lg,
      .btn-group-lg > .btn {
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 6px;
      }
      .btn-sm,
      .btn-group-sm > .btn {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      }
      .btn-xs,
      .btn-group-xs > .btn {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
      }
      .btn-block {
        display: block;
        width: 100%;
      }
      .btn-block + .btn-block {
        margin-top: 5px;
      }
      input[type="submit"].btn-block,
      input[type="reset"].btn-block,
      input[type="button"].btn-block {
        width: 100%;
      }
      .btn-group,
      .btn-group-vertical {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
      .btn-group > .btn,
      .btn-group-vertical > .btn {
        position: relative;
        float: left;
      }
      .btn-group > .btn:hover,
      .btn-group-vertical > .btn:hover,
      .btn-group > .btn:focus,
      .btn-group-vertical > .btn:focus,
      .btn-group > .btn:active,
      .btn-group-vertical > .btn:active,
      .btn-group > .btn.active,
      .btn-group-vertical > .btn.active {
        z-index: 2;
      }
      .btn-group .btn + .btn,
      .btn-group .btn + .btn-group,
      .btn-group .btn-group + .btn,
      .btn-group .btn-group + .btn-group {
        margin-left: -1px;
      }
      .btn-toolbar {
        margin-left: -5px;
      }
      .btn-toolbar .btn,
      .btn-toolbar .btn-group,
      .btn-toolbar .input-group {
        float: left;
      }
      .btn-toolbar > .btn,
      .btn-toolbar > .btn-group,
      .btn-toolbar > .input-group {
        margin-left: 5px;
      }
      .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
        border-radius: 0;
      }
      .btn-group > .btn:first-child {
        margin-left: 0;
      }
      .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      .btn-group > .btn:last-child:not(:first-child),
      .btn-group > .dropdown-toggle:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      .btn-group > .btn-group {
        float: left;
      }
      .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
        border-radius: 0;
      }
      .btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
      .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      .btn-group .dropdown-toggle:active,
      .btn-group.open .dropdown-toggle {
        outline: 0;
      }
      .btn-group > .btn + .dropdown-toggle {
        padding-left: 8px;
        padding-right: 8px;
      }
      .btn-group > .btn-lg + .dropdown-toggle {
        padding-left: 12px;
        padding-right: 12px;
      }
      .btn-group.open .dropdown-toggle {
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
      .btn-group.open .dropdown-toggle.btn-link {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      .btn .caret {
        margin-left: 0;
      }
      .btn-lg .caret {
        border-width: 5px 5px 0;
        border-bottom-width: 0;
      }
      .dropup .btn-lg .caret {
        border-width: 0 5px 5px;
      }
      .btn-group-vertical > .btn,
      .btn-group-vertical > .btn-group,
      .btn-group-vertical > .btn-group > .btn {
        display: block;
        float: none;
        width: 100%;
        max-width: 100%;
      }
      .btn-group-vertical > .btn-group > .btn {
        float: none;
      }
      .btn-group-vertical > .btn + .btn,
      .btn-group-vertical > .btn + .btn-group,
      .btn-group-vertical > .btn-group + .btn,
      .btn-group-vertical > .btn-group + .btn-group {
        margin-top: -1px;
        margin-left: 0;
      }
      .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
      .btn-group-vertical > .btn:first-child:not(:last-child) {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .btn-group-vertical > .btn:last-child:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
        border-radius: 0;
      }
      .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
      .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      .btn-group-justified {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
      }
      .btn-group-justified > .btn,
      .btn-group-justified > .btn-group {
        float: none;
        display: table-cell;
        width: 1%;
      }
      .btn-group-justified > .btn-group .btn {
        width: 100%;
      }
      .btn-group-justified > .btn-group .dropdown-menu {
        left: auto;
      }
      [data-toggle="buttons"] > .btn input[type="radio"],
      [data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
      [data-toggle="buttons"] > .btn input[type="checkbox"],
      [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
  }

  h3.popover-title {
    //background: $cprimary-5;
    color: $cprimary-12;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-radius: 6px 6px 0 0;
    border: 6px solid $corange;
    background: #fff;
    border-bottom: 0;
}

.popover-content {
    //background: $cprimary-5;
    color: $cprimary-12;
    border: 6px solid $corange;
    background: #fff;
    border-top: 0;
    border-bottom: 0;
}

.popover-navigation {
    //background: $cprimary-5;
    border: 6px solid $corange;
    border-top: 0;
    background: $cwhite;
}

.popover.tour {
  border: 0;
}

.popover > .arrow {
  border: 0;
}

.popover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}

.popover.right > .arrow:after {
  //border-right-color: $cprimary-5 !important;
  border-right-color: $corange !important;
}

.popover.left > .arrow:after {
  //border-left-color: $cprimary-5 !important;
  border-left-color: $corange !important;
}

.popover.bottom > .arrow:after {
  //border-bottom-color: $cprimary-5 !important;
  border-bottom-color: $corange !important;
}

.popover.top > .arrow:after {
  //border-top-color: $cprimary-5 !important;
  border-top-color: $corange !important;
}

.popover[class*="tour-"] .popover-navigation {
  border-radius: 0 0 6px 6px;
}

.popover-return + h3.popover-title {
  border-top: 0;
  border-radius: 0;
}

/*Design Soan*/
.tour-first {
  border-radius: 4px;
  text-align: center;
  padding: 40px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  max-width: none;
}

.tour-title {
  font-weight: bold;
  font-size: 24px;
}

.underbtn {
  display: flex;
  justify-content: center;
}

.popover .btn-purple {
  //background: $cprimary-5;
  background: $corange;
  color: #fff;
  padding: 10px 30px;
  width: 280px;
  font-weight: bold;
  border-radius: 4px;
  margin-bottom: 10px;
  &:hover {
    color: #fff;
  }
}

.popover .btn-white, .tourend .btn-white {
  color: $cwhite;
  //background-color: #fff;
  background-color: $corange;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  &:hover {
    color: $cwhite;
  }
}

.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
  float: none;
}

.js-tour-activation {
  width: 32px;
  line-height: 32px;
  height: 32px;
  display: none;
}

.tourend {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 20px;
  //background: $cprimary-5;
  //background: $corange;
  background: $cwhite;
  border: 6px solid $corange;
  z-index: 99999;
  color: #fff;
  top: 60px;
  width: 350px;
  position: absolute;
  transform: translate(-40%, 0);
  left: 0;
  display: none;
  &.active {
    display: block;
  }
}

.pr {
  position: relative;
}

#step-0 .popover-return {
  display: none;
}

.tourend:after, .tourend:before, .tour-popin-activation:after, .tour-popin-activation:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tourend:after, .tour-popin-activation:after {
	border-color: rgba(136, 183, 213, 0);
  //border-bottom-color: $cprimary-5;
  border-bottom-color: $corange;
	border-width: 10px;
	margin-left: -10px;
}
.tourend:before, .tour-popin-activation:before {
	border-color: rgba(194, 225, 245, 0);
  //border-bottom-color: $cprimary-5;
  border-bottom-color: $corange;
	border-width: 16px;
	margin-left: -16px;
}
.tourend-content {
  color: $cprimary-12;
}

.tour-popin-activation {
  top: 145%;
  font-weight: bold;
  font-size: 16px;
  //background: $cprimary-5;
  background: $cwhite;
  border: 6px solid $corange;
  color: $cprimary-12;
  left: 0;
  border-radius: 6px;
  padding: 10px 20px;
  position: absolute;
  z-index: 999999;
  width: 250px;
  transform: translate(-35%, 0);
  // .arrowtop {
  //     left: 50%;
  //     margin-left: -11px;
  //     border-bottom-width: 0;
  //     border-top-color: #999999;
  //     border-top-color: rgba(0, 0, 0, 0.25);
  //     bottom: -11px;
  //     position: absolute;
  //     display: block;
  //     width: 0;
  //     height: 0;
  //     border-color: transparent;
  //     border-style: solid;
  //     border-width: 11px;
  //   }
  //   .arrowtop:after {
  //     content: " ";
  //     bottom: 1px;
  //     margin-left: -10px;
  //     border-bottom-width: 0;
  //     border-top-color: #fff;
  //     position: absolute;
  //     display: block;
  //     width: 0;
  //     height: 0;
  //     border-color: transparent;
  //     border-style: solid;
  //   }
}

.tour-popin-activation button {
  background: $cwhite;
  color: $corange;
  border: 0;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.guide-switcher {
  height: 40px;
  align-items: center;
  display: flex;
  margin-left: 20px;
  label {
    margin-bottom: 0;
  }
}

.popover-return {
  //background: $cprimary-5;
  border: 6px solid $corange;
  background: #fff;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  color: rgba($cprimary-12, 0.5);
  font-family: 'IBM Plex Sans';
  font-weight: bold;
  cursor: pointer;
  padding: 10px 10px 0 10px;
}

.guide-switcher {
  .switch + label {
    width: 50px;
    height: 15px;
    &:after {
      top: -3px;
      left: 0;
    }
  }
  input.switch:checked + label:after {
    left: 100%;
  }
}