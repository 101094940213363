.documents-title {
   // text-align: center;
}
.toggle-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-expend-content {
    height: 15px;
    width: 15px;
   .expend-icon {
     border:1px solid $cprimary-12;
     width: 15px;
     height: 0;
   }
}

.toggle-line {
    height: 0px;
    width: 100%;
    border:1px solid #d4d4d6;
    margin: 20px 0;
}

.popin.create-document {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
}

.folder-delete {
    position: absolute;
    bottom: 30px;
    right: 20px;
}