.ticket-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba($cprimary-7, 0.2);
}

// .ticket-close a.btn {
//     border-radius: 4px;
//     width: 200px;
// }

.ticket-box > div {
    display: flex;
    justify-content: flex-start;
}

.ticket-avatar {
    width: 80px;
    min-width: 80px;
    align-items: flex-start;
    justify-content: center;
}

.ticket-user .ticket-avatar img {
    border-radius: 50%;
}

.ticket-archi .ticket-avatar img {
    border-radius: 4px;
}

.ticket-box {
    border-top: 2px solid #f3f3f4;
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 2px solid #f3f3f4;
}

.ticket-box:nth-child(n+2) {
    border-top: 0;
}

textarea#new_ticket_message_thContent {
    height: 250px;
    background: #f3f3f4;
}

.ticket-form label {
    font-weight: bold;
    font-size: 24px;
}

.ticket-form {
    margin-top: 30px;
}

#new_ticket_message_submit {
    width: 200px;
    height: 48px;
    margin-left: auto;
}

.ticket-document {
    width: 120px;
}
.ticket-document .doc-view img {
    box-shadow: 0 0 3px rgba(0,0,0, 0.15);
}
.ticket-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}

.closeticket {
    opacity: 0.8;
}

select#new_ticket_thAssignedUser {
    border-radius: 100px;
    border: solid 1px $cprimary-12;
    padding: 6px 40px 6px 15px;
    font-weight: bold;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: transparent;
    background-image: url("../images/icon_dropdown_grey.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 5px;
    margin: 0 20px 10px 20px;
    cursor: pointer;
}

input#new_ticket_thSubject {
    border-radius: 4px;
    border: solid 1px #d4d4d6;
    background-color: #ffffff;
}