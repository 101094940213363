.period.popin-wrap:not(.popin-double-size) {
    height: 301px;
    padding: 40px;
    .popin-content {
        margin-top: 40px;
    }
    .popin-close {
        right: 40px;
        top: 40px;
    }
    .starting-date, .js-period-year {
        background: #f3f3f4;
        border: none;
        color: $csecondary-3;
        height: 48px;
        margin: 8px 0;
        width: 133px;
    }
    .js-send-startingDate {
        background-color: $cprimary-7 !important;
        bottom: 40px;
        box-shadow: 0px 6px 12px #00000029;
        font-size: 18px;
        height: 44px;
        position: absolute;
        right: 40px;
        width: 120px;
    }
}
.chart-container {
    width: 100%;
}
.st-openpopin-period {
    height: 36px;
    margin-right: 24px;
    width: 179px;
}
.st-openpopin-goal {
    height: 36px;
    width: 116px;
}
.st-openpopin-period, .st-openpopin-goal {
    color: #212529;
    font-size: 14px;
}
.st-openpopin-goal:hover, .st-openpopin-period:hover {
    font-weight: bold;
}
.st-tabHeader {
    line-height: 32px;
    margin-bottom: 44px;
    div {
        font-size: 14px;
        font-weight: normal;
    }
}
.header-chart {
    display: flex;
    position: relative;
}
.chart-options {
    display: flex;
}
.st-turnover-total {
    position: absolute;
    right: 0;
    b {
        cursor: initial !important;
    }
}
#chartFilter, #js-servicesSoan, .st-selectMission {
    background-color: #F8F8F8 !important;
    background-image: url("/build/images/small-down-darkBlue.svg") !important;
    border: none !important;
    border-radius: 6px !important;
    cursor: pointer !important;
    font-size: 14px !important;
    height: 40px !important;
    line-height: initial !important;
    position: relative !important;
}
#chartFilter, #js-servicesSoan {
    margin-right: 117px;
    width: 155px;
}
.st-selectMission {
    width: 180px !important;
}
.wrap {
    height: 275px;
    margin: 70px auto;
    position: relative;
    text-align: center;
    width: 100%;
}
.graph-block {
    display: flex;
    height: 100%;
    overflow: -moz-scrollbars-none;
    position: relative;
    -ms-overflow-style: none;
    width: 100%;
}
.graph-block::-webkit-scrollbar {
    background: transparent;
    width: 0;
}
.scale {
    height: 100%;
    position: absolute;
}
.scale-value {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    position: absolute;
    width: max-content;
}
.val-1 {
    bottom: 0;
}
.val-2 {
    bottom: 33%;
}
.val-3 {
    bottom: 66%;
}
.val-4 {
    bottom: 99%;
}
.graph {
    height: 100%;
    margin-left: 59px;
    position: absolute;
    width: 1101px;
}
.legend-block {
    margin: 105px auto 0;
}
.bars {
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
.bar {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: 0.6s transform ease-out;
    width: 24px;
}
.active .bar {
    transform: scaleY(1);
}
.month {
    position: relative;
    width: 56px;
}
.month::after{
    bottom: -49px;
    content: attr(data-month);
    font-size: 14px;
    left: 0;
    line-height: 18px;
    position: absolute;
    text-transform: lowercase;
    right: 0;
}
.month.actualMonth:after {
    font-weight: bold;
}
.st-goal {
    background-color: $cprimary-6;
    border: 1px solid $cprimary-10;
    border-radius: 30px;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 4px;
    width: 15px;
    z-index: 1;
}
.st-entree {
    background-color: $cprimary-12;
    min-height: 1px;
    right: 0;
    z-index: 1;
}
.st-sortie {
    background-color: $cprimary-6;
    min-height: 1px;
}
.st-facturee {
    background-color: $cprimary-2;
    right: 0;
    z-index: 0;
}
.tooltip-legend {
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    box-shadow: 0px 6px 12px #00000029;
    color: $cprimary-12;
    display: flex;
    font-size: 14px;
    height: 80px;
    min-width: 200px;
    opacity: 0;
    padding: 15px 10px;
    position: absolute;
    text-align: left;
    visibility: hidden;
    width: max-content;
}
.bar.tooltip-left .tooltip-legend, .st-goal.tooltip-left .tooltip-legend{
    margin-left: 0;
    right: 40px;
}
.bar:hover .tooltip-legend, .st-goal:hover .tooltip-legend {
    opacity: 1;
    visibility: visible;
}
.bar .tooltip-legend {
    margin-left: 54px;
}
.st-goal .tooltip-legend {
    margin-left: 40px;
}
.responsive-tooltip {
    display: none;
    text-align: left;
}
.st-legend {
    display: flex;
    font-size: 13px;

    span {
        cursor: pointer;
        line-height: 18px;
        margin-left: 8px;
    }
    span:hover {
        font-weight: bold;
    }
    p {
        border-radius: 8px;
        cursor: pointer;
        height: 18px;
        width: 28px;
    }
    .legend-out {
        background-color: $cprimary-6;
    }
    .legend-out.border-out{
        background: none;
        border: 1px solid $cprimary-6;
    }
    .legend-in {
        background-color: $cprimary-12;
    }
    .legend-in.border-in {
        background: none;
        border: $cprimary-12 1px solid;
    }
    .legend-billed {
        background-color: $cprimary-2;
        line-height: 20px;
    }
    .legend-billed.border-billed {
        background: none;
        border: 1px solid $cprimary-2;
    }
    .legend-goals {
        background: $cprimary-6 0% 0% no-repeat padding-box;
        border: 1px solid $cprimary-10;
        border-radius: 30px;
        height: 15px;
        width: 15px;
    }
    .legend-goals.border-goals {
        background: none;
        border: 1px solid #074B3A;
    }
    .legend-in, .legend-billed, .legend-goals {
        margin-left: 35px;
    }

}