@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'components/colors.scss';
@import 'components/buttons';
@import 'components/forms';
@import 'components/navbar';
@import 'pages/home.scss';
@import 'pages/login.scss';
@import 'pages/register.scss';
@import 'pages/new-project.scss';
@import 'pages/dashboard.scss';
@import 'pages/all-documents.scss';
@import 'pages/profil.scss';
@import 'components/doc';
@import 'pages/document.scss';
@import 'components/popin';
@import 'components/folder';
@import 'components/menu';
@import 'components/tools';
@import 'components/datepicker';
@import 'pages/new-document.scss';
@import 'pages/activity.scss';
@import 'pages/ticket.scss';
@import 'pages/account.scss';
@import 'pages/search.scss';
@import 'pages/calendar.scss';
@import 'pages/payment.scss';
@import 'components/tour';
@import 'components/autocomplete';
@import 'components/cookie';
@import 'pages/account_notifications.scss';
@import 'components/table';
@import 'components/dropzone';
@import 'pages/new-payment.scss';
@import 'pages/user-management.scss';
@import 'pages/bill-management.scss';
@import 'pages/subscription.scss';
@import 'pages/recovery.scss';
@import 'pages/efacture.scss';
@import 'pages/financialFlow.scss';
@import 'pages/adminstats.scss';
@import 'pages/payment-out.scss';
@import 'pages/approval.scss';
@import 'components/responsive';
@import 'utilities/general.scss';
@import 'utilities/size.scss';
@import 'utilities/font.scss';
@import 'utilities/margin-padding.scss';
@import 'pages/home-dashboard.scss';
@import "components/_chart.scss";
@import 'components/avatar.scss';
@import 'components/mail.scss';
@import 'pages/signature.scss';
@import 'pages/promo.scss';
@import 'pages/kyc.scss';
@import 'components/swal-plugin';
@import "pages/supplier/supplier.scss";
@import "pages/client/client.scss";
@import "pages/supplier/supplierPayment.scss";
@import 'components/responsive';
@import 'pages/adminFraud.scss';
@import 'pages/marketingStats.scss';

html {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
}
h1, h2, h3, h4, h5, h6, .title, .popin-title {
    font-family: 'Poppins', sans-serif;
}
ul {
    padding: 0;
}

.italic {
    font-style: italic;
}

a, input, button, textarea, select {
    outline: 0;
    &:hover {
        outline: 0;
        text-decoration: none !important;
    }
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
        box-shadow: none !important;
    }
}
.form-control:focus {
    box-shadow: none !important;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: none !important;
}

.w-auto {
    width: auto !important;
}

.body-content {
    width: 100%;
    position: relative;
}

// [class^="js-"]:not(input) {
//     cursor: pointer;
// }

.cwhite label {
    color: #fff;
}

.home-btn-black {
    border: 1px solid $cprimary-12;
}

.vertical-menu {
    width: 180px;
    position: relative;

}

.feedback .i {
    font-style: italic;
    font-size:90%;
}

.feedback {
    position: absolute;
    bottom: 30px;
    width: 80%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%);
}

.feedback .btn-primary {
    background: #fff !important;
    border: 1px solid $cprimary-7 !important;
    color: $cprimary-7 !important;
}
.recover-garbage {
    max-width: unset;
}
input.search-input {
    // old border-radius: 24px;
    border-radius: 4px;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 6px 15px 6px 50px;
    height: 35px;
    border: solid 1px #d4d4d6;
    background-color: rgba(212, 212, 214, 0);
}
.naked-header {
    header {
        height: 60px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        background-color: $cprimary-5;
        .login {
            display: none;
        }
        .search {
            display: none;
        }
    }
    .body-content .content {
        display: flex;
        justify-content: space-between;
    }
    .header-flex {
        justify-content: space-between;
    }
}
.search form {
    width: 100%;
    padding: 0px 0px 0px 15px;
}
.app {
    header {
        height: 50px;
        position: fixed;
        z-index: 99999;
    }
    .header-black {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        background-color: $cprimary-12;
    }
    .header-white {
        background-color: #FFFFFF;
        border: none;
        box-shadow: none;
    }
    .body-content {
        padding-top: 50px;
    }
    .body-content .content {
        display: flex;
        justify-content: space-between;
    }
    .st-iconBell {
        height: 25px;
        margin: 0 20px;
        width: 25px;
    }
    .st-blocNotificationBell.unread:before {
        background-color: $cred;
        border : 1px solid $cred;
        border-radius: 8px;
        content: " ";
        color: $cred;
        height: 15px;
        position: absolute;
        right: 13px;
        top: -2px;
        width: 15px;
    }
    .st-blocNotificationBell p {
        position: relative;
    }
    .st-blocNotificationBell p.not-read {
        background-color: $cprimary-1;
    }
    .second-header {
        width: 25%;
    }

    .body-center {
        width: 70%;
    }
}

header#header {
    height: 50px;
    width: 100%;
    border: 0 !important;
}  
li.nav-item {
    display: inline-block;
    list-style: none;
    text-align: center;
    width: auto;
    a {
        color: #fff;
    }  
}

.menu-links .bullet {
    margin-left: 5px;
}

ul.navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.msg-flash {
    position: fixed;
    width: 60%;
    left: 50%;
    transform: translate(-50%);
    top: 50px;
    font-weight: bold;
    z-index: 10000;
    // .alert {
    //     box-shadow: 0 0 8px rgba(0,0,0,0.15);
    //     margin-bottom: 0;
    // }
    & h3 {
        color: $cprimary-12;
    }
    & .success {
        background-color: #F3FCFA;
        border: 1px solid #AFE9DB;
        border-radius: 10px;
        padding: 20px;
    }
    & .warning {
        background-color: #FFFCF6;
        border: 1px solid #FCEAC4;
        border-radius: 10px;
        padding: 20px;
    }
    & .error {
        background-color: #FEF8F7;
        border: 1px solid #F9CDC9;
        border-radius: 10px;
        padding: 20px;
    }
    & .information {
        background-color: #FFFFFF;
        border: 1px solid #F8F8F8;
        border-radius: 10px;
        padding: 20px;
    }
    & div:not(:first-child) {
        margin-top: 10px;
    }
}
input {
    color: $cprimary-12;
}
.menu-mobile {
    display: none;
}
.view-full {
    overflow: hidden;
    header#header {
        height: 50px;
        width: 100%;
        border: 0 !important;
    }  
    li.nav-item {
        display: flex;
        align-items: center;
        list-style: none;
        text-align: center;
        // overflow: hidden;
        //Bug js-tour-popin
        height: 40px;
        width: auto;
        a {
            color: #fff;
            max-height: 40px;
        }  
    }
    
    ul.navbar-nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
    }

    .body-content {
        display: flex;
        justify-content: flex-start;
        .vertical-menu {
            width: 280px;
            padding: 20px;
            overflow: auto;
            height: calc(100vh - 50px);
            //position: relative;
            z-index: 9;
            display: flex;
            flex-direction: column;
            //background: #fff;
            color: $cprimary-12;
            .vertical-menu-close {
                display: none;
            }
            a.js-close-menu img{
                display: none;
            }
            a {
                color: $cprimary-12;
                &.active {
                    color: $cprimary-7 !important;
                    background-color: transparent;
                }
                &:focus {
                    box-shadow: none;
                }
            }
            i + a {
                padding-left: 15px;
            }
            hr {
                width: 90%;
                border-color: #ccc;
                margin-top: 2rem;
                margin-bottom: 2.5rem;
                background-color: transparent;
            }
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 5%;
                width: 1px;
                height: 90%;
                background: #ccc;
            }
        }
        .soancontainer {
            width: calc(100% - 280px);
            height: calc(100vh - 50px);
            overflow-y: auto;
            padding: 40px 40px 100px 90px;
            flex: 1;
            > section {
                // max-width: 1140px;
                position: relative;
                h1, .title {
                    //text-align: center;
                    margin: 20px;
                    margin-left: 0;
                    font-size: 56px;
                    font-weight: bold;
                    word-break: break-word;
                    span {
                        font-size: 16px;
                        //text-align: center;
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .soancontainer-center {
            width: calc(100%);
            height: calc(100vh - 50px);
            overflow-y: auto;
            flex: 1;
            padding: 40px;
        }
    }
}

.kyc-row {
    .custom-file-label {
        width: auto;
        // overflow: hidden;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .custom-file-label::after {
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        position: static;
        content: 'Parcourir';
    }

    .custom-file {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .custom-file input + label {
        display: flex;
        flex-direction: column-reverse;
    }
}

.popin-image-load {
    .custom-file-label {
        width: 100%;
        // overflow: hidden;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .custom-file-label::after {
        content: attr(data-content);
        z-index: 1;
    }

    .custom-file-input {
        position: fixed;
        width: 90%;
    }

    .custom-file {
        display: block;
    }

    .custom-file input + label {
        display: flex;
        flex-direction: column-reverse;
    }
}


img {
    max-width: 100%;
}

.bg-grey {
    background: #f2f2f2;
    header {
        border-bottom: 0 !important;
    }
    .body-center {
        width: 550px;
        margin: 40px auto;
    }
    .form-control {
        background: transparent;
        border: 1px solid #707070;
    }
}

.bg-black {
    background: $cprimary-12;
    color: #fff;
    select option {
        color: $cprimary-12;
    }
    
    select {
        color: #fff;
    }
    header {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        border-bottom: 0 !important;
    }
    .body-content {
        .vertical-menu {
            background: $cprimary-12;
            a {
               color: #fff !important; 
               &.active {
                    color: $cprimary-7 !important;
                    background-color: transparent;
                }
                &:focus {
                    box-shadow: none;
                }
            }
            i {
                color: #fff !important;
            }
            hr {
                border-top: 1px solid rgba(255,255,255,1);
                background-color: transparent;
            }
            .menu-project-name {
                color: #fff;
            }
        }
    }
    label.custom-file-label {
        background: rgba(255, 255, 255, 0.15);
        border-color: rgba(255, 255, 255, 0.15);
    }
}

.strong {
    font-size: 24px;
    font-weight: bold;
}

.title {
    font-size: 36px;
    font-weight: bold;
}

.sstitle {
    font-weight: bold;
    font-size: 24px;
    color: $cprimary-12;
    margin: 20px 0;
}

.center-content .body-content{
    height: calc(100% - 50px);
    .soancontainer {
        min-height: 100%;
    }
}

.custom-file-label {
    position: static;
    width: 100%;
    // overflow: hidden;
    top: 39px;
    right: auto;
    left: auto;
    bottom: auto;
}

.errors {
    display: none;
    color: #fff;
    background: #D9534F;
    border-radius: 6px;
    padding: 10px 20px;
    max-width: 100%;
    &.active {
        display: block !important;
    }
}

.form-group.grouped {
    display: flex;
}

.form-group.grouped button {
    width: 31px;
    color: #fff;
    padding: 0;
    border: 0;
    font-size: 12px;
    margin-left: 10px;
    background: rgb(211,211,211);
    cursor: pointer;
}

.round-avatar {
    border-radius: 50%;
    border: solid 1px rgba(0, 0, 0, 0.1);
}
.avatar-company {
    border-radius: 10px;
}
.avatar-color {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.ui-datepicker .ui-datepicker-next {
    right: 1px;
    top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 1px;
    top: 1px;
}

.smallcontainer {
    width: 750px;
    //margin-left: 120px;
}

.view-full .body-content .soancontainer > section.smallcontainer h1, .view-full .body-content .soancontainer > section.smallcontainer .title {
    padding: 0;
    margin: 0;
}

.smallcontainer h1 span a {
    font-size: 16px;
    text-decoration: underline;
}

.hide-mobile-inline {
    display: inline;
}
.show-mobile {
    display: none;
}
legend.required:after {
    content: "*";
    position: absolute;
    right: -25px;
    top: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: $cred;
}
legend.required {
    position: relative;
    width: fit-content;
}

/*Label add auto * when required class*/
label.required:before {
    content: "*";
    position: absolute;
    right: -25px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: $cred;
}

.ticket-form label.required:before {
    display: none;
}

label.required {
    position: relative;
}

// .page-stepfolder .folder-content {
//     flex-wrap: nowrap;
// }

.badge {
    display: none;
}

.loader {
    background: rgba(255,255,255,1);
    height: calc(100% - 50px);
    left: 0;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 999999;
    .loader-inside {
        position: absolute;
        z-index: 9999999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loader-popin {
    background: rgba(255,255,255,0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    left: 0;
    display: none;
    .loader-inside {
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.loader-upload {
    background: rgba(255,255,255,0.9);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    .loader-inside {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999;
    }
}

.disable {
    background: #ccc !important;
    &:hover {
        background: #ccc !important;
    }
}

#mytable td:last-child, #mytable1 td:last-child, #mytable2 td:last-child {
    text-align: right;
    width: auto;
    white-space: nowrap;
}

/*Folder*/
.folder {
    background: url("../images/folder.svg") no-repeat;
}

/*table*/
table.dataTable thead .sorting {
    background-image: url("../images/sort_black.svg");
    background-repeat: no-repeat;
    background-position: center left;
  }
  table.dataTable thead .sorting_asc {
    background-image: url("../images/sort_asc.png");
    background-repeat: no-repeat;
    background-position: center left;
  }
  table.dataTable thead .sorting_desc {
    background-image: url("../images/sort_desc.png");
    background-repeat: no-repeat;
    background-position: center left;
  }
  table.dataTable thead .sorting_asc_disabled {
    background-image: url("../images/sort_asc_disabled.png");
    background-repeat: no-repeat;
    background-position: center left;
  }
  table.dataTable thead .sorting_desc_disabled {
    background-image: url("../images/sort_desc_disabled.png");
    background-repeat: no-repeat;
    background-position: center left;
  }

  .table-info-stats {
      & thead .sorting {
        background-position: center right !important;
      }
      & thead .sorting_asc {
        background-position: center right !important;
      }
      & thead .sorting_desc {
        background-position: center right !important;
      }
      & thead .sorting_asc_disabled {
        background-position: center right !important;
      }
      & thead .sorting_desc_disabled {
        background-position: center right !important;
      }
  }

  /**/
  .step.active:not(.active-next) .step-content, .step.active:not(.active-prev) .step-content{
    //width: 100%;
    height: 240px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    .step-date.icon-pen {
        display: block;
    }
    .step-picto {
        width: 75%;
        height: 130px !important;
    }
    .picto-prog {
        background: url("../images/programme.png");
        width: 100%;
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 130px;
    }
    .picto-phase {
        background: url("../images/phases.png");
        width: 100%;
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 130px;
    }
    .picto-admin {
        background: url("../images/demarches.png");
        width: 100%;
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 120px;
    }
    .picto-pre {
        background: url("../images/preparation.png");
        width: 100%;
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 120px;
    }
    .picto-deb {
        background: url("../images/chantier.png");
        width: 100%;
        background-size: contain;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 130px;
    }
}


.step-picto {
    width: 90%;
    height: 100px !important;
}

.picto-prog {
    background: url("../images/programme_gris.png");
    width: 100%;
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;
    height: 100px;
}
.picto-phase {
    background: url("../images/phases_gris.png");
    width: 100%;
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;
    height: 100px;
}
.picto-admin {
    background: url("../images/demarches_gris.png");
    width: 100%;
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;
    height: 70px;
}
.picto-pre {
    background: url("../images/preparation_gris.png");
    width: 100%;
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;
    height: 70px;
}
.picto-deb {
    background: url("../images/chantier_gris.png");
    width: 100%;
    background-size: contain;
    background-position-x: right;
    background-repeat: no-repeat;
    height: 100px;
}

.cookie-title, .cookie-text {
    color: $cprimary-12;
}

.soancalendar-step:nth-child(odd) {
    background-color: rgba(129, 138, 212, 0.1);
}

.no-header {
    header#header {
        display: none;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .page-stepfolder .folder-content {
        flex-wrap: wrap;
    }
 }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .view-full .body-content .soancontainer > section {
        max-width: 100%;
        width: 100%;
        position: relative;
        margin: 0;
    }
    .view-full .body-content .soancontainer > section h1, .view-full .body-content .soancontainer > section .title {
        font-size: 24px;
        overflow: hidden;
    }
    .view-full .body-content .soancontainer {
        padding: 40px;
    }
}

.addtohome {
    position: absolute;
    bottom: 10px;
    padding: 10px;
    right: 10px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.35);
    z-index: 999;
    display: none;
}

/*General*/
.container {
    display: flex;
    justify-content: space-between;
    aside {
        width: calc(35% - 10px);
        margin-right: 10px;
    }
    section {
        width: calc(65% - 10px);
        margin-left: 10px;
    }
}

.min-width-200px {
    min-width: 200px;
}

.logoSoan-noConnected {
    justify-content: center !important;
}


.popin-form-align {
    .custom-file-label::after {
        position: absolute;
        top: 37px;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: calc(1.6em + 0.75rem);
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
        content: "Browse";
        background-color: #e9ecef;
        border-left: inherit;
        border-radius: 0 0.25rem 0.25rem 0;
    }
}

.msg-flash-react {
    font-weight: bold;
    left: 50%;
    position: fixed;
    top: 60px;
    transform: translate(-50%);
    width: 60%;
    z-index: 10000;
    h3 {
        color: $cprimary-12;
    }
    & .success {
        background-color: #F3FCFA;
        border: 1px solid #AFE9DB;
        border-radius: 10px;
        padding: 20px;
    }
    & .warning {
        background-color: #FFFCF6;
        border: 1px solid #FCEAC4;
        border-radius: 10px;
        padding: 20px;
    }
    & .error {
        background-color: #FEF8F7;
        border: 1px solid #F9CDC9;
        border-radius: 10px;
        padding: 20px;
    }
    & .information {
        background-color: var(--cwhite);
        border: 1px solid #F8F8F8;
        border-radius: 10px;
        padding: 20px;
    }
    & div:not(:first-child) {
        margin-top: 10px;
    }
}