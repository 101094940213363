.payment-out {
    background-color: $csecondary-1;
    width: 100%;
    & .content-out {
        background-color: $csecondary-1;
    }
    .header-out {
        text-align: center;
        height: 120px;
        position: relative;
        background: #F0F0F0;
    }
    .header-out-text {
        font-size: 24px;
        padding-top: 10px;
    }
    .content-out {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    .company-table-header {
        padding-bottom: 7px;
        // border-bottom: 1px solid rgba(127,127,127, 0.2);
        position: relative;
        justify-content: unset;
        height: 75px;
    }

    .company-to-pay {
        line-height: 50px;
        margin-top: 23px;
        width: 140px;
        color: #7F7F7F;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        &.active {
            .cp-title {
                border-bottom: 2px solid $cprimary-11;
                color: $cprimary-12;
                font-family: Poppins, sans-serif;
                font-weight: bold;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }
        }
    }
    .fs10 {
        font-size: 10px !important;
    }

    .text-pay {
        line-height: 16px;
    }
    .text-pay img {
        margin-bottom: 6px;
        margin-right: 5px;
    }
    .virement {
        line-height: 44px;
        text-transform: none;
        box-shadow: 0px 6px 12px #00000029;
        background-color: $cprimary-12;
        color: white;
    }
    .company-payed {
        margin-left: 40px;
        left: 180px;
        line-height: 50px;
        margin-top: 23px;
        color: #7F7F7F;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        &.active {
            .cp-title {
                font-weight: bold;
                color: $cprimary-12;
                border-bottom: 2px solid $cprimary-11;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
            }
        }
    }

    .company-recap {
        box-shadow: 0px 3px 6px #00000029;
        //border: 1px solid #70707084;
        border-radius: 5px;
        padding: 16px;
        width: 25%;
        height: fit-content;
    }
    .height-no-payment {
        height: 241px;
    }
    .no-payment {
        text-align: center;
    }
    .no-payment p {
        margin-top: 10px;
    }
    .company-name {
        font: normal normal bold 22px/34px Poppins;
        margin-bottom: 30px;
        margin-top: 40px;
        text-align: center;
    }

    .company-info {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000029;
        min-height: 650px;
        padding: 0 50px;
        width: 650px;
        z-index: 0;
    }

    .header-out-logo {
        border: 1px solid $csecondary-1;
        width: 100px;
        height: 100px;
        left: calc(50% - 50px);
        position: absolute;
        background: #fff;
        overflow: hidden;
        border-radius: 50%;
        top: 65px;
        padding: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .content-to-pay .content-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding-top: 15px;
        margin-bottom: 70px;
        // border-top: 2px solid rgba(127,127,127,0.2);
        &:first-child {
            border-top: 0;
            margin-top: 50px;
        }
        &:last-child {
            //border-bottom: 2px solid rgba(127,127,127,0.2);
        }
    }

    .content-to-pay {
        margin-top: 30px;
    }
    
    .content-payed {
        margin-top: 30px;
        padding-bottom: 5px;
    }

    .content-payed .content-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding-top: 15px;
        margin-bottom: 70px;
        //border-top: 2px solid rgba(127,127,127,0.2);
        &:first-child{
            border-top: 0;
            margin-top: 50px;
        }
        &:last-child {
            //border-bottom: 2px solid rgba(127,127,127,0.2);
        }
    }

    .content-payed .content-payed-empty {
        left: 0;
        margin: 0 auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .company-table-header {
        display: flex;
    }

    .cp-title {
        font-size: 14px;
        width: 158px;
        text-align: center;
    }
    .footer-out.container-fluid {
        background: $csecondary-1;
        bottom : 0;
        display: flex;
        height: 87px;
        justify-content : center;
        left: 0;
        padding: 20px 15px;
        position: fixed;
        right: 0;
    }
    .w-70 {
        display: flex;
        justify-content: space-evenly;
        width: 70%;
    }
    .w-25 {
        // padding: 15px;
        width: 25%;
    }
    .secure-block {
        display: flex;
        align-items: center;
        //border-right: 1px solid #000000;
        height: 95px;
        //margin-right: 25px;
        padding: 20px 0;
        //width: 50%;
    }
    .secure-block img.cadenas {
        height: 51px;
        margin-right: 2%;
        vertical-align: middle;
        width: 19%;
    }
    .secure-block b {
        display: inline-block;
        margin-right: 5%;
        padding-top: 10px;
        text-align: center;
        vertical-align: middle;
        width: 137px;
    }
    .secure-block img.logo-payment {
        vertical-align: middle;
        width: 28%;
        border-right: 2px solid rgba(0,0,0, 0.5);
        padding-right: 25px;
    }
    .footer-out.container-fluid div.text-simple {
        display: inline-block;
        padding: 15px 0;
        width: 50%;
    }
    .footer-out.container-fluid .btn {
        text-transform: none;
    }
    .footer-out.container-fluid .btn:hover {
        background: #0096FF;
    }
    .footer-out.container-fluid .btn a:hover {
        color: #FFFFFF;
    }
    .inscription {
        height: 44px;
        line-height: 44px;
        width: 228px;
    }

    .js-price, .js-oldprice{
        color: $cprimary-12;
    }
    .st-escomptePrice {
        color: $cprimary-12 !important;
        font-weight: bold !important;
        font-size: 14px;
    }
    .st-blocCompanyInfos {
        display: flex;
        flex-direction: column;
        width: 270px;
        height: 315px;
        background-color: white;
        box-shadow: 0px 1px 3px #4444441A;
        border-radius: 10px;
        margin-right: 15px;
        & .header-out-logo-img {
            border-radius: 10px;
            height: 100px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            width: 100px;
        }
        & .st-iconPhone {
            margin-right: 23px;
        }
        & .st-iconMail {
            margin-left: 23px;
        }
        & .st-companyInfos {
            display: flex;
            flex-direction: column;
        }
    }
    .st-containerOffline {
        margin-top: 21px;
        justify-content: center;
        display: flex;
    }

    .st-emptyBlocOffline {
        width: 260px;
        margin-right: 32px;
    }

    .js-name {
        color: $cprimary-12;
        font-size: 14px;
        opacity: 0.8;
    }
    
    .st-dateOffline, .st-payedDateOffline {
        color: $csecondary-3;
        & img {
            margin-right: 5px;
        }
    }


    .st-lockTextOffline {
        color: $cprimary-12;
        font-size: 12px !important;
        font-style: italic;
    }

    .st-blocIconLock {
        margin-top: 21px;
        text-align: center;
        & img {
            margin-right: 5px;
        }
    }

    .st-iconOffline {
        margin-right: 5px;
    }

    .st-iconOfflineEscompte {
        margin-right: 2px;
        margin-top: -2px;
    }

    .st-copiedPhoneNumber {
        position: absolute;
        background-color: $cprimary-7;
        color: white;
        top: 30px;
        padding: 8px;
        border-radius: 10px;
        display: none;
    }

    .st-phoneHover {
        display: none;
        position: absolute;
        background-color: $cprimary-7;
        color: white;
        top: -30px;
        padding: 2px 6px;
        border-radius: 6px;
    }

    .st-iconPhone:hover ~ .st-phoneHover {
        display: block;
    }

    .st-paymentOfflineBloc {
        margin-top: 50px;
    }
    .st-block-payment-choices {
        min-height: 390px;
    }
    .st-blocTotalOffline {
        width: 198px;
        background-color: #F8F8F8;
        padding: 12px;
        margin-left: auto;
        margin-right: 0;
        border-radius: 6px;
        margin-bottom: 50px;
        & label, input, div {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    #js-totalOffline {
        padding-left: 30px;
    }

    [type="checkbox"] {
        position: absolute;
        left: 0;
        top: 20px;
        display: none;
    }
    
    [type="checkbox"] + label {
        position: relative;
        padding-left: 2.3em;
        font-size: 1.05em;
        line-height: 1.7;
        height: 28px;
        cursor: pointer; 
    }

    /* Aspect de la case */
    [type="checkbox"]:checked + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 17px;
        height: 17px;
        border: 2px solid $cprimary-12;
        background-color: $cprimary-12;
        border-radius: 3px;
    }
    [type="checkbox"]:not(:checked) + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 17px;
        height: 17px;
        border: 2px solid $cprimary-12;
        background-color: white;
        border-radius: 3px;
    }

    /* Aspect de la coche */
    [type="checkbox"]:checked + label::after{
        content: attr(data-content);
        position: absolute;
        font-size: 12px;
        color: white;
        width: 12px;
        height: 12px;
        top: 6px;
        left: 4px;
    }

    /* Aspect non cochée */
    [type="checkbox"]:not(:checked) + label::after {
        opacity: 0;
        transform: scale(0) rotate(45deg);
    }

    /* Aspect cochée */
    [type="checkbox"]:checked + label::after {
        opacity: 1;
        transform: scale(1) rotate(0);
    }

    .st-iconDownload {
        margin-right: 10px;
        cursor: pointer;
    }

    .st-iconEye {
        margin-right: 5px;
        cursor: pointer;
    }

    .st-infosPaymentOffline {
        height: auto;
        width: 51%;
    }

    .st-atoutSoan, .st-atoutSoanUsed {
        height: auto;
        width: 35%;
    }

    .js-total {
        text-align: right;
        cursor: initial;
    }

    .st-blocFactureOffline {
        width: 90%;
    }
    .st-blockCheckbox {
        margin-top: 8px;
    }
    .st-blocPriceOffline {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 20px;
    }

    .st-emptyBillToPay {
        text-align: center;
        font-weight: bold;
    }

    .js-autocompleteBank {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0px 3px 15px #02130E1A;
        display: none;
        left: 0;
        position: absolute;
        top: 72px;
        width: 270px;
        z-index: 10;
        .js-supplierAuto {
            color: $cprimary-12;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            min-height: 40px;
            margin-bottom: 0;
            padding: 10px 15px;
            word-break: break-all;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
}
.popin-bankwire-infos {
    .st-type-bank {
        width: 100%;
        &::placeholder {
            font-style: italic;
        }
    }
    .js-autocompleteBank {
        background-color: $cwhite;
        border-radius: 6px;
        box-shadow: 0px 3px 15px #02130E1A;
        display: none;
        left: 50px;
        position: absolute;
        width: 450px;
        z-index: 10;
        .js-bankAuto {
            color: $cprimary-12;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            min-height: 40px;
            margin-bottom: 0;
            padding: 10px 15px;
            word-break: break-all;
            &:hover {
                background-color: $csecondary-1;
            }
        }
    }
}
.popin-payment {
    .value-payment {
        color: #000000;
        display: inline-block;
        vertical-align: middle;
    }

    .click-to-copy {
        font-size: 10px;
        font-weight: normal;
        left: 0;
        position: absolute;
        right: 0;
        opacity: 0;
    }

    .copyonclick p {
        color: #00000080;
        display: inline-block;
        width: 50%;
    }

    .w-55 {
        width: 50%;
    }

    .w-fix-100 {
        width: 100px;
    }

    .reference-content {
        justify-content: space-between;
        width: 45%;
    }

    #cp1 img, #cp2 img, #cp3 img, #cp4 img {
        opacity: 0;
    }

    #cp1:hover img, #cp2:hover img, #cp3:hover img, #cp4:hover img, #cp1:hover img + .click-to-copy, #cp2:hover img + .click-to-copy, #cp3:hover img + .click-to-copy, #cp4:hover img + .click-to-copy {
        cursor: pointer;
        opacity: 1;
    }
}

.popin .popin-company-infos {
    .all-company-infos {
        margin: 0 auto;
        width: 70%;
    }
    .th-business-name {
        display: block;
        font-size: 20px;
        margin-bottom: 35px;
    }
}
.popin-card-form, .popin-bankDebit, .popin-paying-3xFreeOfCharge, .popin-bankwire-infos {
    padding-bottom: 30px;
}
.js-directDebitWithMandate, .js-sendAuthenticationCode {
    background-color: $cprimary-12;
}
.bouclier {
    margin-bottom: 3px;
    margin-right: 5px;
}
.link-blue {
    color: #0076FF;
    text-decoration: underline;
}
.st-grey-title {
    color: #ABB0AF;
    font-size: 42px;
    margin-bottom: 0;
}
.title-lock img {
    height: 32px;
    margin-bottom: 6px;
    margin-right: 5px;
    width: 32px;
}
.cards-logo {
    margin-bottom: 0;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
}
.st-submitCard, .st-bankwire, .st-prelevement {
    background: $cprimary-12 !important;
}
.assurance {
    padding: 25px 20px;
}
.sepa-banner {
    background: #F3F4F9;
    border-radius: 6px;
    color: #10298E;
    height: 34px;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;
}
.sepa-banner p {
    line-height: 34px;
}
.popin-bottom {
    margin-top: 50px;
}
.popin-bottom p {
    color: #ABB0AF;
    font-size: 10px;
}
.st-payment-propulse {
    width: 270px;
}
.st-underline {
    text-decoration: underline;
}
.footer-offline {
    background-color: #FFFFFF;
    bottom: 0;
    height: 154px;
    left: 0;
    margin-top: 50px;
    // position: fixed;
    right: 0;
    .footer-container {
        height: 154px;
        margin: 0 auto;
        width: 935px;
    }
}
.payment-out {
    .st-containerOfflineMobile,  {
        display: none;
    }
}
@media (max-width: 991.98px) {
    .payment-out {
        .footer-out div.container {
            display: flex;
            justify-content: center;
        }
        .company-recap {
            padding-bottom: 160px;
        }
        .footer-out.container-fluid div.text-simple {
            display: none;
        }
        .st-discoverySoanOffline .st-listOffline.security {
            margin: 20px 0;
            width: 100%;
        }
    }
}

@media (max-width: 767.98px) {
    .st-payment-propulse {
        width: 100%;
    }
    .footer-offline {
        display: none;
    }
    .payment-out {
        .secure-block img.cadenas {
            display: none;
        }
        .secure-block b {
            font-size: 12px !important;
            margin-right: 10%;
            width: 50%;
        }
        .inscription {
            font-size: 12px !important;
            height: auto;
            line-height: normal;
            width: auto;
        }

    }
    .popin .popin-company-infos .all-company-infos {
        width: 100%;
    }
}
@media (max-width: 575.98px) {
    .payment-out {
        .w-70 {
            width: 50%;
        }
        .secure-block {
            height: 70px;
        }
        .secure-block b {
            font-size: 10px !important;
        }
    }
}
@media (max-width: 500px) {
    .content-out {
        .content-item .w-50 .fs16 {
            font-size: 14px !important;
        }
        .content-item .w-50 .font-italic.cgrey {
            font-size: 12px !important;
        }
        .content-item .w-50 .d-flex.align-items {
            font-size: 10px !important;
        }
        .content-item .w-50 .cblue.font-italic {
            font-size: 10px;
        }
        .text-right .fs20 {
            font-size: revert !important;
        }
    }
}
@media (max-width: 400px) {
    .payment-out {
        .secure-block {
            margin-right: 10px;
        }
        .secure-block b {
            font-size: 8px !important;
        }
        .secure-block img.logo-payment {
            width: 35%;
        }
    }
}

.iconCompanyName {
    justify-content: center;
    & div {
        border: 1px #3A4050 solid;
        border-radius: 8px;
        width: 30px;
        height: 30px;
        text-align: center;
        margin-bottom: 26px;
        position: relative;
    }
    & div:nth-child(1) {
        margin-right: 13px;
    }
    & div:nth-child(2) {
        margin-left: 13px;
    }
    & img {
        width: 20px;
        height: 20px;
    }
    & a {
        font-size: 1.3em;
        display: block;
        margin-top: -4px;
    }
    & .st-blocNumber {
        position: absolute;
        background-color: #394050;
        color: white;
        padding: 5px 10px 5px 10px;
        border-radius: 8px;
        top: -50px;
        left: -46px;
        display: none;
    }
    & .st-borderTriangleOfnumber {
        position: absolute;
        border-top: 12px solid #394050;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        display: none;
        top: -18px;
        left: 8px;
    }
    & div:nth-child(1):hover p, div:nth-child(1):hover .st-borderTriangleOfnumber {
        display: block;
    }
}

.st-blocSubscriptionOffline, .st-blocSubscriptionOfflineMobile {
    background-color: $cprimary-12;
    border-radius: 10px;
    box-shadow: 0px 1px 3px #4444441A;
    color: white;
    font-size: 0.8em;
    height: 315px;
    margin-bottom: 22px;
    margin-left: 0;
    margin-right: auto;
    padding: 20px 26px 20px 26px;
    width: 270px;
    & .st-discoverySoanOffline {
        border-radius: 20px;
        text-align: center;
        & h2 {
            font-size: 20px;
            font-weight: bold;
            margin-top: 15px;
        }
        & p {
            font-size: 12px;
            text-align: initial;
        }
        & .st-logoSoan {
            margin-top: 30px;
        }
    }
    & .st-listOffline {
        margin-top: 20px;
        margin-bottom: 30px;
        & p {
            margin-bottom: 0;
            font-size: 12px;
            margin-left: 15px;
        }
    }
    & .st-btnSubscriptionOffline {
        background-color: white;
        border-radius: 6px;
        color: $cprimary-12;
        cursor: pointer;
        font-size: 13px;
        font-weight: bold;
        height: 40px;
        margin: auto;
        padding: 10px;
        text-align: center;
        width: 180px;
        // &:hover {
        //     background-color: #fcd000;
        // }
    }
    & .st-btnSubscriptionOffline:lang(es) {
        background-color: white;
        color: $cprimary-12;
        cursor: pointer;
        padding: 10px;
        border-radius: 6px;
        text-align: center;
        font-weight: bold;
        width: 190px;
        height: 40px;
        margin: auto;
        font-size: 13px;
    }
}

.st-factureNameOffline {
    margin-top: auto;
    margin-bottom: auto;
}

.st-containerhasPayed {
    max-width: initial;
}

.st-escompteLabelOffline {
    background-color: $cprimary-7;
    color: white;
    font-family: 'Roboto', sans-serif;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 7px;
    font-size: 10px;
    font-weight: 600;
    width: 104px;
    margin-top: 4px;
}

.st-crossOffline, .st-crossPopinError {
    margin-right: 20px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
}

.st-3xOffline {
    background-color: #ECFDF9;
    height: 40px;
    border-radius: 6px;
    margin-top: -50px;
    margin-bottom: 30px;
    & p {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 20px;
    }
}

.st-calculEscompte, .st-available3x, .st-atoutUsed {
    font-style: italic;
    color: $cprimary-7;
}

.st-escompteOffline, .st-bloc3xOffline, .st-atoutSoanUsed {
    margin-left: 10px;
    & p {
        margin-bottom: 0;
    }
}

.st-payedBtnOffline {
    background-color: $cprimary-12;
    border-radius: 6px;
    box-shadow: 0px 6px 12px #00000029;
    color: white;
    font-weight: bold;
    height: 42px;
    line-height: 42px;
    margin-bottom: 50px;
    margin-top: 15px;
    text-transform: none;
    width: 198px;
}

.st-divBtnPayed {
    position: relative;
    margin-left: auto;
    margin-right: 0;
}

input[type="radio"].st-paymentsOffline {
    display: none;
}

input[type="radio"].st-paymentsOffline + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border: 2px solid #02130E;
    border-radius: 50%;
    background: #fff;
}

input[type="radio"].st-paymentsOffline:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 7px;
    height: 7px;
    border: 2px solid #02130E;
    border-radius: 50%;
    background: #02130E;
    transition: none;
}

input[type="radio"].st-paymentsOffline:checked + label, input[type="radio"].st-paymentsOffline + label {
    line-height: 14px;
    margin-right: 15px;
}

.st-blocInputPayment {
    justify-content: flex-end;
    margin-left: auto;
    margin-right: -14px;
}

.st-bankCardCTA {
    background-color: $csecondary-1;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    color: black;
    display: none;
    height: 42px;
    padding: 14px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: -46px;
    width: 198px;
}
.st-bankCardCTA:lang(es) {
    background-color: $csecondary-1;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    color: black;
    display: none;
    width: 198px;
    height: 52px;
    padding: 14px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -56px;
}

.st-3xFreeOfChargeCTA {
    background-color: $csecondary-1;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    color: black;
    display: none;
    height: 90px;
    padding-top: 17px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -86px;
    width: 224px;
}

.st-prelevementCTA {
    background-color: $csecondary-1;
    border-radius: 6px;
    box-shadow: 0px 3px 6px #00000029;
    color: black;
    display: none;
    height: 42px;
    padding: 14px 0;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -46px;
    width: 220px;
}

.st-greenLight {
    margin-top: -3px;
    margin-right: 2px;
}

.st-blocCardNumber {
    margin-bottom: 20px;
    margin-top: 40px;
}

.st-is3xExist {
    display: none;
}