.st-containerApproval {
    padding-bottom: 50px;
    .st-blocCompanyInfos {
        height: auto;
        min-height: 238px;
    }
    .st-table-approval-header {
        height: 165px;
    }
    .st-containerOffline {
        margin-top: 21px;
    }
    .st-approval-tab {
        width: 200px;
    }
    .st-approval-done {
        margin-left: 0;
    }
    .company-info {
        padding: 50px;
    }
    .company-name {
        padding: 0 15px;
        word-break: break-all;
    }
    .st-approval-title {
        border-bottom: 2px solid #f8f8f8;
    }
    .st-approval-title:hover {
        background: #f8f8f8;
    }
    .st-iconDownload {
        margin-right: 0;
    }
    .st-ellipsis {
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .st-input-comment {
        border: #D2D4D4 1px solid;
        border-radius: 3px;
    }
    .st-comment-stored, .st-approver-infos {
        color: #ABB0AF;
    }
    .st-comment-stored {
        word-break: break-all;
    }
    .st-give-approbation {
        border-radius: 6px;
        height: 38px;
        line-height: 38px;
        margin-left: 20px;
        padding: 0;
        width: 146px;
    }
    .st-give-approbation.js-approve:hover {
        background: #0D9674 !important;
    }
    .st-refuse-approbation {
        border: 1px solid #02130E;
        border-radius: 6px;
        height: 38px;
        line-height: 38px;
        width: 102px;
    }
    .st-refuse-approbation.js-approve:hover{
        background: #f8f8f8;
    }
    .st-approval-disabled {
        background-color: #ABB0AF !important;
        border: none;
        color: #FFFF;
        cursor: initial;
    }
    .st-approval-buttons-bloc {
        margin-top: 60px;
    }
    .st-infosPaymentOffline {
        width: 60%;
    }
    .content-payed .content-item {
        margin-top: 50px;
    }
    .st-block-approval-picto-status {
        margin-right: 19px;
        margin-top: 8px;
    }
    .st-picto-approval-status {
        height: 20px;
    }
    .st-already-registered {
        height: 250px;
    }
    .st-phoneHover {
        right: 0;
    }
}