.dashboard {

    .dashboard-title {
       // margin: 20px 0;
        padding: 10px 0;
        // text-align: center;
        margin-right: 40px;
        margin-bottom: 20px;
        font-size: 58px;
    }
    
    .last-news {
        position: absolute;
        top: 30px;
        right: 0;
    }
    
    .last-news a {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding: 15px;
        display: flex;
        font-weight: bold;
        align-items: center;
    }

    .last-news img {
        margin-left: 10px;
    }
    
    .steps-content {
        position: relative;
        display: flex;
        justify-content: flex-start;
        .arrow-steps {
            .arrow-step {
                width: 100%;
                height: 1px;
                background: $cprimary-7;
                position: absolute;
            }
        }
    }

    .arrow-direction {
        width: 100%;
        height: 1px;
        background: $cprimary-7;
        position: absolute;
    }
    
    .step .step-content {
        cursor: pointer;
        margin: 10px;
        height: 200px;
        border-radius: 4px;
        padding: 20px;
        // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        background-color: rgba($cprimary-7, 0.1);
        display: flex;
        flex-direction: column;
    }

    .step.active-next .step-content, .step.active-prev .step-content {
        box-shadow: none !important;
        margin-bottom: 0;
    }

   
    .step:first-child .step-content {
        margin-left: 0;
    }
    .arrow-step {
        height: 1px;
        position: absolute;
        width: 100%;
        bottom: 0;
        background: $cprimary-7;
    }

    .arrow-end:after {
        border-style: solid;
        border-color:$cprimary-7;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        height: 1em;
        left: 0;
        position: relative;
        float: right;
        top: -7px;
        transform: rotate(45deg);
        vertical-align: top;
        width: 1em;
        right: 0;
    }

    .step-date {
        color: #aaabaf;
    }

   .step.act .step-date {
        color: $cprimary-12;
    }
    
    .step {
        position: relative;
        width: 18.75%;
    }

    .step.active {
        width: 25%;
    }
    
    .step.active .arrow-step {
        display: none !important;
    }

    .step.active .step-text {
        font-size: 24px;
        font-weight: bold;
    }
    
    .step-number {
        position: relative;
        display: none;
    }

    .arrow-step.last-arrow:after {
        border-style: solid;
        border-color:$cprimary-7;
        border-width: 0.25em 0.25em 0 0;
        content: '';
        height: 1em;
        left: 0;
        position: relative;
        float: right;
        top: -7px;
        transform: rotate(45deg);
        vertical-align: top;
        width: 1em;
        right: 0;
    }
    .act .step-content {
        background: $cprimary-7 !important;
        color: #fff;
    }
   // .step.active .step-start a {
       // width: 100%;
   // }
   
    .comment-box {
        width: 100%;
        padding: 20px;
        margin: 20px 0;
        display: inline-block;
        text-align: justify;
        border: 2px dashed #d4d4d6;
        .comment-title {
            font-size: 24px;
            font-weight: bold;
            text-align: left;
            margin: 10px 0;
        }
        .comment-text {
            // font-style: italic;
            color: $cprimary-12;
            // font-size: 14px;
            word-break: break-word;
            margin: 10px 0;
            h2 {
                font-weight: bold;
                font-size: 24px;
                color: $cprimary-12;
                text-align: left;
                margin: 20px 0;
                text-align: left;
            }
        }
        .comment-write {
            text-align: right;
            color: $cprimary-7;
            margin-top: 10px;
            cursor: pointer;
        }
        .comment-form {
            display: none;
            h2 {
                font-weight: bold;
                font-size: 24px;
                color: $cprimary-12;
                margin: 20px 0;
                text-align: left;
            }
        }
        &.active {
            display: block !important;
            .comment-text {
                display: none;
            }
            .comment-form {
                display: block;
            }
        }

        .comment-send {
            text-align: right;
        }
        
        .btn-send-comment {
            background: $cprimary-7;
            color: #fff;
        }
        
        .btn-cancel-comment {
            margin-right: 10px;
            color:$cred;
        }
    }

    .active-next .arrow-step, .active-prev .arrow-step {
        display: none;
    }
}

.trumbowyg-box, .trumbowyg-editor { height: 100% !important; margin-top: 0;}

.vertical-menu > *{margin: 15px 0;}
.menu-project-name {
    font-size: 24px;
    // height: 30px;
    font-weight: bold;
    line-height: 30px;
    color: $cprimary-12;
    .project-name {
        display: flex;
    }
}

.header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.arrow-search {
    display: flex;
    flex-basis: 30%;
}

header .login {
    flex-basis: 30%;
}

.active-step-content {
    display: none;
    &.active {
        display: block;
    }
}

.step-start a {
    background: $cprimary-7;
    height: 40px;
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: block;
    text-align: center;
    color: $cwhite;
    font-weight: bold;
}

.step:first-child .step-start a {
    margin: 0 10px 0 0;
}

.change-menu-project-name {
    display: none;
    input {
        border: 0;
        height: auto;
        width: 80%;
    }
}

.step-date-choice {
    font-size: 14px;
    color: #aaabaf;
}

.act .step-date-choice .step-date {
    color: $cprimary-12;
}

.st-horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
}

.st-dashboard-cards {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .st-card-flip {
        width: 352px;
        border-radius: 10px;
        height: 135px;
        padding: 0px 40px 0px 28px;
    }
    .st-dashboard-card {
        margin-top: 60px;
        height: 135px;
        perspective: 600px;
        position: relative;
        &.is-switched {
            .card__wrapper {
                animation: rotate 0.5s linear both;
            }
        }
        h3 {
            margin-bottom: 14px;
            font-size: 14px;
            font-weight: bold;
        }
        p {
            margin-bottom: 9px;
            font-size: 12px;
            white-space: pre-line;
        }
    }
}

.card__wrapper {
    transform-style: preserve-3d;
    animation: rotate-inverse 0.5s linear both;
}

.card__side {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.is-active {
        position: static;
    }
}

.card__side--back {
    transform: rotateY(180deg);
}

@keyframes rotate {
    0% {
        transform: rotateY(0);
    }
    70% {
        transform: rotateY(200deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes rotate-inverse {
    0% {
        transform: rotateY(180deg);
    }
    70% {
        transform: rotateY(-20deg);
    }
    100% {
        transform: rotateY(0);
    }
}

.st-promotion-banner {
    width: 100%;
    height: 40px;
    background-color: $cprimary-1;
    padding: 0px 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.js-close-cross {
    display: none;
}

.st-dashboard-card:hover .js-close-cross {
    display: block;
}

.st-dash-invit:hover {
    color: $cprimary-12;
}

st-dash-discount:hover {
    color: $cprimary-7;
}

.st-projectCardsFilter {
    background-color: #2125294D;
    z-index: 100;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    color: white;
}



