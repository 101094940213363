.backlink {
    text-align: center;
    margin-top: 40px;
}

.new-project {
    padding-top: 30px;
}

.new-projects-form h1 {
    text-align: center;
    font-weight: bold;
    font-size: 58px;
}

.steps-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px 0;
}

section.new-projects-form {
    width: 560px;
    margin: 0 auto;
}

.new-project-content li {
    list-style: none;
}

.new-project-content ul {
    margin: 0;
    padding: 0;
}

[class^="step-content-"] {
    display: none;
}

[class^="step-content-"].active {
    display: block;
}

.step-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
a.return-link {
    color: $cprimary-7;
    font-weight: bold;
    text-transform: capitalize;
}

.new-project-content #mytable th:nth-child(2) {
    width: 200px !important;
}

.new-projects-form .form-group {
    margin-bottom: 0;
}

.new-projects-form .form-group + i {
    margin-bottom: 1rem;
    display: block;
}