.st-font-black-opacity-05 {
    color: rgba(0, 0, 0, 0.5);
}

.st-font-size-10px {
    font-size: 10px;
}

.st-font-size-13px {
    font-size: 13px !important;
}

.st-font-size-14px {
    font-size: 14px !important;
}

.st-font-size-16px {
    font-size: 16px;
}

.st-font-size-18px {
    font-size: 18px;
}

.st-font-size-20px {
    font-size: 20px;
}

.st-font-size-24px {
    font-size: 24px;
}

.st-font-size-30px {
    font-size: 30px;
}

.st-font-size-32px {
    font-size: 32px;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semi-bold {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700 !important;
}