.account-content {
    
    hr {
        border-top: 1px solid #fff;
    }
    .modifs a {
        color: #fff;
    }
    .files {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
    }
    
    .file {
        margin-right: 15px;
        border-radius: 4px;
        border: 1px solid #e8e9ea;
        padding: 20px;
    }
    
    .modifs {
        margin-top: 30px;
    }
    .btn-secondary {
        background: #fff;
        color: $cprimary-12;
        width: 260px;
        font-weight: bold;
    }
    input[type="password"] {
        width: 260px;
        padding: 0 20px;
    }
    
    .disconnect {
        padding: 20px 0;
    }
    
    .accept-notification {
        margin: 20px 0;
        .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .right {
            display: flex;
        }
    }
}
.arhi-modifinfo{
    hr {
        border-top: 1px solid #fff;
    }
    .form-group {
        display: inline-block;
        margin-right: 10px;
        width: 260px;
    }
    button {
        width: 260px;
    }
    fieldset.form-group {
        width: 100%;
    }
}

.account-avatar img {
    border-radius: 20px;
}

.box-2 .result {
    max-height: 300px;
    height: auto;
}