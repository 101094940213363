.no-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-text span {
    display: block;
    font-size: 36px;
    color: $cprimary-12;
    font-weight: bold;
    margin-bottom: 15px;
}

.notif {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    height: 20px;
    line-height: 20px;
    text-align: center;
    width: 20px;
    font-size: 10px;
    border-radius: 50%;
    right: 0;
    // background: #8bddcd;
}


/*font size*/
.fs10 {
    font-size: 10px !important;
}
.fs11 {
    font-size: 11px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13 {
    font-size: 13px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs15 {
    font-size: 15px !important;
}
.fs16 {
    font-size: 16px !important;
}

.fs17 {
    font-size: 17px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs20 {
    font-size: 20px !important;
}

.fs22 {
    font-size: 22px !important;
}

.fs24 {
    font-size: 24px !important;
}
.fs30 {
    font-size: 30px !important;
}
.fs32 {
    font-size: 32px !important;
}
.fs36 {
    font-size: 36px !important;
}
.fs42 {
    font-size: 42px !important;
}

.fregular1420 {
    font: normal normal normal 14px/20px IBM Plex Sans;
}

        /* margin */
// top
.mt-auto {
    margin-top: auto !important;
}
.mt-20 {
    margin-top: -20px !important;
}
.mt-20 {
    margin-top: -20px !important;
}
.mt-10 {
    margin-top: -10px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt100 {
    margin-top: 100px !important;
}

// right
.mr-10 {
    margin-right: -10px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr14 {
    margin-right: 14px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr30 {
    margin-right: 30px !important;
}
.mr40 {
    margin-right: 40px !important;
}
.mr50 {
    margin-right: 50px !important;
}

// bottom
.mb-auto {
    margin-bottom: auto !important;
}
.mb-20 {
    margin-bottom: -20px !important;
}
.mb-10 {
    margin-bottom: -10px !important;
}
.mb0 {
    margin-bottom: 0 !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb100 {
    margin-bottom: 100px !important;
}

// left
.ml-10 {
    margin-left: -10px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml40 {
    margin-left: 40px !important;
}
.ml50 {
    margin-left: 50px !important;
}
.ml60 {
    margin-left: 60px !important;
}

// Font weight
.fw600 {
    font-weight: 600 !important;
}
.bold {
    font-weight: bold !important;
}

        /* Padding */

// left

.pl20 {
    padding-left: 20px;
}
        /* Fonts */
.ff-poppins {
    font-family: 'Poppins', sans-serif;
}

/*box shadow*/
.shadowbox {
    width: 450px;
    min-height: 400px;
    max-height: 70vh;
    border-radius: 4px;
    overflow: auto;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 40px;
}

.userregister-content {
    width: 800px;
    min-height: 400px;
    max-height: 70vh;
    border-radius: 4px;
    overflow: auto;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 40px;
}

.greenbox {
    padding: 20px;
    font-weight: bold;
    margin: 20px 0;
    background: rgba($cprimary-7, 0.2);
    border-radius: 4px;
}

.js-openpopin {
    cursor: pointer;
}

.cpointer {
    cursor: pointer;
}

/* Comment edit */
.comment-box {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    display: inline-block;
    text-align: justify;
    border: 2px dashed #d4d4d6;
    .comment-title {
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        margin: 10px 0;
    }
    .comment-text {
        word-break: break-word;
        // font-style: italic;
        color: $csecondary-3;
        // font-size: 14px;
        margin: 10px 0;
        h2 {
            font-weight: bold;
            font-size: 24px;
            color: $cprimary-12;
            text-align: left;
            margin: 20px 0;
            text-align: left;
        }
    }
    .comment-write {
        text-align: right;
        color: $cprimary-7;
        margin-top: 10px;
        cursor: pointer;
    }
    .comment-form {
        display: none;
        h2 {
            font-weight: bold;
            font-size: 24px;
            color: $cprimary-12;
            margin: 20px 0;
            text-align: left;
        }
    }
    &.active {
        display: block !important;
        .comment-text {
            display: none;
        }
        .comment-form {
            display: block;
        }
        .comment-delete {
            display: none;
        }
    }

    .comment-send {
        text-align: right;
    }
    
    .btn-send-comment {
        background: $cprimary-7;
        color: #fff;
    }
    
    .btn-cancel-comment {
        margin-right: 10px;
        color: red;
    }
}

.comment-delete {
    margin-right: 20px;
    margin-top: 10px;
    a {
        color: $corange;
        &:hover {
            color: $corange;
        }
    }
}

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* no border for img*/
.noborder img {
    border: 0;
}

/*effect*/
.ephemeral {
    position: absolute;
    right: 0;
    font-weight: bold;
    color: $cprimary-7;
    animation: ephemeralUp 1s;
}

@keyframes ephemeralUp {
    from {
        bottom: 0;
        opacity: 1;
    }
    to {
        bottom: 50%;
        opacity: 0;
    }
}

.nocursor {
    cursor: default;
}

.nocursor .activity-msg {
    cursor: default;
}

.pointblue {
    position: absolute;
    left: 0;
    top: 100px;
}

.pointyellow {
    position: absolute;
    right: 0;
    bottom: 100px;
}

.iti {
    display: block;
}

.iti__country-list {
    color: $cprimary-12;
}

.iti__arrow {
    border-top: 4px solid #fff;
}

.round-icone {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
}

// .js-accountReach .rounded-picto {
//     height: 30px !important;
// }

.rounded-picto {
    text-align: center;
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    height: 30px;
    // border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 100%;
    img {
        max-width: none;
    }
}

.rounded-picto [class^="color-"] {
    height: 100%;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.initial {
    color: $cwhite;
}

.initial b {
    display: flex;
    align-items: center;
    justify-content: center;
}

.picto-tangle {
    display: flex;
    align-items: center;
    .rounded-picto {
        margin-left: 0;
        margin-right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $cprimary-12 solid 0.5px;
        border-radius: 50%;
        background-color: white;
    }
    .rounded-initials {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: $cprimary-12 solid 0.5px;
        color: $cprimary-12;
        font-size: 10px;
        background-color: white;
        margin-right: -5px;
        font-weight: bold;
    }
}

.shared-with {
    border-radius: 16px;
    border: solid 1px $cprimary-7;
    padding: 6px 15px;
    display: flex;
    white-space: nowrap;
    align-items: center;
}

.separator {
    width: 50%;
    height: 1px;
    background: $cprimary-7;
}

.word-break {
    word-break: break-all;
}

.ellipsis {
    overflow: hidden;
}

hr.cprimary-7 {
    border-color: $cprimary-7;
}

.break-all {
    word-break: break-all;
}

.importcsv {
    .result-container {
        position: relative;
        z-index: 9;
        background: #000;
        height: 400px;
        margin-top: 40px;
        border-radius: 6px;
        padding: 10px;
        //color: #66ff66;
        overflow: auto;
    }

    .line {
        color: #66ff66;
    }

    .line.cerror {
        color: $cred;
    }

    .custom-file input + label {
        background: transparent;
        border: 0;
        display: inline-block;
        margin: 0;
        color: $cwhite;
        min-height: 80px;
        line-height: 65px;
        padding-left: 0;
    }

    .custom-file input + label:after {
        border: 1px solid $cprimary-7;
        border-radius: 4px;
        background: transparent;
        height: 45px;
        line-height: 30px;
        text-align: center;
        color: $cprimary-7;
        width: 200px;
        content: "Choisir un fichier";
        font-size: 16px;
        cursor: pointer;
    }
    #particles-js {
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .js-export-usercsv {
        display: none;
        position: relative;
        z-index: 9;
    }

    .js-test-banks {
        position: relative;
        z-index: 9;
    }
}

.green-container {
    background: $cprimary-12;
    border-radius: 8px;
    padding: 20px;
    color: #fff;
}

.max-container {
    max-width: 1140px;
    margin-left: 100px;
}

.maxlittle-container {
    max-width: 500px;
    &.center {
        margin: 0 auto;
    }
}

//color white calendar on account page
.account-page {
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}
.account-page .popin-payment-offer {
    ::-webkit-calendar-picker-indicator {
        filter: invert(0);
    }
}

.visibilityshow {
    visibility: visible;
}

.visibilityhidden {
    visibility: hidden;
}

.popin-need-information {
    .hidden {
        display: none;
    }
    .show {
        display: block;
    }
}

date-input-polyfill[data-open=true] {
    z-index: 999999;
}

date-input-polyfill button, date-input-polyfill select {
    height: 30px;
}

.rounded-cprimary-7 {
    padding: 10px 15px;
    border-radius: 18px;
    border: 1px solid $cprimary-7;
    height: 130px;
    width: 200px;
    color: $cprimary-7;
    font-weight: bold;
    position: relative;
}

.mw-1 {
    min-width: 120px;
}

.text-nobreak {
    word-break: keep-all;
}

.normalbreak {
    word-break: normal;
}

.maxwidth {
    max-width: 500px;
}

.used-name-toggle{
    display: none;
}

.hidden {
    display: none;
}

.border-b {
    border-bottom: 1px solid #70707030;
}