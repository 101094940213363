.account-notification {
    .notif-cat {
        margin: 20px 0;
    }

    .notif-content .form-group {margin: 0;}

    .notif-content {
        margin-top: 20px;
    }
    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
        color: #fff;
    }

    .notif-content > label {
        margin-bottom: 15px;
    }
    
    .notif-content .form-group > label {
        margin-bottom: 15px;
    }
    
    .notif-content .form-group > legend {
        margin-bottom: 15px;
        padding: 0
    }
}

