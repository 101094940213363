#chart
{
    background-color: white;
    color: black;
}

.w-20
{
    width: 20% !important;
}

.chart-input
{
    margin-right: 20px;
}
// .apexcharts-tooltip
// {
//     color: black;
// }

// .apexcharts-canvas
// {
//     color: white;
// }

// tspan
// {
//     color: white !important;
// }