.soancalendar {
    .cheader-date {
        width: 40px;
        white-space: nowrap;
        padding-top: 18px;
        transform: rotate(-45deg);
    }

    .planview .soancalendar-chead {
        display: none;
    }

    .soancalendar-chead {
        display: flex;
        height: 50px;
        margin-left: 20%;
        border-bottom: 1px solid rgba($csecondary-3, .6);
        color: rgba($csecondary-3, .6);
    }

    .soancalendar-cbody {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }

    .next-btn {
        cursor: pointer;
        width: 11px;
        height: 21px;
    }
    
    .previous-btn {
        cursor: pointer;
        width: 11px;
        height: 21px;
    }

    .soancalendar-cbody .day {
        width: 40px;
       // height: 600px;
        //background: #fff;
        border-right: 1px solid rgba($csecondary-3, .6);
        position: relative;
        &.sunday {
            background: #f3f3f4;
        }
        &.saturday {
            background: #f3f3f4;
        }
    }

    .soancalendar-cbody .day:first-child {
        border-left: 1px solid rgba(94, 101, 107, 0.6);
    }

    .soancalendar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    
    .soancalendar-filters {
        display: flex;
    }

    .soancalendar-filters .current{
        color: $cprimary-7;
    }
    
    .soancalendar-filters > div {
        margin-left: 15px;
    }

    .soancalendar-naviguate {
        display: flex;
        align-items: center;
    }
    
    .soancalendar-naviguate .next-btn img {
        transform: rotate(180deg);
    }
    
    .soancalendar-naviguate .show-filter {
        margin: 0 15px;
        font-weight: bold;
        font-size: 24px;
        width: 250px;
        text-align: center;
    }

    .soancalendar .weekview .show-filter {
        width: 320px;
    }

    .bullet-event {
        border-radius: 50%;
        background: orange;
        box-shadow: 0 0 3px rgba(0,0,0,0.15);
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        margin: 0 auto;
        position: absolute;
        top: 55px;
        z-index: 99999;
        left: 50%;
        transform: translate(-50%);
        span {
            display: none;
        }
        &.bullet-rendez-vous {
            background: $cprimary-12;
        }
        &.bullet-document {
            background: $cprimary-7;
        }
    }


    .bullet-steps {
        top: 20px;
        line-height: 20px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        img {
            height: 100%;
        }
    }

    .weekview .cheader-date {
        transform: rotate(0);
        text-align: center;
        width: 15%;
        border-right: 1px solid;
    }
    
    .weekview .soancalendar-cbody .day {
        width: 15%;
    }

    .weekview .soancalendar-chead {
        font-size: 14px;
    }
    
    .weekview .soancalendar-cbody {
        margin: 0;
        overflow-x: hidden;
    }
    
    .weekview .cheader-date:first-child {
        border-left: 1px solid;
    }

    .monthview .soancalendar-cbody {
        margin: 0;
        overflow-x: hidden;
    }

    .day .plan-line {
        display: none;
    }

    .plan-block {
        width: 650px;
        margin-bottom: 30px;
        .plan-block-date {
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 20px;
            text-transform: capitalize;
        }
        .plan-line {
            display: flex;
            margin: 10px 0;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            border-bottom: 1px solid $csecondary-3;
            cursor: pointer;
            .plan-bullet {
                background: rgb(56, 12, 56);
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
                height: 12px;
                width: 12px;
                line-height: 12px;
            }
            .plan-document {
                background: $cprimary-7;
            }
            .val-info {
                font-weight: bold;
            }
            .val-type {
                font-weight: bold;
            }
            .val-date {
                text-transform: capitalize;
                width: 40%;
            }
            .val-actions {
                width: 20%;
                text-align: right;
            }
            .val-name {
                overflow: hidden;
                width: auto;
                max-width: 70%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .planview {
        .soancalendar-cbody .soancalendar-planning {
            display: flex;
            flex-direction: column;
            margin-top: 5px;
        }
        .bullet-event {
            border-radius: 50%;
            background: orange;
            box-shadow: none;
            width: 20px;
            height: 20px;
            text-align: center;
            font-size: 12px;
            color: #fff;
            margin: 0 auto;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translate(-50%);
            span {
                display: none;
            }
        }
        .plan-block {
            width: 650px;
            margin-bottom: 30px;
            .plan-block-date {
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 20px;
                text-transform: capitalize;
            }
            .plan-line {
                display: flex;
                margin: 10px 0;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                border-bottom: 1px solid $csecondary-3;
                cursor: pointer;
                .plan-bullet {
                    background: rgb(56, 12, 56);
                    border-radius: 50%;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    height: 12px;
                    width: 12px;
                    line-height: 12px;
                }
                .plan-document {
                    background: $cprimary-7;
                }
                .plan-signature {
                    background: $corange;
                }
                .plan-retard-signature {
                    background: $cred;
                }
                .val-info {
                    font-weight: bold;
                }
                .val-name {
                    width: 40%;
                }
                .val-type {
                    font-weight: bold;
                }
                .val-actions {
                    text-align: center;
                }
                .val-date {
                    text-transform: capitalize;
                    width: 25%;
                }
            }
        }
    }
}

.calendar-popin {
    position: fixed;
    bottom: -205px;
    animation: slideUp 1s forwards;
    background: #fff;
    max-height: 300px;
    height: auto;
    overflow: auto;
    right: 10%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.35);
    width: 350px;
    padding: 20px;
    .eventmore {
        display: none;
    }

    .calendar-popinhead {
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .plan-line {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .plan-bullet {
            background: rgb(56, 12, 56);
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            height: 12px;
            width: 12px;
            line-height: 12px;
        }
        .plan-document {
            background: $cprimary-7;
        }
        .plan-signature {
            background: $corange;
        }
        .plan-retard-signature {
            background: $cred;
        }
    }
}

@-moz-keyframes slideUp{
    0% { bottom: -205px; }
    100% { bottom: 0; }
}

@-webkit-keyframes slideUp{
    0% { bottom: -205px; }
    100% { bottom: 0; }
}

@keyframes slideUp{
    0% { bottom: -205px; }
    100% { bottom: 0; }
}


.soancalendar-step {
    display: flex;
    height: 115px;
    background-color: #fff;
}

.soancalendar-step:nth-child(odd) {
    background-color: rgba(139, 221, 205, 0.1);
}

.soancalendar-picto {
    width: 20%;
    align-items: center;
    display: flex;
    padding: 20px;
}


.soancalendar-picto img {
    width: 40%;
}

.soancalendar-cal {
    width: 80%;
    display: flex;
    overflow-x: hidden;
}

.planview .soancalendar-step {
    display: none;
}

.planview .soancalendar-planning {
    display: flex;
}

.val-name {
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.current .soancalendar-picto-text {
    color: $cprimary-7;
}

.day.today {
    background: rgba(51, 34, 185, 0.1);
}