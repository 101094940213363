.timeline-round {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 10px 0;
    height: 70px;
}

.timeline-picto {
    border: 2px solid $cprimary-7;
    border-radius: 50%;
    height: 12px;
    background: #fff;
    width: 12px;
    z-index: 9;
    margin-left: 4px;
    &.sent {
        //border: 4px solid $cprimary-7;
        //background: url('../images/icon_check.svg') #fff no-repeat;
        // background-size: contain;
        // width: 30px;
        // height: 30px;
        // margin-left: -5px;
    }
}

.timeline-branch {
    position: absolute;
    top: 30px;
    background: #343A40;
    left: 9px;
    height: calc(100% - 60px);
    z-index: 1;
    width: 2px;
}

.timeline-relaunch {
    position: relative;
    margin-top: -30px;
}

.timeline-text {
    margin-left: 20px;
    overflow: hidden;
}

.timeline-title {
    font-weight: bold;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.timeline-mail {
    display: none;
    position: absolute;
    left: 215px;
    width: 744px;
    top: 50px;
    z-index: 9;
    border-radius: 4px;
    border: 1px solid #D4D4D6;
    margin-bottom: 100px;
    .close-cross {
        display: none;
        text-align: right;
    }
}

.timeline-container:not(.timeline-sent) {
    cursor: pointer;
    width: 200px;
    display: flex;
    align-items: center;
    &:hover {
        margin-left: -10px;
        .timeline-picto {
            background: url('../images/edit_round.svg') no-repeat;
            border: 0 !important;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-left: 0;
        }
        .timeline-text {
            margin-left: 10px;
        }
    }
}

.timeline-round.open {
    .timeline-mail {
        display: block;
    }
    .timeline-container {
        background: $csecondary-1;
        padding: 20px;
        margin-left: -30px;
        border-radius: 4px;
        width: 200px;
        &:hover {
            .timeline-text {
                margin-left: 20px;
            }
        }
    }
    .timeline-picto {
        background: url('../images/edit_round.svg') no-repeat;
        border: 0 !important;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-left: 0;
    }
}

.timeline-container.timeline-sent {
    cursor: pointer;
    width: 200px;
    display: flex;
    align-items: center;
    .mail-actions {
        display: none;
    }
}

.mail-subject {
    display: flex;
    align-items: center;
}

.mail-msg {
    textarea {
        border: none;
        overflow: auto;
        outline: none;
        width: 100%;
        height: 200px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none;
        a {
            color: $cprimary-7;
        }
    }
}

.mail-actions {
    text-align: right;
    margin: 30px 15px;
}

.mail-sendto-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: $csecondary-1;
    height: 62px;
    & div:nth-child(1) {
        margin-right: 30px;
    }
    & .select-echeance {
        border: 1px #D4D4D6 solid;
        border-radius: 4px;
        height: 34px;
        font-weight: normal;
        background-color: white;
        width: 225px;
    }
}

.input-day {
    width: 34px;
    height: 34px;
    text-align: center;
    padding: 0 5px;
    border: 1px solid #D4D4D6;
}

.meme-img {
    margin-top: 15px;
    img {
        margin: 5px;
        width: 30%;
        height: auto;
    }
}

.recall-mail-subject {
    border: 0;
    width: 80%;
}

.draft {
    align-items: center;
    display: none;
    &.open {
        display: flex;
    }
    .draft-container {
        display: flex;
        align-items: center;
        background: $cprimary-5;
        padding: 20px;
        border-radius: 4px;
        max-width: 200px;
        width: 100%;
        margin-left: -30px;
        margin-bottom: 30px;
        z-index: 1;
    }
    .timeline-mail {
        display: block;
    }
}

.draft-open {
    cursor: pointer;
}

.boxed img {
    border: 1px solid $csecondary-2;
}

.highlight-selectdate {
    background: $cprimary-7;
    a {
        color: $cwhite;
    }
}

.js-delateDays {
    color: #FD216B;
}

.st-addNewMail {
    width: 230px;
    border: 1px solid $cprimary-7;
    height: 54px;
    border-radius: 4px;
    margin-left: -45px;
    // & span {
    //     margin-top: ;
    // }
    & div {
        height: 54px;
        & img, p {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.st-msgInfoMail {
    margin-left: -45px;
    width: 230px;
    text-align: center;
    margin-top: 20px;
    font-size: 13px;
}

.st-mailObject {
    margin-right: 30px;
}

.mail-subject input {
    font-size: 14px;
}

.st-nbDays {
    margin: 0 10px;
}

.mail-subject, .mail-sendto-container, .st-addingModel, .st-addingModelDraft {
    padding: 5px 15px;
}

.timeline-mail {
    & .trumbowyg-box {
        margin-top: 15px;
        border: 0;
        padding: 0 15px;
    }
    & .trumbowyg-editor {
        border: 1px #D4D4D6 solid;
        border-top: 0;
        font-size: 14px;
        & a {
            color: #0000EE;
            text-decoration: underline;
        }
    }
    & .st-blocAddingLogoMail {
        border-top: 1px #D4D4D6 solid;
        border-bottom: 1px #D4D4D6 solid;
        height: 54px;
        line-height: 54px;
        margin: 0 20px;
        & p {
            font-weight: bold;
            cursor: pointer;
        }
    }

    .st-addLogoMail {
        font-weight: bold;
    }

    .delete-draft-mail, .delete-mail {
        color: #D02424;
        margin-right: 30px;
    }

    .st-submit-mail {
        width: 120px;
        height: 44px;
        font-weight: normal;
    }

    .st-showAddingModel, .st-showAddingModelDraft {
        margin: 20px;
        border-bottom: 1px #D4D4D6 solid;
        & p, img {
            cursor: pointer;
            font-weight: bold;
        }
    }

    .st-addingModel, .st-addingModelDraft {
        display: none;
        height: 75px;
        background-color: #F8F8F8;
        border-radius: 8px;
        margin-bottom: 15px;
        & div {
            margin-top: auto;
            margin-bottom: auto;
            & label {
                font-weight: bold;
                margin-right: 30px;
            }
            & input {
                border: 0;
                border-bottom: 2px #212529 solid;
                background-color: #F8F8F8;
                border-radius: 0;
                width: 150px;
            }
        }
        & button {
            border: 0;
            background-color: initial;
            font-weight: bold;
        }
    }

    .form-check-label {
        margin-right: 15px;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        & span {
            position: absolute;
            top: -18px;
            width: 140px;
            left: 40px;
            font-weight: bold;
        }
    }

    .trumbowyg-fullscreen-button {
        display: none;
    }

    .js-logoCompanyMail {
        cursor: move;
    }

    .js-errorMsgDays {
        color: #FD216B;
        display: none;
        padding: 15px 20px;
        cursor: initial;
    }

    .st-errorInput {
        border: 1px #FD216B solid;
    }

    .js-disabledButton {
        opacity: 0.6;
        cursor: initial;
    }

    .js-msgErrorModel {
        color: #FD216B;
        margin-bottom: 20px;
    }

    .st-personalization-load-zone {
        margin-left: 150px;
        margin-top: 4px;
        width: auto;
    }

    .cerror {
        padding-left: 20px;
        padding-bottom: 15px;
    }

    .st-labelModelName {
        color: initial !important;
    }
}

.st-cancelBill {
    background-color: #FFCE00;
    height: 44px;
    line-height: 32px;
    &:hover {
        background-color: #FFCE00;
    }
}

#modelSelect {
    border-radius: 4px;
    background-color: $cprimary-2;
    font-size: 14px;
    width: 236px;
    margin-left: 215px;
    margin-top: 30px;
    position: relative;
    z-index: 10;
    .st-divModelSelected {
        padding: 7px 15px;
        cursor: pointer;
    }
    .st-divLisDropdownModel {
        padding: 18px;
    }
    .st-dropdownModelSelect {
        border-radius: 4px;
        box-shadow: 0px 6px 12px #00000029;
        background-color: white;
        width: 236px;
        top: 40px;
    }
}

.st-emptyRelaunch {
    display: none;
    margin-left: 215px;
    top: 25px;
    width: 500px;
    position: relative;
}

.st-deleteModel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    &:hover {
        background-color: #F5F5F5;
    }
}
.st-payment-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}